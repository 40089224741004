import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Modal from "../../../../../components/Modal";
import NoResults from "../../../../../components/NoResults";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { IllustrationTypeE } from "../../../../../ui/Illustrations";
import TableContentWrapper from "../../../../../ui/table/TableContentWrapper";
import { ExistedOutputT } from "../../../types";
import OutputsModal from "../../../ui/OutputsModal";
import { BrandAffinityT } from "../types";
import AddBrand from "./AddBrandHandle";
import TableHead from "./TableHead";
import TableItem from "./TableItem";

type AffintyTableT = {
  affinities: BrandAffinityT[] | null;
};

const AffintyTable: FC<React.PropsWithChildren<AffintyTableT>> = ({
  affinities,
}) => {
  const [openModal, setOpenModal] = useState<ExistedOutputT[] | null>(null);

  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity",
  );

  const getContent = () => {
    if (affinities && affinities.length > 0) {
      return (
        <>
          {affinities?.map((item, index) => (
            <TableItem
              key={item.id}
              item={item}
              last={index === affinities.length - 1}
              setCurrentMedias={setOpenModal}
            />
          ))}
        </>
      );
    }

    return (
      <TableContentWrapper height={350}>
        <NoResults
          illustrationSize={300}
          illustration={IllustrationTypeE.scissors}
          message={t("no_results")}
        />
      </TableContentWrapper>
    );
  };

  return (
    <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
      <TableHead />
      {affinities ? (
        <>
          {getContent()}
          <AddBrand />
        </>
      ) : (
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item_no_avatar}
          count={3}
          direction="column"
        />
      )}
      <Modal
        visible={!!openModal}
        close={() => setOpenModal(null)}
        heading={t("all_mentions")}
        side="right"
        renderChildren={() => {
          return <OutputsModal outputs={openModal} />;
        }}
      />
    </FlexBox>
  );
};

export default AffintyTable;
