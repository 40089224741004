import styled from "@emotion/styled";
import * as React from "react";

export enum CustomWidthE {
  full = "100%",
  half = "50%",
  third = "33.33%",
  quarter = "25%",
  fifth = "20%",
  custom80 = "80%",
  custom75 = "75%",
  custom72 = "72%",
  custom60 = "60%",
  custom40 = "40%",
  custom30 = "30%",
  custom28 = "28%",
}

export type CursorT = "auto" | "pointer" | "inherit";
export type FlexDirectionT = "row" | "column" | "row-reverse";
export type FlexWrapT = "nowrap" | "wrap";
export type AlignT =
  | "stretch"
  | "center"
  | "flex-start"
  | "space-around"
  | "flex-end"
  | "space-between"
  | "baseline"
  | "auto";

type FlexBoxT = {
  flexDirection?: FlexDirectionT;
  justifyContent?: AlignT;
  alignItems?: AlignT;
  alignSelf?: AlignT;
  flexWrap?: FlexWrapT;
  title?: string;
  fullHeight?: boolean;
  customWidth?: CustomWidthE | string;
  position?: "static" | "relative" | "absolute";
  zIndex?: number | "auto";
  cursor?: CursorT;
  gap?: string;
  ref?: React.Ref<HTMLDivElement | null> | undefined;
};

const FlexBoxComponent = styled.div<FlexBoxT>(
  ({
    flexDirection = "row",
    justifyContent = "center",
    alignItems = "center",
    alignSelf = "auto",
    flexWrap = "nowrap",
    fullHeight = false,
    customWidth,
    position = "static",
    zIndex = "auto",
    cursor = "auto",
    gap,
  }) => ({
    position,
    display: "flex",
    flexDirection,
    justifyContent,
    alignItems,
    alignSelf,
    flexWrap,
    height: fullHeight ? "100%" : "auto",
    width: customWidth ? customWidth : "auto",
    zIndex,
    cursor,
    gap,
  }),
);

const FlexBox: React.FC<
  React.PropsWithChildren<FlexBoxT & React.HTMLAttributes<HTMLDivElement>>
> = (props) => {
  return <FlexBoxComponent {...props} />;
};

export default FlexBox;
