import styled from "@emotion/styled";
import _ from "lodash";
import * as React from "react";
import { Dispatch, FC, SetStateAction, SyntheticEvent, useState } from "react";
import { FileDrop } from "react-file-drop";
import uniqid from "uniqid";
import {
  allowExtension,
  getFileExtension,
  getFileType,
} from "../../helpers/fileType";
import { FileStatusTypeE, PendingFileT } from "../../hooks/usePendingFile";
import { rem, theme } from "../../styling/theme";

type OverlayStyleT = {
  active: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DnDArea = styled(FileDrop as any)({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
});

const HoverOverlay = styled.div<OverlayStyleT>(({ active }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  opacity: active ? 0.5 : 0,
  zIndex: 2,
  backgroundColor: theme.color.yellowLightColor,
  borderRadius: rem(4),
}));

const StyledFileInput = styled.input({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  opacity: 0,
  cursor: "pointer",
  zIndex: 2,

  "&::-webkit-file-upload-button": {
    visibility: "hidden",
  },
});

type FilesUploadAreaT = {
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  allowCopyPaste?: boolean;
  isDisabled?: boolean;
  allowMultipleUpload?: boolean;
};

const FilesUploadArea: FC<React.PropsWithChildren<FilesUploadAreaT>> =
  React.memo(
    ({
      setPendingFiles,
      allowCopyPaste = false,
      isDisabled = false,
      allowMultipleUpload = true,
    }) => {
      const [onDrag, setOnDrag] = useState(false);

      type getFormatedFileNameT = (name: string) => string;
      const getFormatedFileName: getFormatedFileNameT = (name) => {
        return name
          .normalize("NFD")
          .replace(/[\s\+]/g, "_")
          .replace(/[\u0300-\u036f]/g, "_");
      };

      type setPendingFilesHandleT = (files: FileList | null) => void;
      const setPendingFilesHandle: setPendingFilesHandleT = (files) => {
        const filesWillprocessed = files
          ? allowMultipleUpload
            ? files
            : ([files[0]] as any as FileList) // eslint-disable-line @typescript-eslint/no-explicit-any
          : null;

        filesWillprocessed &&
          !isDisabled &&
          Object.values(filesWillprocessed).forEach((file) => {
            const formatedName = getFormatedFileName(file.name);
            const extension = getFileExtension(formatedName);

            const formatedFile = new File([file], formatedName, {
              type: file.type,
              lastModified: file.lastModified,
            });

            if (allowExtension(extension)) {
              const pendingFile = {
                id: uniqid(),
                file: formatedFile,
                link: "none", //TODO: [FE/TODO] - Empty link state for pending files
                type: getFileType(extension),
                status: FileStatusTypeE.pending,
              };

              setPendingFiles((prevState) => {
                return prevState ? [...prevState, pendingFile] : [pendingFile];
              });
            }
          });
      };

      type setFilesHandleT = (
        event: SyntheticEvent<HTMLDivElement, Event> | null,
      ) => void;
      const setFilesHandle: setFilesHandleT = (event) => {
        if (event) {
          const { target } = event;
          const { files } = target as HTMLInputElement;

          if (target && files !== null && files.length > 0) {
            setPendingFilesHandle(files);
          }
        }
      };

      const WINDOW_CONST = typeof window !== "undefined" && window;
      if (WINDOW_CONST && allowCopyPaste) {
        WINDOW_CONST.addEventListener(
          "paste",
          React.useRef(
            _.throttle((e) => {
              setPendingFilesHandle(e.clipboardData.files);
            }, 1000),
          ).current,
        );
      }

      return (
        <DnDArea
          onDrop={(files: FileList) => {
            setPendingFilesHandle(files);
            setOnDrag(false);
          }}
          onDragOver={() => setOnDrag(true)}
          onDragLeave={() => setOnDrag(false)}
        >
          <HoverOverlay active={onDrag} />
          <StyledFileInput
            name="file-upload"
            type="file"
            multiple={allowMultipleUpload}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => setFilesHandle(e)}
          />
        </DnDArea>
      );
    },
  );

export default FilesUploadArea;
