import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { rem } from "../../../../../styling/theme";
import FilterContainer from "../components/FilterContainer";
import { SearchQueryGlobalT } from "../types";

const AroundWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
});

type DoubleFilterT = {
  audienceFilter: React.ReactNode;
  influencerFilter: React.ReactNode;
  t: (scope: Scope, options?: TranslateOptions | undefined) => string;
  closable?: (keyof SearchQueryGlobalT)[];
};

const DoubleFilter: FC<React.PropsWithChildren<DoubleFilterT>> = ({
  audienceFilter,
  influencerFilter,
  t,
  closable,
}) => {
  return (
    <FilterContainer tooltip={false} t={t} closable={closable}>
      <AroundWrapper gap={rem(16)}>
        <AroundWrapper gap={rem(8)}>
          <FlexBox gap={rem(4)}>
            <Paragraph paragraph={t("audience.title")} paragraphSize="small" />
            <Tooltip title={t("audience.tooltip")} placement="top">
              <QuestionCircleOutlined
                style={{
                  fontSize: rem(14),
                  color: designToken.colorTextTertiary,
                }}
              />
            </Tooltip>
          </FlexBox>
          {audienceFilter}
        </AroundWrapper>
        <AroundWrapper gap={rem(8)}>
          <FlexBox gap={rem(4)}>
            <Paragraph
              paragraph={t("influencer.title")}
              paragraphSize="small"
            />
            <Tooltip title={t("influencer.tooltip")} placement="top">
              <QuestionCircleOutlined
                style={{
                  fontSize: rem(14),
                  color: designToken.colorTextTertiary,
                }}
              />
            </Tooltip>
          </FlexBox>
          {influencerFilter}
        </AroundWrapper>
      </AroundWrapper>
    </FilterContainer>
  );
};

export default DoubleFilter;
