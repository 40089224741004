import * as React from "react";
import { FC, Fragment, useContext } from "react";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import {
  InfluencerNavigationContext,
  isInfluencerNavigationDetailItem,
} from "../context-providers/InfluencerNavigationContextProvider";
import ApprovalRequestContainer from "./approval-request/ApprovalRequestContainer";
import Approvals from "./approvals/ApprovalsContainer";

const MainContainer: FC<React.PropsWithChildren<{ loginToFb?: string }>> = ({
  loginToFb,
}) => {
  const { navigationState } = useContext(InfluencerNavigationContext);
  const { session } = useContext(ApplicationContext);

  const getSection = () => {
    if (!navigationState) {
      return null;
    }

    if (isInfluencerNavigationDetailItem(navigationState)) {
      return (
        <ApprovalRequestContainer
          loginToFb={loginToFb}
          approvalRequestId={navigationState.id}
        />
      );
    }

    if (navigationState === "approvals" && session) {
      return <Approvals session={session} />;
    }

    return null;
  };

  return <Fragment>{getSection()}</Fragment>;
};

export default MainContainer;
