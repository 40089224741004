import { FrownOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import * as React from "react";
import { createContext, FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { designToken } from "../../../helpers/antDesign";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import {
  SessionT,
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
  SubscriptionT,
} from "../../types";
import { generatePortalSession } from "../data-access/generatePortalSession";
import { createSubscriptionMutation } from "../data-access/mutation/createSubscriptionMutation";
import { syncSubscription } from "../data-access/syncSubscription";
import { NavigationKeyE } from "../router";

type SectionsT =
  | "edit_subscription"
  | "portal_billing_history"
  | "portal_edit_billing_address"
  | "portal_payment_methods";
type openPortalT = (section?: SectionsT) => void;

type UpgradeTrialButtonPropsT =
  | {
      type?: BaseButtonProps["type"];
      title?: string;
    }
  | undefined;

type SubscriptionModalContextT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chargebee: any;
  getFeatureUsage: (
    featureId: SubscriptionEntitlementFeaturesT,
  ) => SubscriptionCurrentUsageT | undefined;
  hasMarketAccess: (feature?: SubscriptionEntitlementFeaturesT) => boolean;
  isTrial: boolean;
  onUpgradeButtonHandle: () => void;
  openPortal: openPortalT;
  openTrialModal: () => void;
  setSubscription: (subscription: SubscriptionT | undefined) => void;
  subscription: SubscriptionT | undefined;
  upgradeTrialButton: (props?: UpgradeTrialButtonPropsT) => React.ReactNode;
};

export const SubscriptionContext = createContext<SubscriptionModalContextT>({
  chargebee: undefined,
  getFeatureUsage: () => undefined,
  hasMarketAccess: () => false,
  isTrial: false,
  onUpgradeButtonHandle: () => {},
  openPortal: () => {},
  openTrialModal: () => {},
  setSubscription: () => {},
  subscription: undefined,
  upgradeTrialButton: () => <></>,
});

export const SubscriptionContextProvider: FC<
  React.PropsWithChildren<{
    session: SessionT | undefined;
  }>
> = ({ session, children }) => {
  const [subscription, setSubscription] = useState<SubscriptionT | undefined>(
    session?.subscription ? session?.subscription : undefined,
  );
  const [trialModal, setTrialModal] = useState<boolean>(false);
  const [isTrial, setIsTrial] = useState<boolean>(false);

  const chargebee = window.Chargebee.getInstance();

  const { t } = useTranslate("brands.subscriptions");

  const onUpgradeButtonHandle = (): void => {
    window.location.href = `${NavigationKeyE.Wizard}/plan`;
  };

  const upgradeTrialButton = (props: UpgradeTrialButtonPropsT) => {
    const type = props?.type || "primary";
    const title = props?.title || t("trial.upgrade");
    return (
      <Button
        style={{ padding: type === "link" ? 0 : "auto" }}
        onClick={onUpgradeButtonHandle}
        type={type}
      >
        {title}
      </Button>
    );
  };

  const queryClient = useQueryClient();
  const { mutate: sync } = useMutation(() => syncSubscription(), {
    onSuccess: (data) => {
      createSubscriptionMutation(queryClient, data);
    },
  });

  const openPortal: openPortalT = (section) => {
    const chargebeePortalInstance = chargebee.createChargebeePortal();
    const sectionOptions =
      section && subscription
        ? {
            sectionType: section,
            params: { subscriptionId: subscription.remote_id },
          }
        : undefined;
    chargebeePortalInstance.open(
      {
        subscriptionChanged: () => sync(),
        subscriptionCancelled: () => sync(),
      },
      sectionOptions,
    );
  };

  const getFeatureUsage = (
    featureId: SubscriptionEntitlementFeaturesT,
  ): SubscriptionCurrentUsageT | undefined => {
    if (session && session.subscription) {
      return session.subscription.current_period_usage.usage.find(
        (usage) => usage.feature_id === featureId,
      );
    }

    return undefined;
  };

  const hasMarketAccess = (
    feature: SubscriptionEntitlementFeaturesT = "database-access-cz",
  ): boolean => {
    const entitlement = session?.subscription?.entitlements?.find(
      (e) => e.feature_id === feature,
    );

    return entitlement ? entitlement.enabled : false;
  };

  const onUpgradeClickHandle = (): void => {
    setTrialModal(false);
    onUpgradeButtonHandle();
  };

  const openTrialModal = (): void => {
    setTrialModal(true);
  };

  useEffect(() => {
    if (subscription) {
      chargebee.setPortalSession(() => {
        return generatePortalSession(subscription.remote_id);
      });
    }
    setIsTrial(subscription?.plan === "trial");
  }, [subscription]);

  useEffect(() => {
    if (session && session?.subscription !== null) {
      setSubscription(session.subscription);
    }
  }, [session]);

  return (
    <SubscriptionContext.Provider
      value={{
        chargebee,
        getFeatureUsage,
        hasMarketAccess,
        isTrial,
        onUpgradeButtonHandle,
        openPortal,
        openTrialModal,
        setSubscription,
        subscription,
        upgradeTrialButton,
      }}
    >
      {children}

      {isTrial && (
        <Modal
          centered
          open={trialModal}
          zIndex={1051}
          onOk={onUpgradeClickHandle}
          onCancel={() => setTrialModal(false)}
          okText={t("trial.modal.ok")}
        >
          <FlexBox flexDirection="column" gap={rem(8)}>
            <FrownOutlined
              style={{ fontSize: rem(24), color: designToken.orange }}
            />
            <Paragraph paragraph={t("trial.modal.title")} fontWeight={600} />
            <Paragraph
              paragraph={t("trial.modal.subtitle")}
              paragraphSize="small"
            />
          </FlexBox>
        </Modal>
      )}
    </SubscriptionContext.Provider>
  );
};
