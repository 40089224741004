import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import CheckBox from "../../../ui/CheckBox";

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }
`;

interface CheckboxProps {
  active: boolean;
  name: string;
  onChange: () => void;
}

const CheckboxContainer: FC<React.PropsWithChildren<CheckboxProps>> = ({
  name,
  onChange,
  active = false,
  children,
}) => {
  const [isChecked, setIsChecked] = useState(active);

  return (
    <>
      <Label htmlFor={name}>
        <CheckBox
          active={active}
          onClickHandle={() => {
            onChange();
            setIsChecked(!isChecked);
          }}
        >
          {children}
        </CheckBox>
      </Label>
    </>
  );
};

export default CheckboxContainer;
