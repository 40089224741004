import { I18n } from "i18n-js";
import * as React from "react";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export type TranslationsLocaleT = "en" | "cs";

type TranslationsT = {
  setTranslationsReady: Dispatch<SetStateAction<boolean>>;
  translations: Record<TranslationsLocaleT, JSON> | null;
  locale: TranslationsLocaleT;
};

type TranslationsContextT = {
  i18n: I18n;
  locale: TranslationsLocaleT;
};

export const TranslationsContext = createContext<TranslationsContextT>({
  i18n: new I18n(),
  locale: "en",
});

const TranslationsContextProvider: FC<
  React.PropsWithChildren<TranslationsT>
> = ({ setTranslationsReady, translations, locale, children }) => {
  // prepare for change language option
  const [localState, _] = useState<TranslationsLocaleT>(locale);
  const pluralRules = new Intl.PluralRules("cs");
  const [i18n, _setI18n] = useState<I18n>(new I18n(translations || {}));

  i18n.pluralization.register("cs", (_i18n, count) => {
    const pluralForm = pluralRules.select(count);
    return [count.toString(), pluralForm];
  });
  i18n.defaultLocale = localState;

  useEffect(() => {
    i18n.locale = localState;

    setTranslationsReady(true);
  }, [localState]);

  return (
    <TranslationsContext.Provider value={{ i18n: i18n, locale: localState }}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsContextProvider;
