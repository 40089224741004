import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import { AnimationOverlay, Avatar, Line } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(16),
});

const NotificationWrapper = styled(FlexBox)({
  padding: `${rem(8)} ${rem(16)}`,
  borderBottom: `1px solid ${theme.color.greyLightColor}`,
});

const NocitifcationCardPlaceholder: FC<React.PropsWithChildren<{}>> = () => (
  <Wrapper customWidth={CustomWidthE.full}>
    <NotificationWrapper customWidth={CustomWidthE.full}>
      <AnimationOverlay />
      <FlexBox customWidth={CustomWidthE.full} alignItems="flex-start">
        <Avatar size={32} />
        <FlexBox
          customWidth={CustomWidthE.full}
          flexDirection="column"
          alignItems="flex-start"
        >
          <Line />
          <Line />
          <Line last={true} noMarginBottom={true} />
        </FlexBox>
      </FlexBox>
    </NotificationWrapper>
  </Wrapper>
);

export default NocitifcationCardPlaceholder;
