import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem } from "../styling/theme";
import { ElipsesBg } from "../ui/icons/CustomIcons";
import FlexBox from "./FlexBox";

const Content = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",
  width: "50%",
  padding: rem(32),
  gap: rem(12),
});

const ImageContent = styled(Content)({
  alignItems: "center",
});

const StyledRightElipsesBg = styled(ElipsesBg)({
  position: "absolute",
  top: 0,
  right: 0,
});

const StyledLeftElipsesBg = styled(ElipsesBg)({
  position: "absolute",
  bottom: 0,
  left: 0,
  rotate: "180deg",
});

type EmptyIntroducingContainerT = {
  imageSrc: string;
};

const EmptyIntroducingContainer: FC<
  React.PropsWithChildren<EmptyIntroducingContainerT>
> = ({ imageSrc, children }) => {
  return (
    <>
      <Content>{children}</Content>
      <ImageContent>
        <StyledRightElipsesBg />
        <StyledLeftElipsesBg />
        <img src={imageSrc} alt="empty" />
      </ImageContent>
    </>
  );
};

export default EmptyIntroducingContainer;
