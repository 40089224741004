import * as React from "react";
import { FC, useContext } from "react";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Select from "../../../../../../features/Select";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";
import SerchInputWithClear from "../../components/SerchInputWithClear";
import { QueryTextTagT, QueryTextTagTypeT } from "../../types";

const ContentSearch: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate(
    "brands.influencer_database.filter.content_search",
  );
  const { t: tKeywords } = useTranslate(
    "brands.influencer_database.filter.content_search.keywords",
  );

  const values = (type: QueryTextTagTypeT): string[] => {
    return searchGlobalQuery.text_tags
      .filter((tag) => tag.type === type)
      .map((tag) => tag.value);
  };

  const setValue = (type: QueryTextTagTypeT, newValues: string[]): void => {
    const newTags: QueryTextTagT[] = newValues.map((value) => {
      return { value: value, type: type, action: "should" };
    });
    const otherType = type === "mention" ? "hashtag" : "mention";
    const existingValues = values(otherType);
    const existingTags: QueryTextTagT[] = existingValues.map((value) => {
      return { value: value, type: otherType, action: "should" };
    });

    setPartialSearchGlobalQuery({ text_tags: [...existingTags, ...newTags] });
  };

  return (
    <FilterContainer t={t}>
      <FlexBox
        gap={rem(16)}
        customWidth={CustomWidthE.full}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Select
          allowClear
          value={values("mention")}
          mode="tags"
          maxTagCount="responsive"
          notFoundContent={null}
          style={{ width: "100%" }}
          placeholder={t("placeholders.mentions")}
          onChange={(val) => setValue("mention", val)}
          options={[]}
          addonBefore="@"
        />
        <Select
          allowClear
          value={values("hashtag")}
          mode="tags"
          maxTagCount="responsive"
          notFoundContent={null}
          style={{ width: "100%" }}
          placeholder={t("placeholders.hashtags")}
          onChange={(val) => setValue("hashtag", val)}
          options={[]}
          addonBefore="#"
        />
        <SerchInputWithClear
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
          attribute="keywords"
          t={tKeywords}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default ContentSearch;
