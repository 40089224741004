import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Popover from "../../../../../components/Popover";
import { getDateTimeDynamicOption } from "../../../../../helpers/date";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon from "../../../../../ui/icons/Icon";
import { outputsOriginsIcons } from "../../../dataConstants";
import { OutputOriginT } from "../../../types";

const Wrapper = styled(FlexBox)({
  position: "relative",
  padding: `${rem(12)} ${rem(16)}`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
  cursor: "default",
});

const Devider = styled(FlexBox)({
  marginLeft: rem(8),
  paddingLeft: rem(8),
  height: rem(16),
  borderLeft: `1px solid ${theme.color.greyColor}`,
});

type CardFooterT = {
  cardOrigin: OutputOriginT;
  updatedTime: string | null;
};

const CardFooter: FC<React.PropsWithChildren<CardFooterT>> = ({
  cardOrigin,
  updatedTime,
}) => {
  const { t, locale } = useTranslate("brands.campaign");

  return (
    <Wrapper customWidth={CustomWidthE.full}>
      <Popover
        renderTrigger={() => (
          <FlexBox>
            <Icon
              icon={outputsOriginsIcons[cardOrigin]}
              color={theme.color.textGreyColor}
              zIndex={"auto"}
            />
            <Devider />
            <Paragraph
              paragraph={`${t("media_groups.updated")}: ${
                updatedTime
                  ? getDateTimeDynamicOption(updatedTime as string, locale)
                  : "-"
              }`}
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        )}
        content={t(`origin.${cardOrigin}`)}
        width={250}
        customPosition={{
          bottom: `calc(100% + ${rem(10)})`,
          top: "auto",
          left: rem(-40),
        }}
      />
    </Wrapper>
  );
};

export default CardFooter;
