import styled from "@emotion/styled";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import * as React from "react";
import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import FlexBox from "../../../../../../components/FlexBox";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { getDictionaryGeos } from "../../../../data-access/getDictionaryGeos";
import { DictionaryGeoT } from "../../../../types";
import { SearchQueryGlobalT } from "../../types";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  gap: rem(10),
  borderRadius: rem(4),
});

type LocationSelectT = {
  searchQuery: SearchQueryGlobalT;
  setFilterSearchQuery: (query: Partial<SearchQueryGlobalT>) => void;
};

const LocationSelect: FC<React.PropsWithChildren<LocationSelectT>> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const [searchValue, setSearchString] = useState<string>("");
  const [geos, setGeos] = useState<DictionaryGeoT[]>([]);

  const options = geos.map((geo) => ({
    label: geo.name,
    value: geo.id.toString(),
  }));

  const { t } = useTranslate("brands.influencer_database.filter.countries");

  const { isLoading, refetch } = useQuery(
    QueryKeys.DICTIONARY_GEOS,
    () => getDictionaryGeos(searchValue),
    {
      enabled: false,
      onSuccess: (data) => {
        setGeos(data);
      },
    },
  );

  const debounceFetcher = useMemo(() => {
    return debounce(refetch, 500);
  }, [searchValue]);

  const handleOnSelect = (id: string | null): void => {
    if (id === null) return;

    setFilterSearchQuery({
      geo: [...searchQuery.geo, { id: id }],
    });
  };

  const handleOnDeselect = (id: string | null): void => {
    if (id === null) return;

    setFilterSearchQuery({
      geo: searchQuery.geo.filter((geo) => geo.id !== id),
    });
  };

  useEffect(() => {
    searchValue.length > 0 && debounceFetcher();
  }, [searchValue]);

  return (
    <Wrapper>
      <Select
        showSearch
        mode="multiple"
        value={searchQuery.geo.map((geo) => geo.id)}
        style={{ width: "100%" }}
        onSearch={setSearchString}
        onSelect={(val) => handleOnSelect(val)}
        onDeselect={(val) => handleOnDeselect(val)}
        options={options}
        filterOption={false}
        placeholder={t("global.placeholder")}
        notFoundContent={
          isLoading ? (
            <FlexBox>
              <Spin size="small" />
            </FlexBox>
          ) : null
        }
      />
    </Wrapper>
  );
};

export default LocationSelect;
