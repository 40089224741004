import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext } from "react";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { InfluencerNavigationDetailStepT } from "../../types";
import {
  InfluencerNavigationContext,
  isInfluencerNavigationDetailItem,
} from "../context-providers/InfluencerNavigationContextProvider";
import NavigationItem from "./NavigationItem";

const NavigationWrapper = styled.div`
  padding: ${rem(20)} ${rem(20)} 0;

  ${theme.media.lg} {
    padding: ${rem(38)} ${rem(64)};
  }
`;

const NavContainer = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;

  ${theme.media.lg} {
    padding: 0 0 ${rem(40)};
  }
`;

const FlowBar = styled.ul`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  &:before {
    content: "";
    position: absolute;
    top: 17px;
    left: -20px;
    right: -20px;
    bottom: 0;
    background-color: ${theme.color.whiteColor};
  }

  &:after {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${theme.color.darkPrimaryColor};
    z-index: -1;
  }

  ${theme.media.lg} {
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0;
    margin-bottom: ${rem(32)};

    &:before {
      content: none;
    }

    &:after {
      top: 0;
      bottom: 0;
      left: 16px;
      right: auto;
      height: auto;
      width: 1px;
    }
  }
`;

type NavigationItemsT = {
  icon: React.ReactNode;
  step: InfluencerNavigationDetailStepT;
  active: boolean;
};

const NavigationMenu: FC<
  React.PropsWithChildren<{ approvalProcessed: boolean | undefined }>
> = ({ approvalProcessed }) => {
  const { navigationState } = useContext(InfluencerNavigationContext);
  const navigationDetailState =
    navigationState && isInfluencerNavigationDetailItem(navigationState)
      ? navigationState
      : undefined;
  const currentStep = navigationDetailState
    ? navigationDetailState.step
    : "welcome";

  const stepFlow: InfluencerNavigationDetailStepT[] = [
    "welcome",
    "security",
    "tutorials",
    "approval",
  ];
  const currentStepIndex = stepFlow.indexOf(currentStep);

  const getIconType = (
    iconStep: InfluencerNavigationDetailStepT,
    iconType: IconTypeE,
  ): IconTypeE => {
    if (approvalProcessed) {
      return IconTypeE.check;
    }

    const iconStepIndex = stepFlow.indexOf(iconStep);

    return currentStepIndex > iconStepIndex ? IconTypeE.check : iconType;
  };

  const getIconColor = (iconStep: InfluencerNavigationDetailStepT): string => {
    if (approvalProcessed) {
      return theme.color.whiteColor;
    }

    const iconStepIndex = stepFlow.indexOf(iconStep);

    return iconStepIndex <= currentStepIndex
      ? theme.color.whiteColor
      : theme.color.darkPrimaryColor;
  };

  const isActive = (step: InfluencerNavigationDetailStepT): boolean => {
    if (approvalProcessed) {
      return true;
    }

    const stepIndex = stepFlow.indexOf(step);

    return stepIndex <= currentStepIndex;
  };

  const navItems: NavigationItemsT[] = [
    {
      icon: (
        <Icon
          icon={getIconType("welcome", IconTypeE.graph)}
          size={16}
          color={getIconColor("welcome")}
        />
      ),
      active: isActive("welcome"),
      step: "welcome",
    },
    {
      icon: (
        <Icon
          icon={getIconType("security", IconTypeE.shieldKey)}
          size={16}
          color={getIconColor("security")}
        />
      ),
      active: isActive("security"),
      step: "security",
    },
    {
      icon: (
        <Icon
          icon={getIconType("tutorials", IconTypeE.messageQuestion)}
          size={16}
          color={getIconColor("tutorials")}
        />
      ),
      active: isActive("tutorials"),
      step: "tutorials",
    },
    {
      icon: (
        <Icon
          icon={getIconType("approval", IconTypeE.workflow)}
          size={16}
          color={getIconColor("approval")}
        />
      ),
      active: isActive("approval"),
      step: "approval",
    },
  ];

  return (
    <NavigationWrapper>
      <NavContainer>
        <Icon icon={IconTypeE.logo} size={80} />
      </NavContainer>
      <FlowBar>
        {navItems.map((item, index) => (
          <NavigationItem key={index} {...item} />
        ))}
      </FlowBar>
    </NavigationWrapper>
  );
};

export default NavigationMenu;
