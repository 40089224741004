import { theme } from "../../../../../styling/theme";
import { IconTypeE } from "../../../../../ui/icons/Icon";
import { EngagementRateTierE } from "../filters/global/EngagementRate";

export const engagementRateColor = (value: number): string => {
  const rate = value * 100;
  if (rate >= EngagementRateTierE.excellent) {
    return "#219653";
  }

  if (rate >= EngagementRateTierE.good) {
    return "#27AE60";
  }

  if (rate >= EngagementRateTierE.average) {
    return "#F2994A";
  }

  return theme.color.errorColor;
};

export const engagementRateIcon = (value: number): IconTypeE => {
  const rate = value * 100;
  if (rate >= EngagementRateTierE.excellent) {
    return IconTypeE.smileVery;
  }

  if (rate >= EngagementRateTierE.good) {
    return IconTypeE.smileSlightly;
  }

  if (rate >= EngagementRateTierE.average) {
    return IconTypeE.smileNeutral;
  }

  return IconTypeE.smileSad;
};
