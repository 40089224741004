import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import BlueLink from "../../../components/BlueLink";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../helpers/antDesign";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import { SubscriptionEntitlementFeaturesT } from "../../types";
import { SubscriptionContext } from "../context-providers/SubscriptionsContextProvider";
import { StyledDivider } from "../features/influencer-database/components/FilterContainer";

type CurrentUsageT = {
  entitlementFeature: SubscriptionEntitlementFeaturesT;
  showTreshold?: number;
  divider?: boolean;
};

const CurrentUsage: FC<React.PropsWithChildren<CurrentUsageT>> = ({
  entitlementFeature,
  showTreshold = 0,
  divider = false,
}) => {
  const { entitlementValue } = useContext(ApplicationContext);
  const { openPortal, getFeatureUsage, isTrial, upgradeTrialButton } =
    useContext(SubscriptionContext);
  const { t } = useTranslate("brands.subscriptions");

  const usage = getFeatureUsage(entitlementFeature);
  const monthCount = (usage && usage.used) || 0;
  const limit = (entitlementValue(entitlementFeature) as number) || 0;
  const limitReached = monthCount >= limit;

  if (
    (usage && usage?.used < limit * showTreshold) ||
    limit.toString() === "Unlimited"
  )
    return null;

  return (
    <>
      <FlexBox gap={rem(12)}>
        <FlexBox gap={rem(4)}>
          <Paragraph
            paragraph={monthCount.toString()}
            paragraphSize="small"
            color={
              limitReached
                ? designToken.colorError
                : designToken.colorTextTertiary
            }
          />
          <Paragraph
            paragraph={`/ ${limit} ${t("used")}`}
            paragraphSize="small"
            color={designToken.colorTextTertiary}
          />
          <Tooltip
            title={t(`tooltips.${entitlementFeature.replaceAll("-", "_")}`)}
          >
            <QuestionCircleOutlined
              style={{
                fontSize: rem(14),
                color: designToken.colorTextTertiary,
              }}
            />
          </Tooltip>
        </FlexBox>
        {limitReached && (
          <>
            {isTrial ? (
              upgradeTrialButton({
                type:
                  entitlementFeature === "database-searches"
                    ? "link"
                    : "primary",
              })
            ) : (
              <BlueLink
                text={t("buy_addon")}
                type="grey"
                marginTop="0"
                onClick={() => openPortal("edit_subscription")}
              />
            )}
          </>
        )}
      </FlexBox>
      {divider && <StyledDivider dashed />}
    </>
  );
};

export default CurrentUsage;
