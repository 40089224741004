import * as React from "react";
import { createContext, Dispatch, FC, SetStateAction } from "react";
import { QueryKeys } from "../../../helpers/queryKeys";
import { DateCalendarData } from "../features/media-groups/CalendarContainer";
import { MediaContentStateT } from "../features/media-groups/card-cover/MediaContentModal";
import { ExistedOutputsModalStateT } from "../features/media-groups/card/ExistedOutputsModal";
import {
  MediaGroupToRemoveStateT,
  MediaToRemoveStateT,
} from "../features/media-groups/MediaGoupsContainer";
import { CampaignT, QueryStackT } from "../types";

type MediaGroupsHelperContextT = {
  cardWidth: string;
  dateCalendar: DateCalendarData | undefined;
  queryStack: QueryStackT;
  addedQueryStack: QueryStackT;
  setMediaContentState: Dispatch<
    SetStateAction<MediaContentStateT | undefined>
  >;
  setDateCalendar: Dispatch<SetStateAction<DateCalendarData | undefined>>;
  setMediaGroupToRemove: Dispatch<
    SetStateAction<MediaGroupToRemoveStateT | undefined>
  >;
  setMediaToRemove: Dispatch<SetStateAction<MediaToRemoveStateT | undefined>>;
  setExistedOutputsState: Dispatch<
    SetStateAction<ExistedOutputsModalStateT | undefined>
  >;
  campaign: CampaignT | undefined;
};

export const MediaGroupsHelperContext =
  createContext<MediaGroupsHelperContextT>({
    cardWidth: "",
    dateCalendar: undefined,
    queryStack: QueryKeys.CAMPAIGN_MEDIA_GROUPS,
    addedQueryStack: QueryKeys.CAMPAIGN_MEDIA_GROUPS_ADDED,
    setMediaContentState: () => {},
    setDateCalendar: () => {},
    setMediaGroupToRemove: () => {},
    setMediaToRemove: () => {},
    setExistedOutputsState: () => {},
    campaign: undefined,
  });

export const MediaGroupsHelperContextProvider: FC<
  React.PropsWithChildren<MediaGroupsHelperContextT>
> = ({
  cardWidth,
  setMediaGroupToRemove,
  setMediaToRemove,
  setMediaContentState,
  setExistedOutputsState,
  dateCalendar,
  setDateCalendar,
  campaign,
  queryStack,
  addedQueryStack,
  children,
}) => (
  <MediaGroupsHelperContext.Provider
    value={{
      cardWidth,
      setMediaGroupToRemove,
      setMediaToRemove,
      setMediaContentState,
      setExistedOutputsState,
      dateCalendar,
      setDateCalendar,
      campaign,
      queryStack,
      addedQueryStack,
    }}
  >
    {children}
  </MediaGroupsHelperContext.Provider>
);
