import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { hexToRgb, rem, theme } from "../styling/theme";
import InnerHTML from "./InnerHTML";
import VisibleWrapper from "./VisibleWrapper";

type PositionStyleT = {
  positionTop: boolean;
};

type WrapperStyleT = {
  bouncing: boolean;
} & PositionStyleT;

const Wrapper = styled(VisibleWrapper)<WrapperStyleT>(
  ({ positionTop, bouncing }) => ({
    position: "absolute",
    width: "min-content",
    height: "auto",
    top: positionTop ? "auto" : `calc(100% + ${rem(5)})`,
    bottom: !positionTop ? "auto" : `calc(100% + ${rem(5)})`,
    left: "50%",
    padding: `${rem(6)} ${rem(10)}`,
    fontSize: rem(13),
    fontWeight: 600,
    borderRadius: rem(4),
    textTransform: "none",
    zIndex: 3,
    backgroundColor: `${hexToRgb(theme.color.textColor, 0.8)}`,
    transform: "translateX(-50%)",
    animation: bouncing ? `tooltipBounce 2s ease infinite` : "none",
  }),
);

const Notch = styled.div<PositionStyleT>(({ positionTop }) => ({
  position: "absolute",
  width: 0,
  height: 0,
  bottom: positionTop ? rem(-10) : "auto",
  top: !positionTop ? rem(-10) : "auto",
  left: "50%",
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: `10px solid ${hexToRgb(theme.color.textColor, 0.8)}`,
  transform: positionTop
    ? "translateX(-50%)"
    : "rotate(180deg) translateX(50%)",
  zIndex: -1,
}));

type TextStyleT = {
  whiteSpace: "nowrap" | "normal";
};

const Text = styled.div<TextStyleT>(({ whiteSpace }) => ({
  color: theme.color.whiteColor,
  fontWeight: 500,
  lineHeight: "normal",
  textAlign: "center",
  whiteSpace,
}));

export type TooltipPosition = "top" | "bottom";

type TooltipT = {
  visible: boolean;
  tooltip: string;
  bouncing?: boolean;
  position?: TooltipPosition;
  whiteSpace?: "nowrap" | "normal";
};

const Tooltip: FC<React.PropsWithChildren<TooltipT>> = ({
  tooltip,
  visible,
  bouncing = false,
  position = "bottom",
  whiteSpace = "nowrap",
}) => (
  <Wrapper
    visible={visible}
    positionTop={position === "top"}
    bouncing={bouncing}
  >
    <Notch positionTop={position === "top"} />
    <Text whiteSpace={whiteSpace}>
      <InnerHTML content={tooltip} />
    </Text>
  </Wrapper>
);

export default Tooltip;
