import * as React from "react";
import { FC } from "react";

type InnerHTMLT = {
  content: string;
};

const InnerHTML: FC<React.PropsWithChildren<InnerHTMLT>> = ({ content }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default InnerHTML;
