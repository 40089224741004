import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";

const Wrapper = styled(FlexBox)({
  ["p"]: {
    textAlign: "center",
  },
});

const IconWrapper = styled(FlexBox)({
  padding: rem(20),
  backgroundColor: hexToRgb(theme.color.errorColor, 0.4),
  borderRadius: "50%",
});

const ErrorTitle = styled(Paragraph)({
  padding: `${rem(25)} 0 ${rem(10)} 0`,
});

const NotFoundMessage: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate("influencer_root.approval_request");

  return (
    <Wrapper justifyContent="center" flexDirection="column">
      <IconWrapper>
        <Icon
          icon={IconTypeE.cross}
          size="big"
          color={theme.color.errorColor}
        />
      </IconWrapper>
      <ErrorTitle
        paragraph={t("errors.not_found.title")}
        paragraphSize="large"
      />
      <Paragraph paragraph={t("errors.not_found.text")} />
    </Wrapper>
  );
};

export default NotFoundMessage;
