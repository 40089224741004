import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import macbook from "../../../assets/fb-approval/icons/macBook.png";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: ${rem(-32)};
  ${theme.media.lg} {
    flex-wrap: nowrap;
  }
`;

const ImgWrapper = styled.div`
  display: block;
`;

const ListWrapper = styled.div`
  display: block;
`;

const List = styled.ul`
  padding: 0;
`;

const ListText = styled.li`
  list-style: none;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: ${rem(22)};
  color: ${theme.color.bwBlack};
  margin-bottom: 26px;
`;

const Img1 = styled.img`
  width: 100%;
  ${theme.media.lg} {
    width: auto;
  }
`;

const IconWrapper = styled.span`
  border-radius: 50%;
  padding: ${rem(7)};
  font-size: 0;
  fill: white;
  background-color: ${theme.color.primaryColor};
  width: 24px;
  height: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccessToData: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate("influencer_root.approval_request.security.faqs");

  return (
    <Wrapper>
      <ListWrapper>
        <List>
          <Title>{t("available.title")}</Title>
          <ListText>
            <IconWrapper>
              <Icon
                icon={IconTypeE.check}
                size={9}
                color={theme.color.whiteColor}
              />
            </IconWrapper>
            {t("available.item1")}
          </ListText>
          <ListText>
            <IconWrapper>
              <Icon
                icon={IconTypeE.check}
                size={9}
                color={theme.color.whiteColor}
              />
            </IconWrapper>
            {t("available.item2")}
          </ListText>
        </List>
        <List>
          <Title>{t("not_available.title")}</Title>
          <ListText>
            <IconWrapper>
              <Icon
                icon={IconTypeE.cross}
                size={8}
                color={theme.color.whiteColor}
              />
            </IconWrapper>
            {t("not_available.item1")}
          </ListText>
          <ListText>
            <IconWrapper>
              <Icon
                icon={IconTypeE.cross}
                size={8}
                color={theme.color.whiteColor}
              />
            </IconWrapper>
            {t("not_available.item2")}
          </ListText>
          <ListText>
            <IconWrapper>
              <Icon
                icon={IconTypeE.cross}
                size={8}
                color={theme.color.whiteColor}
              />
            </IconWrapper>
            {t("not_available.item3")}
          </ListText>
        </List>
      </ListWrapper>
      <ImgWrapper>
        <Img1 src={macbook} alt="" />
      </ImgWrapper>
    </Wrapper>
  );
};

export default AccessToData;
