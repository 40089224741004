import styled from "@emotion/styled";
import { Select } from "antd";
import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { getTags } from "../../../../data-access/getTags";
import { AttributeT } from "../../Crm";
import { ContactT } from "../../types";

const Wrapper = styled(FlexBox)({
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  padding: `0 ${rem(16)} 0 ${rem(8)}`,
  gap: rem(16),
});

type OptionT = {
  label: string;
  value: string;
};

type TagsT = {
  contact: ContactT;
  setContact: (keyPath: AttributeT, value: string | string[]) => void;
};

const Tags: FC<React.PropsWithChildren<TagsT>> = ({ contact, setContact }) => {
  const { t } = useTranslate("brands.influencer_database.crm.contact.tags");
  const [options, setOptions] = useState<OptionT[]>([]);

  const { isLoading } = useQuery(QueryKeys.TAGS, () => getTags(), {
    onSuccess: (data) => {
      const tags = data.map((tag) => ({ label: tag.name, value: tag.name }));
      setOptions(tags);
    },
  });

  const onSelectChangeHandle = (values: string[]): void => {
    setContact("tags", values);
  };

  return (
    <Wrapper>
      <Paragraph paragraph={t("description")} paragraphSize="small" />
      <Select
        mode="tags"
        loading={isLoading}
        style={{ width: "100%" }}
        placeholder={t("placeholder")}
        value={contact.tags}
        onChange={onSelectChangeHandle}
        options={options}
      />
    </Wrapper>
  );
};

export default Tags;
