import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { useQuery } from "react-query";
import TagsInput from "../../../../../features/TagsInput";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { getHashtags } from "../../../data-access/getHashtags";

type HashtagsTagsTagsT = {
  hashtags: string[];
  setHashtags: Dispatch<SetStateAction<string[]>>;
  campaignId: string;
  label?: string;
  placeholder?: string;
  isStripped?: boolean;
  allowNew?: boolean;
};

const HashtagsTagsTags: FC<React.PropsWithChildren<HashtagsTagsTagsT>> = ({
  hashtags,
  setHashtags,
  campaignId,
  label,
  placeholder,
  isStripped = true,
  allowNew = true,
}) => {
  const { data } = useQuery(QueryKeys.FILTER_HASHTAGS, () =>
    getHashtags(campaignId),
  );

  return (
    <TagsInput
      values={hashtags}
      setValues={setHashtags}
      suggestions={data}
      label={label}
      placeholder={placeholder}
      isStripped={isStripped}
      allowNew={allowNew}
      addonBefore={"#"}
    />
  );
};

export default HashtagsTagsTags;
