import "@ant-design/v5-patch-for-react-19"; // remove once antd supports React 19 directly
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "../react/App";
import "../react/styling/globalStyles.css";

document.addEventListener("turbolinks:load", () => {
  const appElements = [document.getElementById("react-app")];

  appElements.forEach((app) => {
    if (app) {
      const { data, component, loader, translations, locale } = app.dataset;

      if (process.env.NODE_ENV === "development" || component) {
        const root = createRoot(app);
        root.render(
          <App
            id={component}
            props={data ? JSON.parse(data) : null}
            translations={translations ? JSON.parse(translations) : null}
            locale={locale ? locale : "en"}
            loader={loader}
          />,
        );
      }

      app.removeAttribute("data-translations");
      app.removeAttribute("data-data");
      app.removeAttribute("data-no-loader");
      app.removeAttribute("data-component");
    }
  });
});
