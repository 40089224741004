import { cs } from "date-fns/locale";
import { formatDate } from "helpers/formaters";
import { useTranslate } from "hooks/useTranslate";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { isSafari } from "../../../../../helpers/browsersCheck";
import { getStringDate } from "../../../../../helpers/date";
import { useMutateMedia } from "../../../../../hooks/useMutateMedia";
import { CalendarWrapper } from "../../../../../ui/CalendarWrapper";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import InsightItemWrapper from "../../../ui/InsightItemWrapper";

type InputDateT = {
  id: string;
  label: string;
  date: string | null;
  permanentDisabled?: boolean;
  liveAddedMedia?: boolean;
};

const InputDate: React.FC<React.PropsWithChildren<InputDateT>> = ({
  id,
  label,
  date,
  permanentDisabled = false,
  liveAddedMedia,
}) => {
  const [dateState, setDateState] = useState<string | null>(date);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [openPopover, setOpenPopover] = useState<"open" | "close">("close");
  const { i18n } = useTranslate();

  const { setDateCalendar, addedQueryStack, queryStack } = useContext(
    MediaGroupsHelperContext,
  );
  const calendarPositionWrapper = useRef<HTMLDivElement>(null);

  const { isDisabled, mutateMedia } = useMutateMedia(
    id,
    liveAddedMedia ? addedQueryStack : queryStack,
  );

  const openDatePickerByWrapper = (): void => {
    if (!permanentDisabled) {
      setOpenPopover("open");
    }
  };

  useEffect(() => {
    setDateCalendar(
      openPopover === "open" && calendarPositionWrapper.current
        ? {
            calendar: (
              <CalendarWrapper customWidth={CustomWidthE.full}>
                {/* @ts-ignore */}
                <Calendar
                  locale={cs}
                  date={
                    dateState && !isSafari() ? new Date(dateState) : new Date()
                  }
                  onChange={(e: Date) => setDateState(getStringDate(e))}
                />
              </CalendarWrapper>
            ),
            xCor:
              calendarPositionWrapper.current.getBoundingClientRect().x +
              window.scrollX,
            yCor:
              calendarPositionWrapper.current.getBoundingClientRect().y +
              window.scrollY,
            closeCalendar: () => setOpenPopover("close"),
          }
        : undefined,
    );
  }, [openPopover]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);

      return;
    }

    if (openPopover === "open" && !isDisabled) {
      mutateMedia({ published_at: dateState });
      setOpenPopover("close");
    }
  }, [dateState]);

  return (
    <InsightItemWrapper
      onClick={openDatePickerByWrapper}
      label={label}
      disabled={permanentDisabled}
    >
      <FlexBox
        position="relative"
        zIndex={openPopover === "open" ? 3 : "auto"}
        customWidth={CustomWidthE.full}
        justifyContent="flex-start"
        ref={calendarPositionWrapper}
      >
        <Paragraph
          paragraph={date ? formatDate(date, i18n) : "-"}
          paragraphSize="small"
          fontWeight={permanentDisabled ? 500 : 600}
        />
      </FlexBox>
    </InsightItemWrapper>
  );
};

export default InputDate;
