import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import SortLink from "../../../../components/SortLink";
import {
  putSpacesToThousand,
  roundNumber,
} from "../../../../helpers/formaters";
import { useLocalSort } from "../../../../hooks/useLocalSort";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { OverlapAnalysisDataItemT } from "../../types";

const MainTable = styled.table({
  fontSize: rem(18),
  borderCollapse: "collapse",

  ["td, th"]: {
    padding: rem(10),
    border: `1px solid ${theme.color.greyColor}`,
  },
});

type TableT = {
  data: OverlapAnalysisDataItemT[];
};

const Table: FC<React.PropsWithChildren<TableT>> = ({ data }) => {
  const { sortedCollection, setSortingOptions, sortingOptions } =
    useLocalSort<OverlapAnalysisDataItemT>("followers", data);

  const { t } = useTranslate("brands.overlap_analyses.show");

  return (
    <MainTable>
      <thead>
        <tr>
          <th>{t("username")}</th>
          <th>
            <SortLink
              attribute={"followers"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
              paragraphSize="big"
            />
          </th>
          <th>
            <SortLink
              attribute={"overlapping_percentage"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
              paragraphSize="big"
            />
          </th>
          <th>
            <SortLink
              attribute={"unique_percentage"}
              sortingOptions={sortingOptions}
              setSortingOptions={setSortingOptions}
              paragraphSize="big"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedCollection &&
          sortedCollection.map((item) => (
            <tr key={item.username}>
              <td>{item.username}</td>
              <td>{putSpacesToThousand(item.followers)}</td>
              <td>
                {`${putSpacesToThousand(
                  item.overlapping_followers,
                )} (${roundNumber(item.overlapping_percentage * 100, 1)}%)`}
              </td>
              <td>
                {`${putSpacesToThousand(item.unique_followers)} (${roundNumber(
                  item.unique_percentage * 100,
                  1,
                )}%)`}
              </td>
            </tr>
          ))}
      </tbody>
    </MainTable>
  );
};

export default Table;
