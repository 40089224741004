import { DownloadOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tabs, TabsProps } from "antd";
import { formatDate } from "helpers/formaters";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useMatch, useParams } from "react-router-dom";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import LightBoxModal, {
  LightBoxStateT,
} from "../../../../components/lightBox/LightBoxModal";
import Modal from "../../../../components/Modal";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { LightBoxContextProvider } from "../../../../context-providers/LightBoxContextProvider";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { MixpanelEventT, trackEvent } from "../../../../helpers/trackEvent";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { getCompetitiveAnalysis } from "../../data-access/getCompetitiveAnalysis";
import { getCompetitiveAnalysisOutputs } from "../../data-access/getCompetitiveAnalysisOutputs";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { ExistedOutputT } from "../../types";
import OutputsModal from "../../ui/OutputsModal";
import Outputs from "./Outputs";
import Table from "./Table";

const BackArrow = styled(FlexBox)({
  height: "auto",
  cursor: "pointer",
  padding: `0 ${rem(10)}`,
  borderRadius: `${rem(8)} 0 0 ${rem(8)}`,

  ["&:hover"]: {
    backgroundColor: theme.color.greyLightColor,
    backgroundImage: `linear-gradient(to right, ${theme.color.greyLightColor}, white)`,
  },
});

const Header = styled(FlexBox)({
  justifyContent: "space-around",
  width: "100%",
  padding: `${rem(20)} ${rem(20)} ${rem(20)} 0`,
});

const ResultsWrapper = styled(FlexBox)({
  backgroundColor: theme.color.whiteColor,
  borderRadius: `${rem(8)} ${rem(8)} 0 0`,
  padding: `0 ${rem(16)} ${rem(16)} ${rem(16)}`,
});

const SortButtonWrapper = styled(FlexBox)<{ visible: boolean }>(
  ({ visible }) => ({
    visibility: visible ? "visible" : "hidden",
    gap: rem(5),
  }),
);

type SortT = "timestamp:desc" | "owner:asc";

type ActiveTabT = "influencers" | "outputs";

const CompetitiveAnalysisDetailContainer: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams() as { id: string };

  const [openModal, setOpenModal] = useState<ExistedOutputT[] | null>(null);
  const [outputs, setOutputs] = useState<ExistedOutputT[] | undefined>();
  const [sort, setSort] = useState<SortT>("timestamp:desc");
  const [lightBoxState, setLightBoxState] = useState<
    LightBoxStateT | undefined
  >(undefined);

  const match = useMatch(`${NavigationKeyE.CompetitiveAnalyses}/${id}/:tab`);
  const activeTab = match ? (match.params.tab as ActiveTabT) : "influencers";

  const filteredOutputs = outputs?.filter((o) => o.owner !== null);
  const exportUrl = getApiUrl(ApiRoutesE.COMPETITIVE_ANALYSES_DETAIL, id);

  const navigate = useRouterNavigate();

  const { t, i18n } = useTranslate("brands.competitive_analyses.show");

  const tabItems: TabsProps["items"] = [
    {
      key: "influencers",
      label: t("influencers"),
    },
    {
      key: "outputs",
      label: t("outputs"),
    },
  ];

  const { data: competitiveAnalysis } = useQuery(
    [QueryKeys.COMPETITIVE_ANALYSIS_DETAIL, id],
    () => getCompetitiveAnalysis(id),
  );

  const {
    data: allOutputs,
    isLoading,
    isRefetching,
    refetch: competitiveAnalysisAllOutputsRefetch,
  } = useQuery(QueryKeys.COMPETITIVE_ANALYSIS_ALL_OUTPUTS, () =>
    getCompetitiveAnalysisOutputs(id, 1000, "", sort),
  );

  const onTabChangeHandle = (key: string): void => {
    navigate(NavigationKeyE.CompetitiveAnalyses, `${id}/${key}`);
    trackEvent(
      key === "influencers"
        ? MixpanelEventT.profileAnalysisDetailInfluencersImpression
        : MixpanelEventT.ProfileAnalysisDetailPostsImpression,
    );
  };

  React.useEffect(() => {
    trackEvent(MixpanelEventT.profileAnalysisDetailInfluencersImpression);
  }, []);

  useEffect(() => {
    competitiveAnalysisAllOutputsRefetch();
  }, [sort]);

  useEffect(() => {
    if (allOutputs) {
      setOutputs(allOutputs);
    }
  }, [allOutputs]);

  const getContent = () => {
    if (!competitiveAnalysis) {
      return;
    }
    const { handle, created_at, analyzed_posts, min_likes, results } =
      competitiveAnalysis;

    return (
      <LightBoxContextProvider setLightBoxState={setLightBoxState}>
        <Panel
          marginBottom="small"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <BackArrow
            onClick={() => navigate(NavigationKeyE.CompetitiveAnalyses)}
          >
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </BackArrow>
          <Header>
            <FlexBox>
              <Paragraph
                paragraph={`@${handle}`}
                paragraphSize="large"
                fontWeight={600}
              />
            </FlexBox>
            <FlexBox>
              <Paragraph
                paragraph={formatDate(created_at, i18n)}
                color={theme.color.textGreyColor}
              />
            </FlexBox>
            <FlexBox>
              <Paragraph paragraph={`${t("analyzed_posts")}:`} />
              <Spacer size="micro" />
              {analyzed_posts}
            </FlexBox>
            <FlexBox>
              <Paragraph paragraph={`${t("min_likes")}:`} />
              <Spacer size="micro" />
              {min_likes}
            </FlexBox>
            <Button href={`${exportUrl}.xlsx`} icon={<DownloadOutlined />}>
              {t("download")}
            </Button>
          </Header>
        </Panel>
        <ResultsWrapper flexDirection="column" alignItems="flex-start">
          <FlexBox
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
          >
            <Tabs
              items={tabItems}
              onChange={onTabChangeHandle}
              activeKey={activeTab}
            />
            <SortButtonWrapper visible={activeTab === "outputs"}>
              <Paragraph paragraph={t("sort.by")} />
              <Button
                icon={<Icon icon={IconTypeE.sort} size="mini" />}
                loading={isLoading || isRefetching}
                onClick={() =>
                  setSort((value) =>
                    value === "timestamp:desc" ? "owner:asc" : "timestamp:desc",
                  )
                }
              >
                {sort === "timestamp:desc"
                  ? t("sort.owner")
                  : t("sort.timestamp")}
              </Button>
            </SortButtonWrapper>
          </FlexBox>
          <Routes>
            <Route
              path="influencers"
              element={
                <Table
                  competitiveAnalysisId={id}
                  results={results}
                  setOpenModal={setOpenModal}
                />
              }
            />
            <Route
              path="outputs"
              element={
                filteredOutputs && (
                  <Outputs outputs={filteredOutputs} results={results} />
                )
              }
            />
          </Routes>
        </ResultsWrapper>

        <Modal
          visible={!!openModal}
          side="right"
          close={() => setOpenModal(null)}
          heading={t("table.outputs")}
          renderChildren={() => {
            return <OutputsModal outputs={openModal} />;
          }}
        />

        <Modal
          visible={!!lightBoxState}
          close={() => setLightBoxState(undefined)}
          showCloseButton={false}
          renderChildren={(_, close) => (
            <>
              {!!lightBoxState && (
                <LightBoxModal lightBoxState={lightBoxState} close={close} />
              )}
            </>
          )}
        />
      </LightBoxContextProvider>
    );
  };

  return <Container>{getContent()}</Container>;
};

export default CompetitiveAnalysisDetailContainer;
