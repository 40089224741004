import { ConfigProvider } from "antd";
import cs from "antd/locale/cs_CZ";
import en from "antd/locale/en_GB";
import * as React from "react";
import { FC, useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { ApplicationContext } from "../../context-providers/ApplicationContextProvider";
import { PermissionContextProvider } from "../../context-providers/PermissionContextProvider";
import { TranslationsContext } from "../../context-providers/TranslationsContextProvider";
import { defaults } from "../../helpers/antDesign";
import { SubscriptionContextProvider } from "./context-providers/SubscriptionsContextProvider";
import { ToastContextProvider } from "./context-providers/ToastContextProvider";
import { router } from "./router";

const CompanyRoot: FC<React.PropsWithChildren<unknown>> = () => {
  const { session, permissions } = useContext(ApplicationContext);
  const { locale } = useContext(TranslationsContext);

  return (
    <>
      <ConfigProvider locale={locale === "cs" ? cs : en} theme={defaults}>
        <PermissionContextProvider permissions={permissions}>
          <ToastContextProvider>
            <SubscriptionContextProvider session={session}>
              <RouterProvider router={router} />
            </SubscriptionContextProvider>
          </ToastContextProvider>
        </PermissionContextProvider>
      </ConfigProvider>
    </>
  );
};

export default CompanyRoot;
