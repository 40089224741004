import styled from "@emotion/styled";
import { formatDate } from "helpers/formaters";
import { useTranslate } from "hooks/useTranslate";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import FlexBox from "../components/FlexBox";
import {
  ExistedOutputT,
  MediaGroupT,
  MediaT,
} from "../entry-files/company-root/types";
import { FileTypeE, getFileExtension, getFileType } from "../helpers/fileType";
import { rem, theme } from "../styling/theme";
import Icon, { IconTypeE } from "./icons/Icon";

const RelativeWrapper = styled(FlexBox)<{ sideMargin: number }>(
  ({ sideMargin }) => ({
    position: "relative",
    width: `calc(20% - ${rem(sideMargin * 2)})`,
    margin: rem(sideMargin),

    [theme.media.maxSm]: {
      width: "33.3%",
    },
  }),
);

const Wrapper = styled(FlexBox)({
  flexWrap: "wrap",
  width: "100%",
  maxHeight: rem(170),
  borderRadius: rem(4),
  overflow: "hidden",
});

const FileWrapper = styled.div({
  width: "100%",
  height: "100%",
  padding: "60% 0",
  backgroundColor: theme.color.yellowColor,
});

const FileIcon = styled(Icon)({
  margin: "0 auto",
});

const Image = styled.img({
  width: "100%",
});

const Video = styled.video({
  width: "100%",
  height: "100%",
  borderRadius: rem(4),
  overflow: "hidden",
});

const CheckboxOverlay = styled.input({
  position: "absolute",
  userSelect: "none",
  margin: 0,
  right: rem(0),
  top: rem(0),
  height: "100%",
  width: "100%",
  opacity: 0,
  zIndex: 2,
  cursor: "pointer",
});

const Bullet = styled(FlexBox)<{ checked: boolean; hover: boolean }>(
  ({ checked, hover }) => ({
    position: "absolute",
    userSelect: "none",
    margin: 0,
    right: rem(8),
    top: rem(8),
    height: rem(25),
    width: rem(25),
    borderRadius: "50%",
    backgroundColor: checked ? theme.color.primaryColor : "transparent",
    border: `2px solid ${theme.color.whiteColor}`,
    opacity: hover || checked ? 1 : 0,
    transition: "opacity 0.4s",
    transitionTimingFunction: theme.timing.default,
  }),
);

const Time = styled(FlexBox)({
  position: "absolute",
  bottom: rem(5),
  left: rem(5),
  fontSize: rem(13),
  fontWeight: 600,
  color: theme.color.whiteColor,
  backgroundColor: theme.color.textColor,
  borderRadius: rem(4),
  padding: `${rem(1)} ${rem(3)}`,
});

type OutputDataT = {
  id: string;
  url: string | null | undefined;
  timestamp: string | null;
};

type StoryComponentT = {
  output: OutputDataT;
  original: ExistedOutputT | MediaGroupT | MediaT;
  setSelectedOutput: Dispatch<
    SetStateAction<ExistedOutputT | MediaGroupT | MediaT | undefined>
  >;
  selectedOutput?: ExistedOutputT | MediaGroupT | MediaT;
  marginSize?: number;
};

const OutputSelect: FC<React.PropsWithChildren<StoryComponentT>> = ({
  output,
  original,
  selectedOutput,
  setSelectedOutput,
  marginSize = 8,
}) => {
  const [hoverState, setHoverState] = useState(false);
  const { i18n } = useTranslate();

  const { id, url, timestamp } = output;
  const checkState = !!(selectedOutput && selectedOutput.id === id);

  const fileType = !!url ? getFileType(getFileExtension(url)) : FileTypeE.file;

  const onClickHandle = (): void => {
    setSelectedOutput(original);
  };

  const getFileContent = () => {
    if (url !== null && fileType === FileTypeE.image) {
      return <Image src={url} />;
    }

    if (url !== null && fileType === FileTypeE.video) {
      return <Video src={url + "#t=0.1"} />;
    }

    return (
      <FileWrapper>
        <FileIcon icon={IconTypeE.sadDoc} size="large" />
      </FileWrapper>
    );
  };

  return (
    <RelativeWrapper
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      sideMargin={marginSize}
    >
      <CheckboxOverlay type="checkbox" onChange={onClickHandle} />
      <Bullet hover={hoverState} checked={checkState}>
        <Icon
          visible={checkState}
          icon={IconTypeE.check}
          color={theme.color.whiteColor}
          size={11}
        />
      </Bullet>
      <Time>{timestamp && formatDate(timestamp, i18n)}</Time>
      <Wrapper fullHeight={true}>{getFileContent()}</Wrapper>
    </RelativeWrapper>
  );
};

export default OutputSelect;
