import styled from "@emotion/styled";
import { Input, Radio, Space } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { IconTypeE } from "../../../../../../ui/icons/Icon";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";
import RadioItem from "../../components/RadionItem";

const StyledInput = styled(Input)({
  width: "100px",
});

export enum EngagementRateTierE {
  average = 1.5,
  good = 3,
  excellent = 5,
  /** @knipIgnoreNextLine */
  custom = 0,
}

type EngagementRateTiersT = keyof typeof EngagementRateTierE;

const EngagementRate: FC<React.PropsWithChildren<unknown>> = () => {
  const [engagementRange, setEngagementRange] = useState<
    EngagementRateTiersT | undefined
  >();
  const [engagementFrom, setEngagementFrom] = useState<number | null>(null);

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const { t } = useTranslate(
    "brands.influencer_database.filter.engagement_rate",
  );

  const tierValueForRange = (
    tier: EngagementRateTiersT,
    custom: number | null,
  ): number | null => {
    return {
      good: EngagementRateTierE.good / 100,
      average: EngagementRateTierE.average / 100,
      excellent: EngagementRateTierE.excellent / 100,
      custom: custom,
    }[tier];
  };

  const tierKeyForValue = (value: number): EngagementRateTiersT | undefined => {
    const entry = Object.entries(EngagementRateTierE).find(
      ([_, val]) => val === value,
    );
    return entry ? (entry[0] as EngagementRateTiersT) : undefined;
  };

  const setQuery = (tier: EngagementRateTiersT, from: number | null): void => {
    setPartialSearchGlobalQuery({
      engagement_rate: {
        value: tierValueForRange(tier, from),
        operator: "gte",
      },
    });
  };

  const handleRadioOnChange = (tier: EngagementRateTiersT): void => {
    setEngagementRange(tier);

    if (tier === "custom" && engagementFrom === null) return;

    setQuery(tier, engagementFrom);
  };

  const handleInputOnChange = (value: number): void => {
    setEngagementFrom(value);
    setQuery("custom", value / 100);
  };

  useEffect(() => {
    const value = searchGlobalQuery.engagement_rate.value;
    if (value) {
      const tier = tierKeyForValue(value * 100);
      tier && setEngagementRange(tier);
      tier === "custom" && setEngagementFrom(value);
    } else {
      setEngagementRange(undefined);
    }
  }, [searchGlobalQuery]);

  return (
    <FilterContainer t={t}>
      <Radio.Group
        name="engagementRate"
        value={engagementRange}
        defaultValue={undefined}
        onChange={(e) => handleRadioOnChange(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value={undefined}>{t("none")}</Radio>
          <RadioItem
            value="excellent"
            paragraph={t("excellent")}
            description={t("more", { value: EngagementRateTierE.excellent })}
            icon={IconTypeE.smileVery}
          />
          <RadioItem
            value="good"
            paragraph={t("good")}
            description={t("more", { value: EngagementRateTierE.good })}
            icon={IconTypeE.smileSlightly}
          />
          <RadioItem
            value="average"
            paragraph={t("average")}
            description={t("more", { value: EngagementRateTierE.average })}
            icon={IconTypeE.smileNeutral}
          />
          <FlexBox
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Radio value="custom">{t("custom")}</Radio>
            <StyledInput
              max={100}
              min={0}
              disabled={engagementRange !== "custom"}
              onChange={(e) => handleInputOnChange(e.target.valueAsNumber)}
              addonAfter="%"
              type="number"
              value={
                engagementRange === "custom" && engagementFrom
                  ? engagementFrom
                  : undefined
              }
            />
          </FlexBox>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default EngagementRate;
