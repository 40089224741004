import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext } from "react";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { RemoveInputStyles } from "../../../../../components/GeneralStyles";
import { GridWrapper } from "../../../../../components/GridWrapper";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { Avatar } from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import CardWrapper from "../../../../../ui/cards-ui/CardWrapper";
import { Content, TopBar } from "../../../../../ui/cards-ui/styles";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { PartnershipNetworkT } from "../../../types";
import InsightItemWrapper from "../../../ui/InsightItemWrapper";
import NewCardEmptyState from "../card-cover/NewCardEmptyState";
import AddNewMediaGroup from "./AddNewMediaGroup";

const StyledInput = styled.input({
  ...RemoveInputStyles,
  padding: 0,
  margin: 0,
  fontSize: rem(15),
  fontWeight: 600,
  color: theme.color.textColor,
  zIndex: 1,
});

const InsightWrapper = styled(FlexBox)({
  marginTop: rem(6),
  padding: `${rem(8)} ${rem(6)} 0`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
});

const Footer = styled(FlexBox)({
  position: "relative",
  padding: `${rem(12)} ${rem(16)}`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
  cursor: "default",
});

const NewCard: FC<
  React.PropsWithChildren<{ network: PartnershipNetworkT }>
> = ({ network }) => {
  const { cardWidth } = useContext(MediaGroupsHelperContext);
  const { t } = useTranslate("brands.campaign.media_groups");

  return (
    <CardWrapper
      cardWidth={cardWidth}
      renderChildren={(wrapperWidth) => (
        <>
          <AddNewMediaGroup network={network} />
          <TopBar
            customWidth={CustomWidthE.full}
            justifyContent="space-between"
          >
            <FlexBox>
              <Icon icon={IconTypeE.igPost} size="small" />
              <Spacer size="micro" />
              <Paragraph
                paragraph={`${t("add_new_card.new_card")}`}
                fontWeight={500}
              />
            </FlexBox>
            <Spacer />
            <FlexBox position="relative">
              <Icon icon={IconTypeE.dots} size="small" />
            </FlexBox>
          </TopBar>
          <NewCardEmptyState wrapperWidth={wrapperWidth} />
          <Content
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
            padding={`${rem(12)} ${rem(16)}`}
          >
            <FlexBox>
              <Avatar />
              <Paragraph
                paragraph={t("add_new_card.new_influencer")}
                fontWeight={600}
              />
            </FlexBox>
            <GridWrapper sidesMargin={2}>
              <InsightItemWrapper
                label={`${t("form.labels.published_at")}`}
                disabled={false}
              >
                <StyledInput value={"-"} readOnly />
              </InsightItemWrapper>
              <InsightItemWrapper
                label={`${t("form.labels.likes")}`}
                disabled={false}
              >
                <StyledInput value={"-"} readOnly />
              </InsightItemWrapper>
              <InsightItemWrapper
                label={`${t("form.labels.comments")}`}
                disabled={false}
              >
                <StyledInput value={"-"} readOnly />
              </InsightItemWrapper>
              <InsightItemWrapper
                label={`${t("form.labels.reach")}`}
                disabled={false}
              >
                <StyledInput value={"-"} readOnly />
              </InsightItemWrapper>
              <InsightItemWrapper
                label={`${t("form.labels.impressions")}`}
                disabled={false}
              >
                <StyledInput value={"-"} readOnly />
              </InsightItemWrapper>
            </GridWrapper>
            <InsightWrapper
              customWidth={CustomWidthE.full}
              justifyContent="flex-start"
            >
              <Paragraph
                paragraph={t("insights")}
                paragraphSize="micro"
                color={theme.color.textGreyColor}
              />
              <Spacer />
              <FlexBox position="relative">
                <BlueLink
                  text={"Upload"}
                  icon={IconTypeE.plusSquare}
                  iconSize="mini"
                  paragraphSize="small"
                  marginTop="0"
                />
              </FlexBox>
            </InsightWrapper>
          </Content>
          <Footer justifyContent="center" customWidth={CustomWidthE.full}>
            <Icon
              icon={IconTypeE.reportingPublic}
              color={theme.color.textGreyColor}
              zIndex={"auto"}
            />
            <Spacer />
            <Paragraph
              paragraph={`${t("updated")}: ${new Date().toDateString()}`}
              color={theme.color.textGreyColor}
            />
          </Footer>
        </>
      )}
    />
  );
};

export default NewCard;
