import {
  CheckCircleFilled,
  DownOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Divider, Tooltip } from "antd";
import { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { FC, useContext, useState } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { rem } from "../../../../../styling/theme";
import {
  defaultGlobalSearchQuery,
  SearchGlobalQueryContext,
} from "../../../context-providers/SearchGlobalQueryContextProvider";
import { SearchQueryGlobalT } from "../types";

const Wrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Header = styled(FlexBox)<{ open: boolean; closable: boolean }>(
  ({ open, closable }) => ({
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    paddingBottom: open ? rem(12) : 0,
    cursor: closable ? "pointer" : "default",

    "&:hover span": {
      color: closable
        ? designToken.colorPrimaryTextHover
        : designToken.colorText,
      cursor: closable ? "pointer" : "default",
    },
  }),
);

const TitleWrapper = styled(FlexBox)({
  gap: rem(8),
  flexDirection: "row",
  alignItems: "center",
});

const CloseButton = styled(FlexBox)<{ open: boolean }>(({ open }) => ({
  width: rem(20),
  height: rem(20),
  cursor: "pointer",

  "&:hover": {
    color: designToken.colorIconHover,
  },

  svg: {
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

const Content = styled(FlexBox)<{ open: boolean }>(({ open }) => ({
  display: open ? "flex" : "none",
  justifyContent: "flex-start",
  width: "100%",
}));

export const StyledDivider = styled(Divider)({
  margin: `${rem(16)} 0`,
});

type FilterContainerT = {
  t: (scope: Scope, options?: TranslateOptions | undefined) => string;
  tooltip?: boolean;
  closable?: (keyof SearchQueryGlobalT)[];
};

const FilterContainer: FC<React.PropsWithChildren<FilterContainerT>> = ({
  t,
  tooltip = true,
  closable,
  children,
}) => {
  const [open, setOpen] = useState(!closable);
  const isClosable = !!closable;

  const { searchGlobalQuery } = useContext(SearchGlobalQueryContext);

  const onClickHandle = (): void => {
    isClosable ? setOpen((prev) => !prev) : undefined;
  };

  const closableOptions = () => {
    if (!closable || closable.length === 0) return <></>;

    const areValuesDefault = closable.map(
      (attr) =>
        JSON.stringify(defaultGlobalSearchQuery[attr]) ===
        JSON.stringify(searchGlobalQuery[attr]),
    );
    const showCheck = !open && areValuesDefault.includes(false);

    return (
      <FlexBox gap={rem(8)}>
        {showCheck && (
          <CheckCircleFilled style={{ color: designToken.colorSuccess }} />
        )}
        <CloseButton open={open}>
          <DownOutlined style={{ color: designToken.colorIcon }} />
        </CloseButton>
      </FlexBox>
    );
  };

  return (
    <Wrapper>
      <Header open={open} closable={isClosable} onClick={onClickHandle}>
        <TitleWrapper>
          <Paragraph
            paragraph={t("title")}
            paragraphSize="small"
            fontWeight={600}
          />
          {tooltip && (
            <Tooltip title={t("tooltip")} placement="top">
              <QuestionCircleOutlined
                style={{
                  fontSize: rem(14),
                  color: designToken.colorTextTertiary,
                }}
              />
            </Tooltip>
          )}
        </TitleWrapper>
        {closableOptions()}
      </Header>
      <Content open={open}>{children}</Content>
      <StyledDivider dashed />
    </Wrapper>
  );
};

export default FilterContainer;
