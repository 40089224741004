import styled from "@emotion/styled";
import { Steps } from "antd";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import Container from "../../../../components/Container";
import FlexBox from "../../../../components/FlexBox";
import Loader from "../../../../components/Loader";
import SubMenuContainer from "../../../../components/SubMenuContainer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { getSubscriptionPlans } from "../../data-access/getSubscriptionPlans";
import { getSubscriptionProposal } from "../../data-access/getSubscriptionProposal";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import {
  SubscriptionPlanPriceT,
  SubscriptionPlanT,
  SubscriptionProposalT,
} from "../../types";
import SubscriptionPlans from "../subscription-plans/SubscriptionPlans";
import Checkout from "./Checkout";
import PersonalDetails from "./PersonalDetails";
import SubscriptionProposal from "./SubscriptionProposal";

const StyledSteps = styled(Steps)({
  "&.ant-steps": {
    height: rem(267),
  },

  ".ant-steps-item-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  ".ant-steps-item-tail": {
    display: "none",
    visibility: "hidden",
  },

  ".ant-steps-item:not(:last-child):after": {
    content: '" "',
    position: "absolute",
    backgroundColor: theme.color.greyColor,
    width: rem(1),
    height: rem(20),
    top: rem(86),
    left: rem(36),
  },
});

const Wrapper = styled(FlexBox)({
  minHeight: `calc(100dvh - ${rem(36)})`,
  alignItems: "stretch",
});

const StepWrapper = styled(FlexBox)({
  width: "100%",
  minHeight: "100%",
  backgroundColor: theme.color.whiteColor,
  borderRadius: designToken.borderRadiusLG,
});

const CheckoutWrapper = styled(StepWrapper)({
  alignItems: "flex-start",
});

const LoaderWrapper = styled(FlexBox)({
  justifyContent: "center",
  customWidth: "full",
  paddingTop: rem(24),
});

enum WizardStepE {
  "plan" = 0,
  "checkout" = 1,
}

export type PlanPriceStateT = {
  plan: SubscriptionPlanT;
  price: SubscriptionPlanPriceT;
};

const WizardContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const { session } = useContext(ApplicationContext);
  const subscription = session?.subscription;
  const match = useMatch(`${NavigationKeyE.Wizard}/:key`);
  const activeKey = match ? match.params.key || "plan" : "plan";

  const [planPriceState, setPlanPriceState] = useState<PlanPriceStateT>();
  const [subscriptionProposal, setSubscriptionProposal] =
    useState<SubscriptionProposalT>();
  const [showSubmenu, setShowSubmenu] = useState<boolean>(
    subscription?.plan === "trial" && activeKey !== "personal-details",
  );

  const shouldDisplaContent =
    !subscription ||
    subscription.plan === "trial" ||
    (subscription.status === "cancelled" && !subscription.remote);

  const navigate = useRouterNavigate();
  const { t } = useTranslate("brands.wizard");

  useQuery(QueryKeys.SUBSCRIPTIONS_PLANS, () => getSubscriptionPlans());

  const { isLoading } = useQuery(
    QueryKeys.SUBSCRIPTIONS_PROPOSAL,
    () => getSubscriptionProposal(),
    {
      onSuccess: (data) => {
        data && setSubscriptionProposal(data);
      },
    },
  );

  const submenu = (width: number) => {
    return (
      <SubMenuContainer width={width}>
        <FlexBox fullHeight>
          <StyledSteps
            direction="vertical"
            labelPlacement="vertical"
            current={WizardStepE[activeKey as keyof typeof WizardStepE]}
            items={[
              {
                title: t("steps.plan"),
              },
              {
                title: t("steps.checkout"),
              },
            ]}
          />
        </FlexBox>
      </SubMenuContainer>
    );
  };

  useEffect(() => {
    if (subscriptionProposal) {
      setShowSubmenu(false);
      navigate(NavigationKeyE.Wizard, "proposal", { replace: true });
    }
  }, [subscriptionProposal]);

  const getContent = () => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
    return (
      <FlexBox>
        <Container
          renderSubmenu={showSubmenu ? (width) => submenu(width) : undefined}
        >
          <Wrapper>
            <Routes>
              <Route
                path="personal-details"
                element={
                  <StepWrapper>
                    <PersonalDetails />
                  </StepWrapper>
                }
              />
              <Route
                path="plan"
                element={
                  <StepWrapper>
                    <SubscriptionPlans
                      planPriceState={planPriceState}
                      setPlanPriceState={setPlanPriceState}
                    />
                  </StepWrapper>
                }
              />
              <Route
                path="checkout"
                element={
                  <CheckoutWrapper>
                    {planPriceState ? (
                      <Checkout
                        planPriceState={planPriceState}
                        setPlanPriceState={setPlanPriceState}
                      />
                    ) : (
                      <Navigate to={`${NavigationKeyE.Wizard}/plan`} replace />
                    )}
                  </CheckoutWrapper>
                }
              />
              {subscriptionProposal && (
                <Route
                  path="proposal"
                  element={
                    <StepWrapper>
                      <SubscriptionProposal
                        subscriptionProposal={subscriptionProposal}
                      />
                    </StepWrapper>
                  }
                />
              )}
            </Routes>
          </Wrapper>
        </Container>
      </FlexBox>
    );
  };

  return (
    <>
      {shouldDisplaContent ? (
        getContent()
      ) : (
        <Navigate to={NavigationKeyE.Database} />
      )}
    </>
  );
};

export default WizardContainer;
