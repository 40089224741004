import {
  CreditCardOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Divider, Tag } from "antd";
import InfoPopover from "components/InfoPopover";
import { addDays } from "date-fns";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { rem, theme } from "styling/theme";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { formatCurrency, formatDate } from "../../../../helpers/formaters";
import { MixpanelEventT, trackEvent } from "../../../../helpers/trackEvent";
import { useTranslate } from "../../../../hooks/useTranslate";
import { SubscriptionStatusT, SubscriptionT } from "../../../types";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import PlanUsage from "./PlanUsage";
import TotalCosts from "./TotalCosts";
import ValueBox from "./ValueBox";

const Box = styled(FlexBox)({
  backgroundColor: theme.color.whiteColor,
  padding: `${rem(20)} ${rem(16)}`,
  borderRadius: designToken.borderRadiusLG,
});

const StyledDivider = styled(Divider)({
  color: designToken["blue-1"],
  margin: 0,
});

type ManageSubscriptionT = {
  subscription: SubscriptionT;
};

const ManageSubscription: FC<React.PropsWithChildren<ManageSubscriptionT>> = ({
  subscription,
}) => {
  const {
    current_period_usage,
    next_billing_at,
    billing_period,
    billing_period_unit,
    total_price,
    expires_on,
    plan,
    status,
    currency_code,
    remote,
  } = subscription;
  const subscriptionIsEnabled = status !== "cancelled";

  const { openPortal, setSubscription, isTrial, upgradeTrialButton } =
    useContext(SubscriptionContext);
  const navigate = useRouterNavigate();
  const { t, i18n } = useTranslate("brands.manage_subscription");
  const renewalDate =
    status === "active" && !isTrial
      ? addDays(new Date(expires_on), 1)
      : undefined;

  const statusColor = (status: SubscriptionStatusT): string => {
    switch (status) {
      case "active":
        return "success";
      case "non_renewing":
      case "paused":
        return "orange";
      case "cancelled":
        return "red";
      default:
        return "black";
    }
  };

  const onManageClickHandle = (): void => {
    remote
      ? openPortal()
      : !subscriptionIsEnabled && navigate(NavigationKeyE.Wizard, "plan");
  };

  useEffect(() => {
    trackEvent(MixpanelEventT.SubscriptionManagementImpression);
  }, []);

  useEffect(() => {
    setSubscription(subscription);
  }, [subscription]);

  return (
    <FlexBox>
      <Container>
        <FlexBox gap={rem(16)} flexDirection="column" alignItems="flex-start">
          <Paragraph
            paragraph={t("manage")}
            paragraphSize="large"
            fontWeight={600}
          />
          <FlexBox
            customWidth={CustomWidthE.full}
            flexDirection="column"
            gap={rem(16)}
          >
            <FlexBox
              gap={rem(16)}
              customWidth={CustomWidthE.full}
              alignItems="stretch"
            >
              <Box
                customWidth={isTrial ? CustomWidthE.full : "70%"}
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
                gap={rem(16)}
              >
                <FlexBox gap={rem(12)}>
                  <Paragraph
                    paragraph={t(`plans.${plan.toLowerCase()}`)}
                    fontWeight={600}
                  />
                  <Tag color={statusColor(status)} bordered={false}>
                    {t(`statuses.${status}`)}
                  </Tag>
                </FlexBox>

                <FlexBox
                  customWidth={CustomWidthE.full}
                  justifyContent="space-between"
                >
                  {subscriptionIsEnabled && !isTrial ? (
                    <>
                      <ValueBox
                        label={t("next_renewal")}
                        value={
                          renewalDate ? formatDate(renewalDate, i18n) : "-"
                        }
                      />
                      <ValueBox
                        label={t("next_payment")}
                        value={
                          next_billing_at
                            ? formatDate(next_billing_at, i18n)
                            : "-"
                        }
                      />
                      <ValueBox
                        label={t("billing_cycle")}
                        value={
                          billing_period_unit && renewalDate
                            ? t(
                                `billing.${billing_period}${billing_period_unit}`,
                              )
                            : "-"
                        }
                      />
                      <ValueBox
                        label={t("total")}
                        value={
                          total_price
                            ? formatCurrency(total_price, currency_code, {
                                valueInCents: true,
                              })
                            : "-"
                        }
                        icon={
                          isTrial ? undefined : (
                            <InfoPopover
                              minWidth={rem(250)}
                              maxWidth="auto"
                              placement="rightTop"
                              arrow={{ pointAtCenter: true }}
                              content={
                                <TotalCosts subscription={subscription} />
                              }
                            />
                          )
                        }
                      />
                    </>
                  ) : (
                    <Paragraph
                      paragraph={
                        isTrial
                          ? t("trial")
                          : t("disabled", {
                              date: formatDate(expires_on, i18n),
                            })
                      }
                      paragraphSize="small"
                    />
                  )}
                </FlexBox>
                <FlexBox>
                  {isTrial ? (
                    upgradeTrialButton({ title: t("upgrade") })
                  ) : (
                    <Button
                      type="primary"
                      onClick={onManageClickHandle}
                      disabled={subscriptionIsEnabled && !remote}
                    >
                      {t(subscriptionIsEnabled ? "manage" : "renew")}
                    </Button>
                  )}
                </FlexBox>
              </Box>
              {!isTrial && (
                <Box
                  customWidth="30%"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={rem(8)}
                >
                  <Button
                    type="link"
                    icon={<FileTextOutlined />}
                    disabled={!remote}
                    onClick={() => openPortal("portal_billing_history")}
                  >
                    {t("view_invoices")}
                  </Button>
                  <StyledDivider dashed />
                  <Button
                    type="link"
                    icon={<CreditCardOutlined />}
                    disabled={!remote}
                    onClick={() => openPortal("portal_payment_methods")}
                  >
                    {t("payment_methods")}
                  </Button>
                  <StyledDivider dashed />
                  <Button
                    type="link"
                    icon={<EnvironmentOutlined />}
                    disabled={!remote}
                    onClick={() => openPortal("portal_edit_billing_address")}
                  >
                    {t("billing_address")}
                  </Button>
                </Box>
              )}
            </FlexBox>
            {subscriptionIsEnabled && (
              <PlanUsage
                usage={current_period_usage.usage}
                subscription={subscription}
                renewalDate={renewalDate}
              />
            )}
          </FlexBox>
        </FlexBox>
      </Container>
    </FlexBox>
  );
};

export default ManageSubscription;
