import { ConfigProvider } from "antd";
import * as React from "react";
import ReactDOM from "react-dom";
import { theme } from "../../styling/theme";
import NavigationBrand from "../company-root/features/navigation/Navigation";
import NavigationInfluencer from "../influencer-root/features/navigation/Navigation";

export default ({ isBrand }: { isBrand: boolean | undefined }) => {
  const navigationHook = document.getElementById("navigation-hook");
  const NavigationRoot = isBrand ? NavigationBrand : NavigationInfluencer;

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: theme.color.primaryColor } }}
    >
      {navigationHook &&
        ReactDOM.createPortal(
          <NavigationRoot standalone={true} />,
          navigationHook,
        )}
    </ConfigProvider>
  );
};
