import { EyeInvisibleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Table as AntTable, Badge } from "antd";
import { ColumnsType } from "antd/es/table";
import { formatDate } from "helpers/formaters";
import { get } from "lodash";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import AvatarTile, { AvatarSizeE } from "../../../../../components/AvatarTile";
import FlexBox from "../../../../../components/FlexBox";
import { designToken } from "../../../../../helpers/antDesign";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { getCampaignMediaGroups } from "../../../data-access/getCampaignMediaGroups";
import {
  CampaignT,
  MediaGroupT,
  MediaT,
  PartnershipNetworkT,
} from "../../../types";

const imgSize = 42;

const ImageWrapper = styled(FlexBox)({
  width: rem(imgSize),
  height: rem(imgSize),
  border: `1px solid ${designToken.colorBorderSecondary}`,
});

const Img = styled.img({
  width: rem(imgSize),
  height: rem(imgSize),
  objectFit: "cover",
  objectPosition: "top",
});

interface DataType {
  key: string;
  mediaGroup: MediaGroupT;
}

type TableT = {
  campaign: CampaignT;
};

const Table: FC<React.PropsWithChildren<TableT>> = ({ campaign }) => {
  const [mediaGroups, setMediaGroups] = useState<MediaGroupT[]>([]);
  const { i18n } = useTranslate();
  const emptyStr = "-";

  const { t } = useTranslate("brands.campaign.outputs.table");

  const { isRefetching, isLoading } = useQuery(
    [QueryKeys.CAMPAIGN_MEDIA_GROUPS, "table", campaign.id],
    () =>
      getCampaignMediaGroups({
        apiUrl: getUrlWithQuery(
          getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS, campaign.id),
        ),
      }),
    {
      onSuccess: (data) => {
        setMediaGroups(data);
      },
    },
  );

  const textSorter = (
    a: DataType,
    b: DataType,
    attribute: keyof MediaT,
  ): number => {
    const x = get(a.mediaGroup, ["preview_media", attribute]) || "";
    const y = get(b.mediaGroup, ["preview_media", attribute]) || "";
    return x.toString().localeCompare(y.toString());
  };

  const numberSorter = (
    a: DataType,
    b: DataType,
    attribute: keyof MediaT,
  ): number => {
    const x = get(a.mediaGroup, ["preview_media", attribute]) || 0;
    const y = get(b.mediaGroup, ["preview_media", attribute]) || 0;
    // @ts-ignore
    return x - y;
  };

  const networkIcon = (network: PartnershipNetworkT): IconTypeE => {
    switch (network) {
      case "instagram":
        return IconTypeE.socInstagram;
      case "tiktok":
        return IconTypeE.socTiktok;
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("output"),
      dataIndex: "output",
      render: (_, record) => {
        const media = record.mediaGroup.medias[0];
        const image = media.media_files.filter(
          (media) => media.kind === "image",
        )[0];
        const count = record.mediaGroup.medias.length;
        const url = image?.thumbnail_url || image?.file_url;

        return (
          <FlexBox justifyContent="flex-start">
            <Badge
              count={count > 1 ? count : undefined}
              offset={[0, imgSize]}
              color="blue"
            >
              {url ? (
                <Img src={url} />
              ) : (
                <ImageWrapper>
                  <EyeInvisibleOutlined
                    style={{ color: designToken.colorTextTertiary }}
                  />
                </ImageWrapper>
              )}
            </Badge>
          </FlexBox>
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      sorter: (a, b) => a.mediaGroup.kind.localeCompare(b.mediaGroup.kind),
      render: (_, record) => {
        return (
          <FlexBox justifyContent="flex-start" gap={rem(4)}>
            <Icon icon={networkIcon(record.mediaGroup.network)} size="small" />
            {t(record.mediaGroup.kind)}
          </FlexBox>
        );
      },
    },
    {
      title: t("influencer"),
      dataIndex: "influencer",
      sorter: (a, b) =>
        a.mediaGroup.partnership.handle.localeCompare(
          b.mediaGroup.partnership.handle,
        ),
      render: (_, record) => {
        const { handle, avatar_url } = record.mediaGroup.partnership;
        return (
          <FlexBox justifyContent="flex-start">
            <AvatarTile
              handle={handle}
              imageUrl={avatar_url}
              avatarSize={AvatarSizeE.mini}
            />
          </FlexBox>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => textSorter(a, b, "published_at"),
      render: (_, record) => {
        const published_at = record.mediaGroup.preview_media?.published_at;
        return published_at ? formatDate(published_at, i18n) : emptyStr;
      },
    },
    {
      title: t("likes"),
      dataIndex: "likes",
      sorter: (a, b) => numberSorter(a, b, "likes"),
      render: (_, record) => record.mediaGroup.preview_media?.likes || emptyStr,
    },
    {
      title: t("comments"),
      dataIndex: "comments",
      sorter: (a, b) => numberSorter(a, b, "comments"),
      render: (_, record) =>
        record.mediaGroup.preview_media?.comments || emptyStr,
    },
    {
      title: t("reach"),
      dataIndex: "reach",
      sorter: (a, b) => numberSorter(a, b, "reach"),
      render: (_, record) => record.mediaGroup.preview_media?.reach || emptyStr,
    },
    {
      title: t("impressions"),
      dataIndex: "impressions",
      sorter: (a, b) => numberSorter(a, b, "impressions"),
      render: (_, record) =>
        record.mediaGroup.preview_media?.impressions || emptyStr,
    },
  ];
  const data: DataType[] = mediaGroups.map((group) => ({
    key: group.id,
    mediaGroup: group,
  }));

  useEffect(() => {
    trackEvent(MixpanelEventT.campaignsOutputsTableViewImpression, {
      campaignId: campaign.id,
    });
  }, []);

  return (
    <FlexBox>
      <AntTable
        sticky
        style={{ width: "100%" }}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 20 }}
        loading={isLoading || isRefetching}
      />
    </FlexBox>
  );
};

export default Table;
