import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../components/FlexBox";
import { rem, theme } from "../../../../styling/theme";
import { CalendarWrapper } from "../../../../ui/CalendarWrapper";
import { CloseOverlay } from "../../../../ui/CloseOverlay";
import { MediaGroupsHelperContext } from "../../context-providers/MediaGroupsHelperContextProvider";

const Wrapper = styled(FlexBox)<{ xCor: number; yCor: number }>(
  ({ xCor, yCor }) => ({
    position: "absolute",
    left: rem(xCor),
    top: rem(yCor - 200),
  }),
);

const StyledCalendarWrapper = styled(CalendarWrapper)({
  padding: rem(8),
  width: rem(290),
  backgroundColor: theme.color.whiteColor,
  borderRadius: rem(8),
  boxShadow: `0px 12px 60px rgb(0 0 0 / 0.1)`,
  zIndex: 3,
});

export type DateCalendarData = {
  calendar: React.ReactNode;
  xCor: number;
  yCor: number;
  closeCalendar: () => void;
};

const CalendarContaner: FC<React.PropsWithChildren<unknown>> = () => {
  const { dateCalendar } = useContext(MediaGroupsHelperContext);

  if (dateCalendar) {
    return (
      <Wrapper xCor={dateCalendar.xCor} yCor={dateCalendar.yCor}>
        <CloseOverlay onClick={dateCalendar.closeCalendar} />
        <StyledCalendarWrapper>{dateCalendar.calendar}</StyledCalendarWrapper>
      </Wrapper>
    );
  }

  return null;
};

export default CalendarContaner;
