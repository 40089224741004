import { Select } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { capitalizeFirstLetter } from "../../../../../../helpers/formaters";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

const HasContact: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.has_contact");

  const contactTypes = [
    "email",
    "phone",
    "whatsapp",
    "facebook",
    "youtube",
    "instagram",
    "tiktok",
    "snapchat",
    "itunes",
    "kakao",
    "kik",
    "lineid",
    "linktree",
    "pinterest",
    "sarahah",
    "sayat",
    "skype",
    "telegram",
    "tumblr",
    "twitchtv",
    "twitter",
    "viber",
    "vk",
    "wechat",
    "weibo",
    "bbm",
  ];
  const options = contactTypes.map((type) => ({
    label: `${t("has")} ${capitalizeFirstLetter(type)}`,
    value: type,
  }));

  const onChangeHandle = (values: string[]): void => {
    const contactTypes = values.map((value) => {
      return { type: value, action: "should" };
    });

    setPartialSearchGlobalQuery({ with_contact: contactTypes });
  };

  return (
    <FilterContainer closable={["with_contact"]} t={t}>
      <FlexBox
        gap={rem(16)}
        alignItems="flex-start"
        flexDirection="column"
        customWidth="100%"
      >
        <Select
          mode="multiple"
          options={options}
          value={searchGlobalQuery.with_contact.map((contact) => contact.type)}
          placeholder={t("placeholder")}
          onChange={onChangeHandle}
          style={{ width: "100%" }}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default HasContact;
