import styled from "@emotion/styled";
import { Select, Tag } from "antd";
import { OptionProps, SelectProps } from "antd/es/select";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import AvatarTile, {
  AvatarSizeE,
} from "../../../../../../components/AvatarTile";
import FlexBox from "../../../../../../components/FlexBox";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../../features/Placeholders/PlaceholderLoader";
import { CampaignPartnershipT, PartnershipT } from "../../../../types";
import { RadioNetworkT } from "./Filter";

const { Option } = Select;

const OptionWrapper = styled(FlexBox)({
  cursor: "pointer",
});

export type PartnershipOrCampaignPartnershipT =
  | PartnershipT
  | CampaignPartnershipT;

export const isCampaignPartnership = (
  value: PartnershipT | CampaignPartnershipT,
): value is CampaignPartnershipT => {
  return (value as CampaignPartnershipT).partnership !== undefined;
};

type mapToPartnershipsT = (
  data: PartnershipOrCampaignPartnershipT[] | undefined,
) => PartnershipT[];
const mapToPartnerships: mapToPartnershipsT = (data) => {
  return data
    ? data.map((item) =>
        isCampaignPartnership(item) ? item.partnership : item,
      )
    : [];
};

type FilterInfluencersT = {
  partnerships: PartnershipOrCampaignPartnershipT[] | undefined;
  setPartnerships: Dispatch<SetStateAction<string[]>>;
  partnershipIds: string[];
  network?: RadioNetworkT;
  onNetworkChangeHandle?: (
    partnerships: PartnershipT[] | undefined,
    setPartnershipIds: (values: string[]) => void,
  ) => void;
  isLoading: boolean;
};

const FilterInfluencers: FC<React.PropsWithChildren<FilterInfluencersT>> = ({
  partnerships,
  setPartnerships,
  partnershipIds,
  network,
  onNetworkChangeHandle,
  isLoading,
}) => {
  const [listPartnerships, setListPartnerships] = useState<PartnershipT[]>(
    mapToPartnerships(partnerships),
  );

  type TagRender = SelectProps["tagRender"];
  const partnershipRender: TagRender = (props) => {
    const partnership = listPartnerships?.find((p) => p.id === props.value);

    if (!partnership) {
      return <></>;
    }

    return (
      <Tag onClose={props.onClose} closable={props.closable}>
        {partnership.handle}
      </Tag>
    );
  };

  const filterOption = (
    input: string,
    option: OptionProps | undefined,
  ): boolean => {
    const partnership =
      option && listPartnerships?.find((p) => p.id === option.value);
    if (!partnership) return false;

    return (partnership.handle ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const onChangeHandle = (values: string[]): void => {
    setPartnerships(values);
  };

  const setPartnershipsIds = (values: string[]): void => {
    const filtered = partnerships?.filter((p) => values.includes(p.id));
    setListPartnerships(mapToPartnerships(filtered));
  };

  useEffect(() => {
    setListPartnerships(mapToPartnerships(partnerships));
  }, [partnerships]);

  useEffect(() => {
    if (network) {
      if (network === "all") {
        setListPartnerships(mapToPartnerships(partnerships));
      } else {
        onNetworkChangeHandle &&
          onNetworkChangeHandle(
            mapToPartnerships(partnerships),
            setPartnershipsIds,
          );
      }
    }
  }, [network]);

  const getContent = () => {
    if (isLoading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.influencers_list}
          count={4}
          direction="column"
        />
      );
    }

    return (
      <>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          tagRender={partnershipRender}
          dropdownRender={(list) => <>{list}</>}
          filterOption={filterOption}
          onChange={onChangeHandle}
          value={partnershipIds}
        >
          {listPartnerships &&
            listPartnerships.map((partnership) => (
              <Option key={partnership.id} value={partnership.id}>
                <OptionWrapper justifyContent="flex-start">
                  <AvatarTile
                    imageUrl={partnership.avatar_url}
                    name={partnership.handle}
                    network={partnership.network}
                    avatarSize={AvatarSizeE.small}
                    cursor="pointer"
                  />
                </OptionWrapper>
              </Option>
            ))}
        </Select>
      </>
    );
  };

  return getContent();
};

export default FilterInfluencers;
