import {
  DeleteOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Table as AntTable,
  Button,
  Form,
  Input,
  InputRef,
  Popconfirm,
  Select,
  Space,
  TableColumnType,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import { formatDate } from "helpers/formaters";
import { BaseSelectRef } from "rc-select";
import * as React from "react";
import { FC, MouseEvent, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrayTags from "../../../../components/ArrayTags";
import Drawer from "../../../../components/Drawer";
import FlexBox from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import { getClients } from "../../../../data-access/getClients";
import { updateClientsMutation } from "../../../../data-access/mutations/updateClientsMutation";
import { updateClient } from "../../../../data-access/updateClient";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { validateClientTitle } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { ClientT } from "../../../types";
import { removeCampaign } from "../../data-access/removeCampaign";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CampaignT } from "../../types";

const BrandSettingsButton = styled(Button)({
  visibility: "hidden",
});

const DeleteButton = styled(Button)({
  visibility: "hidden",
});

const StyledTableWrapper = styled(FlexBox)({
  ".ant-table-row": {
    "&:hover": {
      cursor: "pointer",
    },

    "&:hover, &:focus-within": {
      [`${DeleteButton}`]: {
        visibility: "visible",
      },
    },
  },
});

const BrandCell = styled(FlexBox)({
  "&:hover, &:focus-within": {
    [`${BrandSettingsButton}`]: {
      visibility: "visible",
    },
  },
});

const BrandFilter = styled(FlexBox)({
  padding: rem(8),
  gap: rem(12),
});

interface DataType {
  key: string;
  campaign: CampaignT;
}

type DataIndex = keyof CampaignT;

type TableT = {
  dataIsLoading: boolean;
  campaigns?: CampaignT[];
  refetch: () => void;
};

const Table: FC<React.PropsWithChildren<TableT>> = ({
  dataIsLoading,
  campaigns,
  refetch,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const [editClient, setEditClient] = useState<ClientT>();
  const [updatedClientName, setUpdatedClientName] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [brandSearchValue, setBrandSearchValue] = useState<any>();

  const titleSearchInput = useRef<InputRef>(null);
  const brandSearchInput = useRef<BaseSelectRef>(null);
  const navigate = useRouterNavigate();
  const { t, i18n } = useTranslate("brands.campaigns");

  const { data: clients } = useQuery(QueryKeys.CLIENTS, () => getClients());

  const queryClient = useQueryClient();
  const { mutate: mutateUpdateClient, isLoading } = useMutation(
    () => {
      if (!editClient) return Promise.reject();

      return updateClient(editClient?.id, { name: updatedClientName });
    },
    {
      onSuccess: (data) => {
        updateClientsMutation(queryClient, data, "update");
        refetch();
        setEditClient(undefined);
        setUpdatedClientName("");
      },
    },
  );
  const { mutate: mutateRemoveCampaign } = useMutation(
    (id: string) => removeCampaign(id),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex,
  ): void => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const onRemoveConfirmHandle = (
    e: MouseEvent | undefined,
    id: string,
  ): void => {
    e?.stopPropagation();

    mutateRemoveCampaign(id);
  };

  const handleReset = (clearFilters: () => void): void => {
    clearFilters();
    setSearchText("");
  };

  const onBrandEditClickHandle = (event: MouseEvent, client: ClientT): void => {
    event.stopPropagation();
    setEditClient(client);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={titleSearchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("table.search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("table.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record.campaign[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => titleSearchInput.current?.select(), 100);
      }
    },
    // @ts-ignore
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        // @ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={record.campaign[dataIndex] as string}
        />
      ) : (
        record.campaign[dataIndex]
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.title"),
      dataIndex: "title",
      render: (_, record) => record.campaign.title,
      sorter: (a, b) => a.campaign.title.localeCompare(b.campaign.title),
      ...getColumnSearchProps("title"),
    },
    {
      title: t("table.brand"),
      dataIndex: "brand",
      render: (_, record) => (
        <BrandCell justifyContent="space-between">
          {record.campaign.client.name}
          <BrandSettingsButton
            onClick={(e) => onBrandEditClickHandle(e, record.campaign.client)}
            type="text"
            icon={<SettingOutlined />}
          />
        </BrandCell>
      ),
      sorter: (a, b) =>
        a.campaign.client.name.localeCompare(b.campaign.client.name),
      onFilter: (value, record) => {
        return record.campaign.client.name == value;
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => brandSearchInput.current?.focus(), 100);
        }
      },
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        const options = campaigns
          ? [...new Set(campaigns.map((c) => c.client.name))]
              .map((name) => ({ text: name, value: name }))
              .sort((a, b) => a.text.localeCompare(b.text))
          : [];
        return (
          <BrandFilter justifyContent="space-between">
            <Select
              allowClear
              showSearch
              ref={brandSearchInput}
              style={{ width: rem(150) }}
              options={options}
              value={brandSearchValue}
              onChange={(val) => {
                setSelectedKeys([val]);
                confirm();
                setBrandSearchValue(val);
              }}
            />
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
                setBrandSearchValue(undefined);
              }}
              style={{ width: 90 }}
            >
              {t("table.reset")}
            </Button>
          </BrandFilter>
        );
      },
    },
    {
      title: t("table.instagram_mentions"),
      dataIndex: "instagram_mentions",
      render: (_, record) => (
        <ArrayTags array={record.campaign.instagram_mentions} prefix="@" />
      ),
      sorter: (a, b) => {
        const x = a.campaign.instagram_mentions?.join(",") || "";
        const y = b.campaign.instagram_mentions?.join(",") || "";
        return x.localeCompare(y);
      },
      filters: campaigns
        ? [...new Set(campaigns.map((c) => c.instagram_mentions).flat())].map(
            (mention) => ({
              text: mention,
              value: mention,
            }),
          )
        : [],
      onFilter: (value, record) =>
        record.campaign.instagram_mentions.indexOf(value as string) === 0,
    },
    {
      title: t("table.hashtags"),
      dataIndex: "hashtags",
      render: (_, record) => (
        <ArrayTags array={record.campaign.hashtags} prefix="#" />
      ),
      sorter: (a, b) => {
        const x = a.campaign.hashtags?.join(",") || "";
        const y = b.campaign.hashtags?.join(",") || "";
        return x.localeCompare(y);
      },
    },
    {
      title: t("table.influencers"),
      dataIndex: "influencers",
      render: (_, record) => record.campaign.partnership_ids.length,
      sorter: (a, b) =>
        a.campaign.partnership_ids.length - b.campaign.partnership_ids.length,
    },
    {
      title: t("table.outputs"),
      dataIndex: "outputs",
      render: (_, record) => record.campaign.media_groups_count,
      sorter: (a, b) =>
        a.campaign.media_groups_count - b.campaign.media_groups_count,
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) => formatDate(record.campaign.created_at, i18n),
      sorter: (a, b) =>
        a.campaign.created_at.localeCompare(b.campaign.created_at),
    },
    {
      dataIndex: "actions",
      width: rem(60),
      render: (_, record) => (
        <Popconfirm
          title={t("remove_modal.title")}
          description={t("remove_modal.question")}
          okText={t("remove_modal.confirm")}
          cancelText={t("remove_modal.cancel")}
          okButtonProps={{ disabled: isLoading }}
          onConfirm={(e) => onRemoveConfirmHandle(e, record.campaign.id)}
          onCancel={(e) => e?.stopPropagation()}
        >
          <DeleteButton
            onClick={(e) => e.stopPropagation()}
            type="text"
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];

  const getTableContent = () => {
    if (campaigns && campaigns.length > 0) {
      const data: DataType[] = campaigns.map((campaign) => ({
        key: campaign.id,
        campaign: campaign,
      }));

      return (
        <>
          <StyledTableWrapper>
            <AntTable
              sticky
              style={{ width: "100%" }}
              columns={columns}
              dataSource={data}
              pagination={{ defaultPageSize: 20 }}
              loading={dataIsLoading}
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(
                      NavigationKeyE.Campaign,
                      `${record.campaign.id}/influencers`,
                    );
                  },
                };
              }}
            />
          </StyledTableWrapper>

          <Drawer
            title={t("form.edit_brand.title")}
            open={!!editClient}
            onClose={() => setEditClient(undefined)}
            onConfirm={{
              handle: mutateUpdateClient,
              label: t("form.update"),
              isLoading: isLoading,
            }}
          >
            {editClient && (
              <Form layout="vertical">
                <Form.Item
                  label={t("form.name")}
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        validateClientTitle(value, clients ? clients : []),
                    }),
                  ]}
                >
                  <Input
                    defaultValue={editClient?.name}
                    onChange={(e) => setUpdatedClientName(e.target.value)}
                  />
                </Form.Item>
              </Form>
            )}
          </Drawer>
        </>
      );
    }

    return null;
  };

  return <Panel>{getTableContent()}</Panel>;
};

export default Table;
