import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { UserForUserManagementT } from "entry-files/company-root/types";
import { ClientT } from "entry-files/types";
import { QueryKeys } from "helpers/queryKeys";
import { useQueryClient as useQueryClientV3 } from "react-query";
import { sessionKeys, usersKeys } from "../queryKeys";

// CREATE
type CreateUserT = {
  email: string;
};
type CreateUsersVariablesT = {
  users: Array<CreateUserT>;
};
type CreateUsersDataT = { users: Array<UserForUserManagementT> };
export const useCreateUsersMutation = () => {
  const queryClient = useQueryClient();
  const queryClientV3 = useQueryClientV3();

  return useMutation<CreateUsersDataT, Error, CreateUsersVariablesT>({
    mutationFn: async (variables) => {
      const response = await axios.post("/api/v1/brands/users", variables);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersKeys.all });
      queryClient.invalidateQueries({ queryKey: sessionKeys.all });
      queryClientV3.invalidateQueries({ queryKey: QueryKeys.SESSION }); // ToDo: Remove once we have everything in react-query v5
    },
  });
};

// DELETE
type DeleteUsersVariablesT = {
  id: number;
};
type DeleteUsersDataT = Array<ClientT>;
export const useDeleteUsersMutation = () => {
  const queryClient = useQueryClient();
  const queryClientV3 = useQueryClientV3();

  return useMutation<DeleteUsersDataT, Error, DeleteUsersVariablesT>({
    mutationFn: async ({ id }) => {
      const response = await axios.delete(`/api/v1/brands/users/${id}`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersKeys.all });
      queryClient.invalidateQueries({ queryKey: sessionKeys.all });
      queryClientV3.invalidateQueries({ queryKey: QueryKeys.SESSION }); // ToDo: Remove once we have everything in react-query v5
    },
  });
};

// REINVITE
type ReinviteUsersVariablesT = {
  id: number;
};
export const useReinviteUsersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ReinviteUsersVariablesT>({
    mutationFn: async ({ id }) => {
      await axios.post(`/api/v1/brands/users/${id}/reinvite`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersKeys.all });
    },
  });
};
