import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type SortingOrderT = "asc" | "desc";
export type SortingOptionsT = {
  order: SortingOrderT;
  attribute: string;
};

const sortCollection = <T>(
  collection: T[] | undefined,
  sortingOptions: SortingOptionsT,
): T[] | undefined => {
  const newCollection: T[] = [];

  if (collection && collection.length > 0) {
    const { attribute } = sortingOptions;
    const values = collection
      .map((p) => _.get(p, attribute))
      .filter((p) => p !== null);

    if (values.length > 0) {
      const sorted = collection.sort((a, b) => {
        const x = _.get(a, attribute) || 0;
        const y = _.get(b, attribute) || 0;
        const sortMultiplier = sortingOptions.order == "asc" ? 1 : -1;

        return (x > y ? 1 : -1) * sortMultiplier;
      });

      return newCollection.concat(sorted);
    } else {
      return collection;
    }
  } else {
    return undefined;
  }
};

type useLocalSortReturnT<T> = {
  setSortingOptions: Dispatch<SetStateAction<SortingOptionsT>>;
  sortedCollection: T[] | undefined;
  sortingOptions: SortingOptionsT;
  setSortedCollection: Dispatch<SetStateAction<T[] | undefined>>;
};

export const useLocalSort = <T>(
  defaultAttribute: string,
  inputCollection: T[] | undefined,
): useLocalSortReturnT<T> => {
  const [sortingOptions, setSortingOptions] = useState<SortingOptionsT>({
    order: "desc",
    attribute: defaultAttribute,
  });
  const [sortedCollection, setSortedCollection] = useState<T[] | undefined>(
    inputCollection,
  );

  useEffect(() => {
    if (inputCollection) {
      setSortedCollection(sortCollection<T>(inputCollection, sortingOptions));
    }
  }, [inputCollection, sortingOptions]);

  return {
    sortedCollection,
    sortingOptions,
    setSortingOptions,
    setSortedCollection,
  };
};
