import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../../components/Heading";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { theme } from "../../../../../../styling/theme";
import { GeoCardDataT } from "../../types";
import { getCustomTicksGeoExport, getOptionsGeo } from "./options";

type GeoExportT = {
  data: GeoCardDataT;
};

const GeoExport: FC<React.PropsWithChildren<GeoExportT>> = ({ data }) => {
  const { cities, countries } = data;

  const { t } = useTranslate("brands.influencer_database.detail.card");

  const dataCities = Object.entries(cities).map((item) => ({
    [item[0]]: item[1],
  }));

  const dataCountries = Object.entries(countries).map((item) => ({
    [item[0]]: item[1],
  }));

  const valuesCities = dataCities
    .map((s) => Object.values(s))
    .map((a) => a.join())
    .slice(0, 3);
  const valuesCountries = dataCountries
    .map((s) => Object.values(s))
    .map((a) => a.join())
    .slice(0, 3);
  const labelsCities = dataCities
    .map((s) => Object.keys(s))
    .map((a) => a.join())
    .slice(0, 3);
  const labelsCountries = dataCountries
    .map((s) => Object.keys(s))
    .map((a) => a.join())
    .slice(0, 3);

  const chartDataCities = {
    labels: labelsCities,
    datasets: [
      {
        data: valuesCities,
        backgroundColor: [
          theme.color.primaryColor,
          theme.color.lightPrimaryColor,
          theme.color.yellowColor,
          theme.color.yellowHoverColor,
        ],
        barThickness: 16,
        borderRadius: 5,
      },
    ],
  };

  const chartDataCountries = {
    labels: labelsCountries,
    datasets: [
      {
        data: valuesCountries,
        backgroundColor: [
          theme.color.primaryColor,
          theme.color.lightPrimaryColor,
          theme.color.yellowColor,
          theme.color.yellowHoverColor,
        ],
        barThickness: 16,
        borderRadius: 5,
      },
    ],
  };

  return (
    <FlexBox customWidth={CustomWidthE.full} justifyContent="space-between">
      <FlexBox
        flexDirection="column"
        customWidth={CustomWidthE.half}
        alignItems="flex-start"
      >
        <Heading
          headingType={HeadingTypeT.h3}
          heading={t("others.countries")}
          spaceBottom={"mini"}
          color={theme.color.textGreyColor}
        />
        <Bar
          data={chartDataCountries}
          options={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionsGeo(valuesCountries, labelsCountries, "countries") as any
          }
          height={250}
          plugins={[getCustomTicksGeoExport("countries")]}
        />
      </FlexBox>
      <FlexBox
        flexDirection="column"
        customWidth={CustomWidthE.half}
        alignItems="flex-start"
      >
        <Heading
          headingType={HeadingTypeT.h3}
          heading={t("others.cities")}
          spaceBottom={"mini"}
          color={theme.color.textGreyColor}
        />
        <>
          {valuesCities && valuesCities.length > 0 && (
            <Bar
              data={chartDataCities}
              options={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getOptionsGeo(valuesCities, labelsCities, "cities") as any
              }
              height={250}
              plugins={[getCustomTicksGeoExport("cities")]}
            />
          )}
        </>
      </FlexBox>
    </FlexBox>
  );
};

export default GeoExport;
