import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { rem } from "../../../styling/theme";
import { SubscriptionEntitlementFeaturesT } from "../../types";
import { SubscriptionContext } from "../context-providers/SubscriptionsContextProvider";
import CurrentUsage from "./CurrentUsage";

const HeaderWrapper = styled(FlexBox)({
  padding: `${rem(10)} ${rem(10)} ${rem(20)} ${rem(10)}`,
  gap: rem(16),
});

const Header = styled(FlexBox)({
  width: "100%",
  gap: rem(4),
});

type LimitHeaderT = {
  title?: string;
  onClickHandle: (value: boolean) => void;
  submitTitle?: string;
  entitlementFeature: SubscriptionEntitlementFeaturesT;
  disableCreate?: boolean;
  disableCreateMessage?: string;
  hideCreate?: boolean;
  contentPosition?: "left" | "right";
};

const LimitHeader: FC<React.PropsWithChildren<LimitHeaderT>> = ({
  title,
  onClickHandle,
  submitTitle,
  entitlementFeature,
  disableCreate = false,
  disableCreateMessage,
  hideCreate = false,
  contentPosition = "left",
}) => {
  const tresholdEntitlements: SubscriptionEntitlementFeaturesT[] = [
    "database-reports",
    "database-searches",
  ];
  const { entitlementValue, session } = useContext(ApplicationContext);
  const { getFeatureUsage, setSubscription, isTrial, openTrialModal } =
    useContext(SubscriptionContext);
  const subscription = session?.subscription || undefined;

  const usage = getFeatureUsage(entitlementFeature);
  const monthCount = (usage && usage.used) || 0;
  const limit = (entitlementValue(entitlementFeature) as number) || 0;
  const limitReached = monthCount >= limit;

  const onSubmitClickHandle = (): void => {
    limitReached && isTrial ? openTrialModal() : onClickHandle(true);
  };

  useEffect(() => {
    limitReached && setSubscription(subscription);
  }, [subscription]);

  return (
    <HeaderWrapper
      customWidth={CustomWidthE.full}
      justifyContent="space-between"
      flexDirection="row"
    >
      <Header
        justifyContent={contentPosition === "left" ? "flex-start" : "flex-end"}
      >
        {title && <Paragraph paragraph={title} fontWeight={600} />}
        <CurrentUsage
          entitlementFeature={entitlementFeature}
          showTreshold={
            tresholdEntitlements.includes(entitlementFeature) ? 0.8 : 0
          }
        />
      </Header>
      {!hideCreate && (
        <Tooltip title={disableCreate && disableCreateMessage}>
          <Button
            onClick={onSubmitClickHandle}
            disabled={isTrial ? false : limitReached || disableCreate}
            icon={<PlusOutlined />}
            type={isTrial ? "default" : "primary"}
          >
            {submitTitle}
          </Button>
        </Tooltip>
      )}
    </HeaderWrapper>
  );
};

export default LimitHeader;
