import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import FilesUploadArea from "../../../../../features/files-upload/FilesUploadArea";
import PendingFileUpload from "../../../../../features/files-upload/PendingFileUpload";
import { useMutateMedia } from "../../../../../hooks/useMutateMedia";
import {
  FileStatusTypeE,
  PendingFileT,
} from "../../../../../hooks/usePendingFile";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupT, MediaT } from "../../../types";

const Wrapper = styled(FlexBox)({
  marginTop: rem(6),
  padding: `${rem(8)} ${rem(6)} 0`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
});

type InsightsT = {
  media: MediaT;
  mediaGroup: MediaGroupT;
  liveAddedMedia?: boolean;
};

const Insights: FC<React.PropsWithChildren<InsightsT>> = ({
  media,
  mediaGroup,
  liveAddedMedia,
}) => {
  const [pendingFiles, setPendingFiles] = useState<PendingFileT[]>([]);
  const { t } = useTranslate("brands.campaign.media_groups");

  const { media_files, id } = media;
  const { partnership } = mediaGroup;
  const { setMediaContentState, queryStack, addedQueryStack } = useContext(
    MediaGroupsHelperContext,
  );

  const insights = media_files.filter((item) => item.kind === "insight");

  const { mutateMedia, isDisabled } = useMutateMedia(
    id,
    liveAddedMedia ? addedQueryStack : queryStack,
    () => {
      setPendingFiles([]);
    },
  );

  const setMediaContentStateHandle = (): void => {
    setMediaContentState({
      files: insights,
      mediaId: id,
      partnershipId: partnership.id,
      parentCurrentImage: 0,
      setParentCurrentImage: () => {},
      queryStack: liveAddedMedia ? addedQueryStack : queryStack,
    });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (pendingFiles.length > 0) {
        const uploaded = pendingFiles
          .map((file) => file.status === FileStatusTypeE.uploaded && file.link)
          .filter((url) => url) as string[];

        if (pendingFiles.length === uploaded.length) {
          mutateMedia({ insight_urls: uploaded });
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [pendingFiles]);

  return (
    <Wrapper customWidth={CustomWidthE.full} justifyContent="flex-start">
      <Paragraph
        paragraph={t("insights")}
        paragraphSize="micro"
        color={theme.color.textGreyColor}
      />
      <Spacer />
      <FlexBox position="relative">
        <FilesUploadArea
          isDisabled={isDisabled}
          setPendingFiles={setPendingFiles}
        />
        <BlueLink
          text={"Upload"}
          icon={IconTypeE.plusSquare}
          iconSize="mini"
          paragraphSize="small"
          marginTop="0"
        />
      </FlexBox>
      <Spacer size="mini" />
      {insights.length > 0 && (
        <BlueLink
          text={"Show"}
          icon={IconTypeE.folder}
          iconSize="mini"
          paragraphSize="small"
          marginTop="0"
          onClick={setMediaContentStateHandle}
        />
      )}
      {pendingFiles.length > 0 &&
        pendingFiles.map((file) => (
          <PendingFileUpload
            key={file.id}
            pendingFile={file}
            setPendingFiles={setPendingFiles}
          />
        ))}
    </Wrapper>
  );
};

export default Insights;
