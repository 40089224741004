import { css } from "@emotion/react";

export const RemoveInputStyles = css({
  fontFamily: '"Inter", sans-serif',
  border: "none",
  backgroundImage: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  WebkitAppearance: "none",
  outline: "none",
});
