import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import Button from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Modal from "../../../../../components/Modal";
import { IllustrationWrapper } from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../../../ui/Illustrations";
import BrandModal from "./BrandHandleModal";

const AddBrandHandleWrapper = styled(FlexBox)({
  padding: rem(8),
  borderTop: `1px solid ${theme.color.greyColor}`,
});

const AddBrandHandleLine = styled(FlexBox)({
  position: "relative",
  padding: rem(24),
  backgroundColor: theme.color.greyLightColor,
  borderRadius: rem(4),
  zIndex: 1,
  overflow: "hidden",

  [theme.media.maxSm]: {
    flexDirection: "column",
  },
});

const IllustrationPosition = styled(FlexBox)({
  position: "absolute",
  right: "20%",
  top: rem(-20),
  opacity: 0.2,
  zIndex: -1,
});

export type NewBrandHandleResponseT = "isTracking" | "done" | false;

const AddBrandHandle: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity.add",
  );

  const [brandOpen, setBrandOpen] = useState<boolean>(false);
  const [newBrandStatus, setNewBrandStatus] =
    useState<NewBrandHandleResponseT>(false);

  const closeModalHandle = (): void => {
    setBrandOpen(false);

    setTimeout(() => {
      setNewBrandStatus(false);
    }, 250);
  };

  return (
    <AddBrandHandleWrapper customWidth={CustomWidthE.full}>
      <AddBrandHandleLine
        customWidth={CustomWidthE.full}
        justifyContent="space-between"
      >
        <FlexBox flexDirection="column" alignItems="stretch">
          <Paragraph
            paragraph={t("title")}
            paragraphSize="big"
            fontWeight={600}
          />
          <Spacer size="micro" direction="vertical" />
          <Paragraph paragraph={t("text")} color={theme.color.textGreyColor} />
        </FlexBox>
        <Button onClick={() => setBrandOpen(true)}>{t("action")}</Button>
        <IllustrationPosition>
          <IllustrationWrapper size={rem(500)}>
            {getIllustration(IllustrationTypeE.horizontal_books)}
          </IllustrationWrapper>
        </IllustrationPosition>
      </AddBrandHandleLine>

      <Modal
        visible={!!brandOpen}
        close={closeModalHandle}
        slim={true}
        renderChildren={(visible, close) => {
          return (
            <BrandModal
              status={newBrandStatus}
              setStatus={setNewBrandStatus}
              closeModal={close}
            />
          );
        }}
      />
    </AddBrandHandleWrapper>
  );
};

export default AddBrandHandle;
