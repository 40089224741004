import {
  CheckCircleFilled,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Badge, Button, Dropdown } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Drawer from "../../../../../components/Drawer";
import FlexBox from "../../../../../components/FlexBox";
import { designToken } from "../../../../../helpers/antDesign";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { getCampaignMediaGroups } from "../../../data-access/getCampaignMediaGroups";
import { setMediaGroupPendingState } from "../../../data-access/mutation/setMediaGroupPendingState";
import { CampaignT, OutputsFilterStateT } from "../../../types";
import MediaGroupsContainer from "../../media-groups/MediaGoupsContainer";
import Filter from "./filter/Filter";

const FilterWrapper = styled(FlexBox)({
  justifyContent: "flex-end",
  margin: `${rem(-32)} ${rem(85)} ${rem(16)} 0`,
  gap: rem(12),
});

type CardsT = {
  campaign: CampaignT;
};

const Cards: FC<React.PropsWithChildren<CardsT>> = ({ campaign }) => {
  const [openedFilter, setOpenedFilter] = useState(false);
  const [filterState, setFilterState] = useState<
    OutputsFilterStateT | undefined
  >(undefined);
  const defaultFilterState: OutputsFilterStateT = { partnership_ids: [] };

  const { t } = useTranslate("brands.campaign.outputs");

  const queryCLient = useQueryClient();
  const dropdownItems = [
    {
      key: "instagram",
      onClick: () => setMediaGroupPendingState(queryCLient, true, "instagram"),
      label: "Instagram",
    },
    {
      key: "tiktok",
      onClick: () => setMediaGroupPendingState(queryCLient, true, "tiktok"),
      label: "TikTok",
    },
  ];

  const {
    data: mediaGroups,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery(
    QueryKeys.CAMPAIGN_MEDIA_GROUPS,
    () =>
      getCampaignMediaGroups({
        apiUrl: getUrlWithQuery(
          getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS, campaign.id),
          {
            ...filterState,
          },
        ),
      }),
    {
      retry: false,
      onSuccess: () => setOpenedFilter(false),
    },
  );

  useEffect(() => {
    trackEvent(MixpanelEventT.campaignsOutputsGridViewImpression, {
      campaignId: campaign.id,
    });
  }, []);

  return (
    <>
      <FilterWrapper>
        <Badge
          count={
            filterState !== undefined &&
            JSON.stringify(filterState) !==
              JSON.stringify(defaultFilterState) ? (
              <CheckCircleFilled style={{ color: designToken["green-6"] }} />
            ) : undefined
          }
          color="green"
        >
          <Button
            onClick={() => setOpenedFilter(true)}
            icon={<FilterOutlined />}
          >
            {t("filter.title")}
          </Button>
        </Badge>
        {campaign.tiktok_mentions.length > 0 ? (
          <Dropdown menu={{ items: dropdownItems }}>
            <Button type="primary">
              Add new
              <DownOutlined />
            </Button>
          </Dropdown>
        ) : (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() =>
              setMediaGroupPendingState(queryCLient, true, "instagram")
            }
          >
            Add new
          </Button>
        )}
      </FilterWrapper>
      <MediaGroupsContainer
        campaign={campaign}
        mediaGroups={mediaGroups}
        queryStack={QueryKeys.CAMPAIGN_MEDIA_GROUPS}
        addedQueryStack={QueryKeys.CAMPAIGN_MEDIA_GROUPS_ADDED}
        loading={isRefetching || isLoading}
      />

      <Drawer
        open={openedFilter}
        title="Filter"
        onClose={() => setOpenedFilter(false)}
        onConfirm={{
          handle: refetch,
          label: t("filter.confirm"),
        }}
        // extraLeft={
        //   <BlueLink type="grey" text={t("clear")} onClick={clearFilter} />
        // }
      >
        <Filter setFiliterState={setFilterState} campaignId={campaign.id} />
      </Drawer>
    </>
  );
};

export default Cards;
