import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import Paragraph from "../components/Paragraph";
import Spacer from "../components/Spacer";
import { rem, theme } from "../styling/theme";
import Icon, { IconTypeE } from "./icons/Icon";

const Wrapper = styled(FlexBox)<{
  active: boolean;
  background: string;
  orientation: "vertical" | "horizontal";
}>(({ active, background }) => ({
  maxWidth: rem(105),
  padding: rem(8),
  border: "2px solid",
  borderColor: active ? theme.color.yellowColor : theme.color.greyLightColor,
  backgroundColor: active ? theme.color.yellowLightHoverColor : background,
  borderRadius: rem(8),
  transition: "all 0.3s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
  userSelect: "none",

  ["&:hover"]: {
    backgroundColor: theme.color.yellowLightHoverColor,
    borderColor: theme.color.yellowColor,
  },
}));

type CheckBigIconT = {
  active: boolean;
  onClickHandle: () => void;
  icon: IconTypeE;
  orientation?: "vertical" | "horizontal";
  label?: string;
  customWidth?: CustomWidthE;
  background?: string;
  marginRight?: boolean;
};

const CheckBigIcon: FC<React.PropsWithChildren<CheckBigIconT>> = ({
  active,
  onClickHandle,
  icon,
  orientation = "vertical",
  label,
  customWidth = CustomWidthE.third,
  background = "transparent",
  marginRight,
}) => {
  const color = active
    ? theme.color.textGreyDarkColor
    : theme.color.textLightGreyColor;

  return (
    <>
      <Wrapper
        background={background}
        active={active}
        onClick={onClickHandle}
        flexDirection={orientation === "vertical" ? "column" : "row"}
        orientation={orientation}
        customWidth={customWidth}
      >
        <Icon icon={icon} color={color} />
        {label && (
          <>
            <Spacer size="micro" direction={orientation} />
            <Paragraph
              paragraphSize={"micro"}
              fontWeight={600}
              paragraph={label}
              color={color}
            />
          </>
        )}
      </Wrapper>
      {marginRight && <Spacer size="micro" />}
    </>
  );
};

export default CheckBigIcon;
