import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import * as React from "react";
import { FC } from "react";
import {
  NavigationKeyE,
  useRouterNavigate,
} from "../entry-files/company-root/router";

const StyledTabs = styled(Tabs)({
  ".ant-tabs-nav": {
    marginBottom: 0,
  },
});

export type RoutingTabT = {
  value: string;
  label: string;
  icon?: React.ReactNode;
};

type RoutingTabsT = {
  rootPath: NavigationKeyE;
  id?: string | number;
  tabs: RoutingTabT[];
};

const RoutingTabs: FC<React.PropsWithChildren<RoutingTabsT>> = ({
  rootPath,
  id,
  tabs,
}) => {
  const regex = new RegExp(
    `${rootPath}${id ? `/${id}` : ""}/(${tabs.map((t) => t.value).join("|")})`,
  );
  const match = window.location.pathname.match(regex);
  const activeTab = match ? match[1] : tabs[0].value;

  const navigate = useRouterNavigate();

  const options: TabsProps["items"] = tabs.map((t) => ({
    key: t.value,
    label: t.label,
    icon: t.icon,
  }));

  const navigationId = (key: string): string => {
    return id ? `${id}/${key}` : key;
  };

  return (
    <StyledTabs
      style={{ width: "100%" }}
      items={options}
      onChange={(key) => navigate(rootPath, navigationId(key))}
      activeKey={activeTab}
    />
  );
};

export default RoutingTabs;
