import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import React from "react";

const TikTokSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
    <path
      fill={"currentColor"}
      d="M7 0V12.6C7 13.6171 6.2388 14.4 5.25 14.4C4.2612 14.4 3.5 13.6171 3.5 12.6C3.5 11.5829 4.2612 10.8 5.25 10.8V7.2C2.3713 7.2 0 9.63905 0 12.6C0 15.5609 2.3713 18 5.25 18C8.1287 18 10.5 15.5609 10.5 12.6V5.81836C11.5783 6.60842 12.7035 7.2 14 7.2V3.6C13.9172 3.6 12.7097 3.20569 11.8672 2.45039C11.0247 1.69509 10.5 0.746984 10.5 0H7Z"
    />
  </svg>
);

export const TikTokIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={TikTokSvg} {...props} />;
};

const LogoLetterSvg = () => (
  <svg width="21" height="36" viewBox="0 0 21 36">
    <path
      fill="#523FC2"
      d="M14.6549 35.75C13.1554 35.75 11.7467 35.4698 10.4289 34.9093C9.11114 34.3338 7.9524 33.5537 6.95269 32.5691C5.96814 31.5694 5.18807 30.4107 4.61248 29.0929C4.05204 27.7751 3.77183 26.3664 3.77183 24.8669V17.6418H0.75V11.4618H3.77183V1.73743H9.9518V11.4618H19.3581V17.6418H9.9518V24.8669C9.9518 25.5182 10.073 26.1317 10.3153 26.7072C10.5577 27.2677 10.8909 27.76 11.315 28.1841C11.7391 28.6082 12.239 28.949 12.8146 29.2065C13.3902 29.4489 14.0036 29.57 14.6549 29.57H19.3581V35.75H14.6549Z"
    />
  </svg>
);

export const LogoLetterIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={LogoLetterSvg} {...props} />;
};

const LogoLetterDotSvg = () => (
  <svg width="21" height="36" viewBox="0 0 21 36">
    <path
      fill="#31C5C7"
      d="M20.3451 4.88513C20.3451 5.46072 20.2315 5.99843 20.0043 6.49828C19.7922 6.99814 19.4968 7.4374 19.1182 7.81607C18.7395 8.1796 18.2927 8.47497 17.7777 8.70217C17.2778 8.91423 16.7401 9.02026 16.1645 9.02026C15.5889 9.02026 15.0436 8.91423 14.5286 8.70217C14.0288 8.47497 13.5895 8.1796 13.2108 7.81607C12.8473 7.4374 12.5519 6.99814 12.3247 6.49828C12.1127 5.99843 12.0067 5.46072 12.0067 4.88513C12.0067 4.32469 12.1127 3.79455 12.3247 3.2947C12.5519 2.7797 12.8473 2.34043 13.2108 1.97691C13.5895 1.59823 14.0288 1.30287 14.5286 1.09081C15.0436 0.863602 15.5889 0.75 16.1645 0.75C16.7401 0.75 17.2778 0.863602 17.7777 1.09081C18.2927 1.30287 18.7395 1.59823 19.1182 1.97691C19.4968 2.34043 19.7922 2.7797 20.0043 3.2947C20.2315 3.79455 20.3451 4.32469 20.3451 4.88513Z"
    />
    <path
      fill="#523FC2"
      d="M14.6549 35.75C13.1554 35.75 11.7467 35.4698 10.4289 34.9093C9.11114 34.3338 7.9524 33.5537 6.95269 32.5691C5.96814 31.5694 5.18807 30.4107 4.61248 29.0929C4.05204 27.7751 3.77183 26.3664 3.77183 24.8669V17.6418H0.75V11.4618H3.77183V1.73743H9.9518V11.4618H19.3581V17.6418H9.9518V24.8669C9.9518 25.5182 10.073 26.1317 10.3153 26.7072C10.5577 27.2677 10.8909 27.76 11.315 28.1841C11.7391 28.6082 12.239 28.949 12.8146 29.2065C13.3902 29.4489 14.0036 29.57 14.6549 29.57H19.3581V35.75H14.6549Z"
    />
  </svg>
);

export const LogoLetterDotIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={LogoLetterDotSvg} {...props} />;
};

const LogoTextSvg = () => (
  <svg width="129" height="35" viewBox="0 0 129 35" fill="none">
    <path
      d="M113.516 34.5522H107.409V10.5932H108.886L110.899 12.9198C111.883 12.0249 112.994 11.3389 114.232 10.8616C115.485 10.3695 116.79 10.1234 118.147 10.1234C119.609 10.1234 120.988 10.4068 122.286 10.9735C123.583 11.5253 124.717 12.2934 125.686 13.2777C126.655 14.2471 127.416 15.388 127.968 16.7004C128.535 17.9979 128.818 19.3849 128.818 20.8614V34.5522H122.711V20.8614C122.711 20.235 122.591 19.6459 122.353 19.0941C122.114 18.5273 121.786 18.0352 121.369 17.6176C120.951 17.2 120.466 16.8719 119.914 16.6333C119.363 16.3947 118.774 16.2754 118.147 16.2754C117.506 16.2754 116.902 16.3947 116.335 16.6333C115.768 16.8719 115.276 17.2 114.859 17.6176C114.441 18.0352 114.113 18.5273 113.874 19.0941C113.636 19.6459 113.516 20.235 113.516 20.8614V34.5522Z"
      fill="#31C5C7"
    />
    <path
      d="M102.734 34.5525H96.5825V10.5934H102.734V34.5525Z"
      fill="#31C5C7"
    />
    <path
      d="M91.9072 34.5524H90.4308L88.0595 31.2639C87.4778 31.7859 86.8589 32.278 86.2027 32.7403C85.5614 33.1878 84.8828 33.583 84.167 33.926C83.4511 34.2541 82.7129 34.5151 81.9522 34.709C81.2066 34.9029 80.446 34.9998 79.6704 34.9998C77.9852 34.9998 76.3969 34.6866 74.9055 34.0602C73.429 33.4189 72.1315 32.539 71.013 31.4205C69.9093 30.287 69.0369 28.9522 68.3956 27.4161C67.7543 25.8651 67.4337 24.1798 67.4337 22.3603C67.4337 20.5558 67.7543 18.878 68.3956 17.3269C69.0369 15.7759 69.9093 14.4336 71.013 13.3002C72.1315 12.1667 73.429 11.2794 74.9055 10.6381C76.3969 9.99679 77.9852 9.67614 79.6704 9.67614C80.2073 9.67614 80.7591 9.72088 81.3259 9.81037C81.9075 9.89985 82.4668 10.049 83.0037 10.2578C83.5555 10.4517 84.07 10.7127 84.5473 11.0408C85.0245 11.3689 85.4272 11.7715 85.7553 12.2488V1.06342H91.9072V34.5524ZM85.7553 22.3603C85.7553 21.5252 85.5912 20.7198 85.2631 19.9443C84.9499 19.1539 84.5174 18.4604 83.9656 17.8638C83.4138 17.2524 82.7651 16.7677 82.0194 16.4097C81.2886 16.0369 80.5056 15.8505 79.6704 15.8505C78.8353 15.8505 78.0448 15.9996 77.2991 16.2979C76.5684 16.5961 75.9271 17.0286 75.3753 17.5954C74.8384 18.1472 74.4133 18.8258 74.1001 19.6311C73.7869 20.4365 73.6303 21.3462 73.6303 22.3603C73.6303 23.2403 73.7869 24.0754 74.1001 24.8659C74.4133 25.6563 74.8384 26.3498 75.3753 26.9463C75.9271 27.5429 76.5684 28.0127 77.2991 28.3557C78.0448 28.6987 78.8353 28.8702 79.6704 28.8702C80.5056 28.8702 81.2886 28.6912 82.0194 28.3333C82.7651 27.9605 83.4138 27.4758 83.9656 26.8792C84.5174 26.2678 84.9499 25.5743 85.2631 24.7987C85.5912 24.0083 85.7553 23.1955 85.7553 22.3603Z"
      fill="#523FC2"
    />
    <path
      d="M49.0896 34.5522H42.9824V10.5932H44.4588L46.4722 12.9198C47.4565 12.0249 48.5676 11.3389 49.8054 10.8616C51.0582 10.3695 52.3631 10.1234 53.7203 10.1234C55.1819 10.1234 56.5614 10.4068 57.8589 10.9735C59.1564 11.5253 60.2898 12.2934 61.2592 13.2777C62.2286 14.2471 62.9892 15.388 63.541 16.7004C64.1078 17.9979 64.3911 19.3849 64.3911 20.8614V34.5522H58.2839V20.8614C58.2839 20.235 58.1646 19.6459 57.926 19.0941C57.6874 18.5273 57.3593 18.0352 56.9417 17.6176C56.5241 17.2 56.0394 16.8719 55.4876 16.6333C54.9358 16.3947 54.3467 16.2754 53.7203 16.2754C53.079 16.2754 52.475 16.3947 51.9083 16.6333C51.3416 16.8719 50.8494 17.2 50.4318 17.6176C50.0142 18.0352 49.6861 18.5273 49.4475 19.0941C49.2089 19.6459 49.0896 20.235 49.0896 20.8614V34.5522Z"
      fill="#523FC2"
    />
    <path
      d="M28.4413 28.6915C28.6799 28.7661 28.9185 28.8183 29.1571 28.8481C29.3958 28.863 29.6344 28.8705 29.873 28.8705C30.4696 28.8705 31.0437 28.7884 31.5956 28.6244C32.1474 28.4603 32.6619 28.2292 33.1391 27.9309C33.6313 27.6177 34.0638 27.2449 34.4366 26.8124C34.8244 26.3649 35.1376 25.8728 35.3762 25.3359L39.8504 29.8324C39.2836 30.6378 38.6274 31.3611 37.8817 32.0024C37.151 32.6437 36.3531 33.188 35.4881 33.6354C34.638 34.0828 33.7357 34.4184 32.7812 34.6421C31.8416 34.8807 30.8722 35 29.873 35C28.1878 35 26.5994 34.6869 25.108 34.0605C23.6316 33.4341 22.3341 32.5616 21.2155 31.4431C20.1119 30.3246 19.2395 28.9972 18.5982 27.4611C17.9569 25.9101 17.6362 24.2099 17.6362 22.3606C17.6362 20.4665 17.9569 18.7365 18.5982 17.1706C19.2395 15.6046 20.1119 14.2698 21.2155 13.1662C22.3341 12.0626 23.6316 11.2051 25.108 10.5936C26.5994 9.98212 28.1878 9.67639 29.873 9.67639C30.8722 9.67639 31.8491 9.7957 32.8036 10.0343C33.7581 10.2729 34.6603 10.616 35.5104 11.0634C36.3754 11.5108 37.1808 12.0626 37.9265 12.7188C38.6722 13.3601 39.3284 14.0834 39.8951 14.8888L28.4413 28.6915ZM31.5732 16.0968C31.2898 15.9924 31.0065 15.9253 30.7231 15.8955C30.4547 15.8656 30.1713 15.8507 29.873 15.8507C29.0378 15.8507 28.2474 16.0073 27.5017 16.3205C26.7709 16.6188 26.1296 17.0513 25.5778 17.618C25.0409 18.1847 24.6159 18.8708 24.3027 19.6761C23.9895 20.4665 23.8329 21.3614 23.8329 22.3606C23.8329 22.5843 23.8404 22.8378 23.8553 23.1212C23.8851 23.4046 23.9224 23.6954 23.9671 23.9936C24.0268 24.277 24.0939 24.5529 24.1685 24.8214C24.243 25.0898 24.34 25.3284 24.4593 25.5372L31.5732 16.0968Z"
      fill="#523FC2"
    />
    <path
      d="M6.49649 34.5525H0.389282V10.5934H1.86575L3.87911 13.4345C4.86342 12.5397 5.98196 11.8537 7.23472 11.3764C8.48748 10.8843 9.78498 10.6382 11.1272 10.6382H16.5186V16.723H11.1272C10.4859 16.723 9.88192 16.8423 9.3152 17.081C8.74847 17.3196 8.25632 17.6477 7.83873 18.0653C7.42114 18.4829 7.09304 18.975 6.85442 19.5417C6.6158 20.1085 6.49649 20.7125 6.49649 21.3538V34.5525Z"
      fill="#523FC2"
    />
    <path
      d="M103.775 4.07147C103.775 4.6382 103.663 5.16764 103.44 5.65979C103.231 6.15195 102.94 6.58445 102.567 6.95729C102.194 7.31522 101.754 7.60604 101.247 7.82975C100.755 8.03854 100.226 8.14294 99.6589 8.14294C99.0922 8.14294 98.5553 8.03854 98.0482 7.82975C97.5561 7.60604 97.1236 7.31522 96.7507 6.95729C96.3928 6.58445 96.102 6.15195 95.8783 5.65979C95.6695 5.16764 95.5651 4.6382 95.5651 4.07147C95.5651 3.51966 95.6695 2.99768 95.8783 2.50552C96.102 1.99845 96.3928 1.56595 96.7507 1.20802C97.1236 0.835173 97.5561 0.544354 98.0482 0.335561C98.5553 0.111854 99.0922 0 99.6589 0C100.226 0 100.755 0.111854 101.247 0.335561C101.754 0.544354 102.194 0.835173 102.567 1.20802C102.94 1.56595 103.231 1.99845 103.44 2.50552C103.663 2.99768 103.775 3.51966 103.775 4.07147Z"
      fill="#31C5C7"
    />
  </svg>
);

export const LogoTextIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={LogoTextSvg} {...props} />;
};

const ChargebeeLogoSvg = () => (
  <svg
    width="149"
    height="20"
    viewBox="0 0 149 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_244_20113)">
      <path
        d="M6.77612 9.94483L19.909 6.81149V0H13.0995L6.77612 9.94483Z"
        fill="#FF3300"
      />
      <path
        d="M0.269289 9.82525C0.268761 10.6202 0.365231 11.4123 0.55656 12.1839L6.7777 9.94479L0.504851 7.68042C0.348556 8.38388 0.269567 9.10232 0.269289 9.82295V9.82525Z"
        fill="#FF3300"
      />
      <path
        d="M2.58276 3.49191L6.77577 9.94478L8.70853 0.0976562C6.31819 0.43521 4.1367 1.64396 2.58276 3.49191Z"
        fill="#FF3300"
      />
      <path
        d="M6.77612 9.94482L19.909 13.0759V19.8851H13.0995L6.77612 9.94482Z"
        fill="#FF3300"
      />
      <path
        d="M2.58311 16.3953L6.77496 9.94238L8.70657 19.7884C6.31655 19.4499 4.13537 18.2413 2.58081 16.3941L2.58311 16.3953Z"
        fill="#FF3300"
      />
      <path
        d="M31.5174 9.97587C31.5174 6.55978 34.1523 4.13794 37.8443 4.13794C40.2183 4.13794 42.0959 5.07932 42.9232 6.923C42.9232 6.92874 39.8897 7.7276 39.8897 7.7276C39.6282 7.44031 39.3084 7.21213 38.9517 7.05832C38.595 6.90451 38.2097 6.82859 37.8213 6.83564C36.1551 6.83564 34.8475 7.99656 34.8475 9.97702C34.8475 11.9575 36.1551 13.1195 37.8213 13.1195C38.2074 13.1293 38.591 13.0559 38.9463 12.9044C39.3016 12.7529 39.6202 12.5268 39.8805 12.2414L42.9152 12.9667C42.0879 14.7678 40.216 15.8184 37.8408 15.8184C34.1523 15.8184 31.5151 13.3931 31.5151 9.97587H31.5174Z"
        fill="#012A38"
      />
      <path
        d="M56.2561 9.15402V15.6494H52.9686V9.65517C52.9686 7.81609 52.124 6.97701 50.6704 6.97701C49.0893 6.97701 47.9505 7.94828 47.9505 10.0356V15.646H44.6619V0.804598L47.9494 0V5.48391C48.8365 4.5977 50.1016 4.13793 51.5564 4.13793C54.2337 4.13793 56.2584 5.6977 56.2584 9.15862L56.2561 9.15402Z"
        fill="#012A38"
      />
      <path
        d="M70.6135 3.77246V15.6495H67.4719V14.3414C66.6503 15.3322 65.4472 15.8184 63.9304 15.8184C60.7245 15.8184 58.2585 13.5403 58.2585 9.97591C58.2585 6.41154 60.7245 4.13798 63.9281 4.13798C65.3197 4.13798 66.5009 4.58166 67.3225 5.50809V4.58855L70.6112 3.78396L70.6135 3.77246ZM67.3869 9.97936C67.3869 8.01844 66.1229 6.83683 64.4981 6.83683C62.8733 6.83683 61.5886 8.01844 61.5886 9.97936C61.5886 11.9403 62.8526 13.1207 64.4981 13.1207C66.1436 13.1207 67.3869 11.9368 67.3869 9.97591V9.97936Z"
        fill="#012A38"
      />
      <path
        d="M80.4622 4.13798V7.17016C80.1876 7.15062 79.9762 7.12878 79.7245 7.12878C77.9113 7.12878 76.7082 8.11959 76.7082 10.292V15.6495H73.4207V4.53223L76.5611 3.77246V5.80005C77.3632 4.7035 78.711 4.13798 80.4622 4.13798Z"
        fill="#012A38"
      />
      <path
        d="M93.9533 3.77246V13.7506C93.9533 17.969 91.677 19.9092 87.5862 19.9092C85.4363 19.9092 83.3473 19.3817 82.0201 18.3495L83.3277 15.9874C84.2964 16.7679 85.8787 17.2736 87.3323 17.2736C89.6523 17.2736 90.6646 16.2196 90.6646 14.1702V13.6851C89.8005 14.6334 88.5561 15.0977 87.0806 15.0977C83.9402 15.0977 81.4099 12.9253 81.4099 9.61384C81.4099 6.30235 83.9402 4.13798 87.0806 4.13798C88.6629 4.13798 89.9694 4.66556 90.8347 5.76212V4.54143L93.9556 3.77476L93.9533 3.77246ZM90.7025 9.61729C90.7025 7.95062 89.4592 6.83453 87.7287 6.83453C85.9982 6.83453 84.7342 7.95292 84.7342 9.61729C84.7342 11.2817 85.9982 12.4 87.7287 12.4C89.4592 12.4 90.7025 11.2817 90.7025 9.61729Z"
        fill="#012A38"
      />
      <path
        d="M99.7089 11.8448C100.251 12.731 101.23 13.1816 102.543 13.1816C103.746 13.1816 104.611 12.823 105.39 12.0851L107.141 13.9839C106.088 15.1874 104.505 15.823 102.461 15.823C98.538 15.823 95.9871 13.3563 95.9871 9.98047C95.9871 6.60461 98.5599 4.13794 102.06 4.13794C105.195 4.13794 107.786 6.23104 107.92 9.71725L99.7112 11.8471M99.2148 9.89886L104.757 8.57587C104.441 7.41495 103.43 6.62185 102.06 6.62185C100.416 6.6253 99.2148 7.67127 99.2148 9.89886Z"
        fill="#012A38"
      />
      <path
        d="M127.294 11.8448C127.836 12.731 128.815 13.1816 130.129 13.1816C131.331 13.1816 132.197 12.823 132.975 12.0851L134.726 13.9839C133.672 15.1874 132.09 15.823 130.046 15.823C126.123 15.823 123.572 13.3563 123.572 9.98047C123.572 6.60461 126.145 4.13794 129.645 4.13794C132.78 4.13794 135.371 6.23104 135.505 9.71725L127.3 11.8494M126.803 9.90116L132.346 8.57817C132.029 7.41725 131.018 6.62415 129.648 6.62415C128.001 6.6253 126.8 7.67127 126.8 9.89886L126.803 9.90116Z"
        fill="#012A38"
      />
      <path
        d="M140.48 11.8448C141.023 12.731 142.002 13.1816 143.315 13.1816C144.517 13.1816 145.384 12.823 146.161 12.0851L147.913 13.9839C146.859 15.1874 145.277 15.823 143.231 15.823C139.31 15.823 136.759 13.3563 136.759 9.98047C136.759 6.60461 139.331 4.13794 142.831 4.13794C145.966 4.13794 148.557 6.23104 148.692 9.71725L140.486 11.8494M139.99 9.90116L145.532 8.57817C145.216 7.41725 144.205 6.62415 142.835 6.62415C141.187 6.6253 139.986 7.67127 139.986 9.89886L139.99 9.90116Z"
        fill="#012A38"
      />
      <path
        d="M122.288 9.97586C122.288 13.5391 119.823 15.8184 116.637 15.8184C115.118 15.8184 113.898 15.3322 113.075 14.3414V15.6494H109.932V0.804598L113.224 0V5.50345C114.064 4.57471 115.224 4.13793 116.636 4.13793C119.819 4.13793 122.287 6.41494 122.287 9.98046L122.288 9.97586ZM118.956 9.97586C118.956 8.01494 117.713 6.83448 116.066 6.83448C114.42 6.83448 113.179 8.01494 113.179 9.97586C113.179 11.9368 114.423 13.1172 116.067 13.1172C117.712 13.1172 118.957 11.9368 118.957 9.97586H118.956Z"
        fill="#012A38"
      />
    </g>
    <defs>
      <clipPath id="clip0_244_20113">
        <rect
          width="148.462"
          height="20"
          fill="white"
          transform="translate(0.269287)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ChargebeeLogo = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ChargebeeLogoSvg} {...props} />;
};

const ElipsesBgSvg = () => (
  <svg
    width="801"
    height="801"
    viewBox="0 0 801 801"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17_98)">
      <g opacity="0.32" filter="url(#filter0_f_17_98)">
        <circle
          cx="845.125"
          cy="245.125"
          r="150.392"
          transform="rotate(-14.4304 845.125 245.125)"
          fill="#31C5C7"
        />
      </g>
      <g opacity="0.32" filter="url(#filter1_f_17_98)">
        <circle
          cx="580.414"
          cy="47.4142"
          r="150.654"
          transform="rotate(117.696 580.414 47.4142)"
          fill="#523FC2"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_17_98"
        x="444.697"
        y="-155.303"
        width="800.857"
        height="800.857"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="125"
          result="effect1_foregroundBlur_17_98"
        />
      </filter>
      <filter
        id="filter1_f_17_98"
        x="129.729"
        y="-403.27"
        width="901.369"
        height="901.369"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="150"
          result="effect1_foregroundBlur_17_98"
        />
      </filter>
      <clipPath id="clip0_17_98">
        <rect width="801" height="801" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ElipsesBg = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ElipsesBgSvg} {...props} />;
};

const ModalElipsesBgSvg = () => (
  <svg
    width="674"
    height="400"
    viewBox="0 0 674 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.52" filter="url(#filter0_f_0_1)">
      <circle
        cx="593.154"
        cy="80.1536"
        r="97.0338"
        transform="rotate(-14.4304 593.154 80.1536)"
        fill="#31C5C7"
      />
    </g>
    <g opacity="0.52" filter="url(#filter1_f_0_1)">
      <circle
        cx="417.249"
        cy="289.249"
        r="116.463"
        transform="rotate(117.696 417.249 289.249)"
        fill="#523FC2"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_0_1"
        x="246.096"
        y="-266.904"
        width="694.114"
        height="694.114"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="125"
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <filter
        id="filter1_f_0_1"
        x="0.762451"
        y="-127.237"
        width="832.973"
        height="832.973"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="150"
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
    </defs>
  </svg>
);

export const ModalElipsesBg = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ModalElipsesBgSvg} {...props} />;
};

const UserMonitorSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4047 8.33077C10.0702 8.11265 9.71224 7.92674 9.33615 7.77638C9.33296 7.77487 9.32977 7.77374 9.32658 7.77261C9.32339 7.77148 9.3202 7.77035 9.31701 7.76884C10.3111 7.09045 10.9574 5.98543 10.9574 4.73869C10.9574 2.67337 9.18616 1 7 1C4.81384 1 3.04258 2.67337 3.04258 4.73869C3.04258 5.98543 3.68885 7.09045 4.68299 7.77035C4.6798 7.77186 4.67661 7.77299 4.67342 7.77412C4.67023 7.77525 4.66704 7.77638 4.66385 7.77789C3.94896 8.06281 3.30747 8.47136 2.75694 8.99297C2.20914 9.5094 1.77238 10.1212 1.47078 10.7945C1.17449 11.4536 1.01469 12.1604 1.00004 12.8764C0.999614 12.8925 1.0026 12.9085 1.00883 12.9235C1.01505 12.9384 1.02439 12.9521 1.03628 12.9636C1.04818 12.9751 1.0624 12.9843 1.0781 12.9905C1.0938 12.9968 1.11066 13 1.1277 13H2.08514C2.15535 13 2.2112 12.9472 2.2128 12.8824C2.24471 11.7186 2.73939 10.6286 3.61385 9.80251C4.51863 8.94774 5.72022 8.47739 7 8.47739C7.82245 8.47739 8.61261 8.67164 9.31245 9.03735C9.63336 8.74606 10.0018 8.5062 10.4047 8.33077ZM5.05959 6.57186C5.5782 7.06181 6.26756 7.33166 7 7.33166C7.73244 7.33166 8.4218 7.06181 8.94041 6.57186C9.45903 6.08191 9.74466 5.43065 9.74466 4.73869C9.74466 4.04673 9.45903 3.39548 8.94041 2.90553C8.4218 2.41558 7.73244 2.14573 7 2.14573C6.26756 2.14573 5.5782 2.41558 5.05959 2.90553C4.54097 3.39548 4.25534 4.04673 4.25534 4.73869C4.25534 5.43065 4.54097 6.08191 5.05959 6.57186Z"
      fill={"currentColor"}
    />
    <path
      d="M15.4241 9.50061L13.7401 9.69998C13.6843 9.70663 13.6606 9.77391 13.7003 9.81296L14.2017 10.3056L12.291 12.183L11.4304 11.3381C11.3771 11.2858 11.2918 11.2866 11.2393 11.3381L8.51965 14.0113C8.50706 14.0238 8.5 14.0407 8.5 14.0583C8.5 14.0759 8.50706 14.0927 8.51965 14.1052L8.90008 14.4807C8.92629 14.5064 8.96941 14.5064 8.99562 14.4807L11.3349 12.183L12.1947 13.0278C12.2479 13.0793 12.3333 13.0793 12.3857 13.0278L14.6802 10.7749L15.1815 11.2675C15.1905 11.2763 15.2018 11.2824 15.2142 11.2852C15.2265 11.288 15.2394 11.2873 15.2514 11.2832C15.2634 11.2792 15.2739 11.2719 15.2819 11.2622C15.2899 11.2525 15.2949 11.2408 15.2965 11.2285L15.4994 9.57371C15.5053 9.53135 15.4681 9.4948 15.4241 9.50061Z"
      fill={"currentColor"}
    />
  </svg>
);

export const UserMonitorIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={UserMonitorSvg} {...props} />;
};
