import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../../../../styling/theme";
import HtmlText from "../../ui/HtmlText";
import LargeColourWrapper, { LargeImageT } from "../../ui/LargeColourWrapper";

const Title = styled.h3`
  font-weight: 500;
  font-size: ${rem(20)};
  line-height: 135%;
  margin-top: 0;
  margin: 0;

  ${theme.media.lg} {
    font-size: ${rem(40)};
    line-height: ${rem(48)};
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  margin: ${rem(12)} 0 ${rem(24)} 0;
  opacity: 0.8;
  ${theme.media.lg} {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }
`;

type ApprovalInfoT = {
  title: string;
  content: string;
  image: LargeImageT;
};

const ApprovalInfo: FC<React.PropsWithChildren<ApprovalInfoT>> = ({
  title,
  content,
  image,
}) => {
  return (
    <>
      <LargeColourWrapper image={image}>
        <Title>{title}</Title>
        <Text>
          <HtmlText value={content} />
        </Text>
      </LargeColourWrapper>
    </>
  );
};

export default ApprovalInfo;
