import styled from "@emotion/styled";
import { Slider } from "antd";
import { SliderMarks } from "antd/es/slider";
import { debounce } from "lodash";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import { SearchQueryGlobalT } from "../../types";

const SliderWrapper = styled.div({
  width: "100%",
});

const InfluencerAge: FC<React.PropsWithChildren<unknown>> = () => {
  const [range, setRange] = useState<[number, number]>([1, 6]);

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const options: Record<number, string> = {
    1: "13",
    2: "18",
    3: "25",
    4: "35",
    5: "45",
    6: "65+",
  };
  const marks: SliderMarks = options;
  const valueLabels: Record<number, string> = {
    1: "13",
    2: "18",
    3: "25",
    4: "35",
    5: "45",
    6: "",
  };

  const setQuery = useRef(
    debounce(
      (query: Partial<SearchQueryGlobalT>) =>
        setPartialSearchGlobalQuery(query),
      500,
    ),
  ).current;

  const handleInputOnChange = (values: number[]): void => {
    const range: [number, number] = [values[0], values[1]];
    setRange(range);

    setQuery({
      age: {
        left_number: valueLabels[range[0]],
        right_number: valueLabels[range[1]],
      },
    });
  };

  const mapLablesToValues = (labels: [string, string]): [number, number] => {
    const labelValues: Record<string, number> = {
      "13": 1,
      "18": 2,
      "25": 3,
      "35": 4,
      "45": 5,
      "": 6,
    };
    return [labelValues[labels[0]], labelValues[labels[1]]];
  };

  useEffect(() => {
    const { age } = searchGlobalQuery;
    if (age) {
      const range: [string, string] = [
        age.left_number.length > 0 ? age.left_number : valueLabels[1],
        age.right_number.length > 0 ? age.right_number : valueLabels[6],
      ];
      setRange(mapLablesToValues(range));
    }
  }, [searchGlobalQuery]);

  return (
    <SliderWrapper>
      <Slider
        tooltip={{ open: false }}
        range
        marks={marks}
        step={1}
        min={1}
        max={6}
        value={range}
        onChange={(val) => handleInputOnChange(val)}
      />
    </SliderWrapper>
  );
};

export default InfluencerAge;
