import { _o, allowScroll, disableScroll } from "./helpers";

type onKeyPressedT = (e: KeyboardEvent) => void;
const onKeyPressed: onKeyPressedT = (e) => {
  if (e.keyCode === 27) {
    closeModal();
  }
};

const closeModal = (): void => {
  const modals = _o(document.getElementsByClassName("modal"));
  const openedModal = modals.find((modal) => modal.className.includes("open"));
  const remote = openedModal && (openedModal as HTMLDivElement).dataset.remote;

  if (openedModal) {
    const contentElement =
      openedModal.getElementsByClassName("modal-content")[0];

    if (remote) {
      contentElement.innerHTML = "";
    }

    openedModal.classList.remove("open");
    allowScroll(true);
  }
};

type modalHandleT = (
  target: string,
  url?: string,
  e?: MouseEvent,
  params?: string,
) => void;
const modalHandle: modalHandleT = (target, url, e, params) => {
  const modalElement = document.getElementById(target);
  const contentElement =
    modalElement && modalElement.getElementsByClassName("modal-content")[0];

  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  const openModal = (): void => {
    if (modalElement !== null) {
      modalElement.classList.add("open");
      document.onkeydown = (e) => {
        onKeyPressed(e);
      };

      disableScroll(true);
    }
  };

  if (modalElement) {
    if (url && contentElement) {
      $.ajax({
        url: url,
        type: "GET",
        data: params ? params : {},
      }).done((response) => {
        const html = document.createRange().createContextualFragment(response);
        contentElement.append(html);

        openModal();
      });

      return;
    }

    openModal();
  }
};

$(document).on("turbolinks:load", () => {
  const modalTriggerElements = document.getElementsByClassName("modal-trigger");

  if (modalTriggerElements.length > 0) {
    const elements = _o(modalTriggerElements) as HTMLElement[];
    elements.forEach((element) => {
      const closeModalElements = document.getElementsByClassName("modal-close");
      const url = element.dataset.url;
      const target = element.dataset.target;

      if (url && target) {
        element.addEventListener("click", (e) => {
          if (e) {
            const htmlTarget = e.target as HTMLElement;
            const htmlParentNode = htmlTarget.parentElement as HTMLElement;

            const interrupt =
              (htmlTarget &&
                typeof htmlTarget.className.includes !== "undefined" &&
                htmlTarget.className.includes("interrupt")) ||
              (htmlParentNode &&
                typeof htmlParentNode.className.includes !== "undefined" &&
                htmlParentNode.className.includes("interrupt"));

            if (!interrupt) {
              modalHandle(target, url, e);
            }
          }
        });
      }

      if (closeModalElements) {
        _o(closeModalElements).forEach((close) => {
          close.addEventListener("click", () => {
            closeModal();
          });
        });
      }

      document.addEventListener("keydown", (e) => onKeyPressed(e));
    });
  }
});
