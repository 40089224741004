import styled from "@emotion/styled";
import { rem, theme } from "../../../styling/theme";

export const BodyContainer = styled.div`
  display: block;
  min-height: 100vh;
  min-width: 100%;

  ${theme.media.lg} {
    display: flex;
  }
`;

export const Aside = styled.aside`
  background-color: ${theme.color.backgroundColor};
  width: 100%;
  z-index: 2;
  position: relative;

  ${theme.media.lg} {
    flex: 0 0 ${rem(358)};
    max-width: ${rem(358)};
  }
`;

export const Main = styled.main`
  padding: ${rem(16)} ${rem(16)} ${rem(120)};
  position: relative;
  background-color: ${theme.color.whiteColor};

  ${theme.media.lg} {
    flex: auto;
    padding: ${rem(40)} ${rem(74)} ${rem(108)} ${rem(74)};
  }
`;
