import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SubscriptionPriceEstimateT } from "../types";

type PriceEstimateQueryT = {
  country_code?: string;
  vat_number?: string;
};

export const getSubscriptionProposalPriceEstimate = async (
  params: PriceEstimateQueryT,
): Promise<SubscriptionPriceEstimateT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_PROPOSAL_PRICE_ESTIMATE), {
      params,
    })
    .then((res) => res.data.subscription_estimate);
};
