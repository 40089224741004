import * as React from "react";
import { FC, useContext, useState } from "react";
import FlexBox from "../../../../components/FlexBox";
import { useResponsive } from "../../../../hooks/useResponsive";
import TransitionWrapper from "../../../../ui/cards-ui/TransitionWrapper";
import { MediaGroupsHelperContext } from "../../context-providers/MediaGroupsHelperContextProvider";
import { EmptyMediaGroupT, MediaGroupT } from "../../types";
import MediaCard from "./card/MediaCard";
import NewCard from "./card/NewCard";
import { MediaGroupToRemoveStateT } from "./MediaGoupsContainer";
import MediaGroupStorySet from "./MediaGroupStorySet";

const isMediaGroup = (
  value: EmptyMediaGroupT | MediaGroupT,
): value is MediaGroupT => value.pending !== undefined;

type MediaGroupWrapperT = {
  mediaGroup: EmptyMediaGroupT | MediaGroupT;
  mediaGroupToRemove?: MediaGroupToRemoveStateT;
  liveAddedMediaGroup?: boolean;
};

const MediaGroupWrapper: FC<React.PropsWithChildren<MediaGroupWrapperT>> = ({
  mediaGroup,
  mediaGroupToRemove,
  liveAddedMediaGroup,
}) => {
  const [hover, setHover] = useState(false);
  const { tabletVersion } = useResponsive();

  const actionsVisible = tabletVersion || hover;

  const emptyMediaGroup = !mediaGroup.id;
  const pendingMediaGroup = mediaGroup.pending;
  const mediaToRemoveId = mediaGroupToRemove?.mediaGroupId;
  const mediaToRemoveRemoved = mediaGroupToRemove?.removed;

  const shrinkMediaGroup =
    (!emptyMediaGroup &&
      mediaToRemoveRemoved &&
      mediaToRemoveId === mediaGroup.id) ||
    (emptyMediaGroup && !pendingMediaGroup);

  const { cardWidth } = useContext(MediaGroupsHelperContext);

  const getCard = () => {
    if (pendingMediaGroup && isMediaGroup(mediaGroup)) {
      return <NewCard network={mediaGroup.network} />;
    }

    if (emptyMediaGroup) {
      return <div />;
    }

    return (
      <>
        {mediaGroup.kind === "story_set" ? (
          <MediaGroupStorySet
            mediaGroup={mediaGroup}
            mediaGroupToRemove={mediaGroupToRemove}
            liveAddedMediaGroup={liveAddedMediaGroup}
            actionsVisible={actionsVisible}
          />
        ) : (
          <FlexBox position="relative">
            <MediaCard
              media={mediaGroup.medias[0]}
              mediaGroup={mediaGroup}
              liveAddedMedia={liveAddedMediaGroup}
            />
          </FlexBox>
        )}
      </>
    );
  };

  return (
    <TransitionWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      customWidth={shrinkMediaGroup ? "0" : cardWidth}
      alignItems="flex-start"
    >
      {getCard()}
    </TransitionWrapper>
  );
};

export default MediaGroupWrapper;
