import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import AvatarTile, { AvatarSizeE } from "../../../components/AvatarTile";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { rem } from "../../../styling/theme";
import CheckBox from "../../../ui/CheckBox";
import RadioButton from "../../../ui/RadioButton";
import { PartnershipT } from "../types";

const Wrapper = styled(FlexBox)({
  marginBottom: rem(4),
});

type InfluencerTileT = {
  partnership: PartnershipT;
  active: boolean;
  onClickHandle: () => void;
  type?: "checkbox" | "radio";
};

const InfluencerTile: FC<React.PropsWithChildren<InfluencerTileT>> = ({
  active,
  partnership,
  onClickHandle,
  type = "checkbox",
}) => {
  const { avatar_url, handle, network } = partnership;

  return (
    <Wrapper
      customWidth={CustomWidthE.full}
      justifyContent="flex-start"
      onClick={onClickHandle}
      cursor="pointer"
    >
      {type === "checkbox" ? (
        <CheckBox active={active} />
      ) : (
        <RadioButton active={active} />
      )}
      <Spacer />
      <AvatarTile
        imageUrl={avatar_url}
        name={handle}
        network={network}
        avatarSize={AvatarSizeE.small}
        cursor="pointer"
      />
    </Wrapper>
  );
};

export default InfluencerTile;
