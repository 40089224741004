import * as ActionCable from "@rails/actioncable";
import * as React from "react";
import { createContext } from "react";
import { InfluencerNavigationContextProvider } from "./context-providers/InfluencerNavigationContextProvider";
import MainContainer from "./features/MainContainer";
import Navigation from "./features/navigation";

const CableApp = { cable: null };
CableApp.cable = ActionCable.createConsumer();
const ActionCableContext = createContext(null);

export default ({ loginToFb }: { loginToFb?: string }) => (
  <ActionCableContext.Provider value={CableApp.cable}>
    <InfluencerNavigationContextProvider>
      <Navigation />
      <MainContainer loginToFb={loginToFb} />
    </InfluencerNavigationContextProvider>
  </ActionCableContext.Provider>
);
