import * as React from "react";
import { FC, useContext, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { PendingFileT } from "../../../../../hooks/usePendingFile";
import CardCover from "../../../../../ui/cards-ui/CardCover";
import CarouselArrow from "../../../../../ui/carousel/CarouselArrow";
import { ClearCarouselWrapper } from "../../../../../ui/carousel/ClearCarouselWrapper";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { MediaT } from "../../../types";
import ImagesOptions from "../../../ui/ImagesOptions";
import EmptyState from "./EmptyState";
import UploadInProgress from "./UploadInProgress";

export const COVER_OPTIONS = {
  defaltHeight: 300,
  aspectRatio: [1, 1],
};

type CoverImagesT = {
  media: MediaT;
  partnershipId: string;
  wrapperWidth?: number;
  liveAddedMedia?: boolean;
};

const CoverImages: FC<React.PropsWithChildren<CoverImagesT>> = ({
  media,
  partnershipId,
  wrapperWidth,
  liveAddedMedia,
}) => {
  const [pendingFiles, setPendingFiles] = useState<PendingFileT[]>([]);
  const [currentImage, setCurrentImage] = useState<number>(0);

  const { setMediaContentState, queryStack, addedQueryStack } = useContext(
    MediaGroupsHelperContext,
  );

  const { id, media_files, kind } = media;

  const images = media_files.filter((item) => item.kind === "image");

  const setMediaContentStateHandle = (): void => {
    setMediaContentState({
      files: images,
      mediaId: id,
      partnershipId,
      parentCurrentImage: currentImage,
      setParentCurrentImage: setCurrentImage,
      queryStack: liveAddedMedia ? addedQueryStack : queryStack,
    });
  };

  return (
    <FlexBox position="relative" customWidth={CustomWidthE.full}>
      <UploadInProgress
        visible={pendingFiles.length > 0}
        pendingFiles={pendingFiles}
        setPendingFiles={setPendingFiles}
        mediaId={id}
        liveAddedMedia={liveAddedMedia}
      />

      {images.length > 0 ? (
        <ClearCarouselWrapper
          position="relative"
          customWidth={CustomWidthE.full}
        >
          <ImagesOptions
            imagesCount={images.length}
            setPendingFiles={setPendingFiles}
            isStory={kind === "story"}
          />
          <FlexBox cursor="pointer" customWidth={CustomWidthE.full}>
            <Carousel
              showThumbs={false}
              emulateTouch={true}
              selectedItem={currentImage}
              onChange={(index) => setCurrentImage(index)}
              renderArrowPrev={(clickHandler, hasPrev) => (
                <CarouselArrow
                  disable={!hasPrev}
                  visible={images.length > 1}
                  onClickHandle={clickHandler}
                  side="left"
                />
              )}
              renderArrowNext={(clickHandler, hasNext) => (
                <CarouselArrow
                  disable={!hasNext}
                  visible={images.length > 1}
                  onClickHandle={clickHandler}
                  side="right"
                />
              )}
            >
              {images.map((file) => {
                const { thumbnail_url, file_url } = file;

                return (
                  <CardCover
                    key={file.id}
                    image={!!thumbnail_url ? thumbnail_url : file_url}
                    wrapperWidth={wrapperWidth}
                    defaultHeight={COVER_OPTIONS.defaltHeight}
                    aspectRatio={COVER_OPTIONS.aspectRatio}
                    onClick={setMediaContentStateHandle}
                  />
                );
              })}
            </Carousel>
          </FlexBox>
        </ClearCarouselWrapper>
      ) : (
        <EmptyState
          setPendingFiles={setPendingFiles}
          wrapperWidth={wrapperWidth}
          options={COVER_OPTIONS}
          kind={kind}
        />
      )}
    </FlexBox>
  );
};

export default CoverImages;
