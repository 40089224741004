import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import Paragraph from "../components/Paragraph";
import Spacer from "../components/Spacer";
import { rem, theme } from "../styling/theme";
import Icon, { IconTypeE } from "./icons/Icon";

const Wrapper = styled(FlexBox)({
  padding: rem(12),
  backgroundColor: theme.color.greyLightColor,
  borderRadius: rem(4),
  marginBottom: rem(24),
});

const IconWrapper = styled(FlexBox)({
  padding: rem(8),
  backgroundColor: theme.color.yellowColor,
  borderRadius: "50%",
});

type FormNoteT = {
  text: string;
};

const FormNote: FC<React.PropsWithChildren<FormNoteT>> = ({ text }) => {
  return (
    <Wrapper justifyContent="stretch">
      <IconWrapper>
        <Icon icon={IconTypeE.note} size="small" />
      </IconWrapper>
      <Spacer size="small" />
      <FlexBox customWidth={CustomWidthE.full} justifyContent="space-between">
        <Paragraph paragraph={text} paragraphSize="micro" />
      </FlexBox>
    </Wrapper>
  );
};

export default FormNote;
