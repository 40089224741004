import styled from "@emotion/styled";
import { Form, FormProps } from "antd";
import { countries } from "jsvat";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FlexBox from "../../../../components/FlexBox";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { SubscriptionPayloadT } from "../../../types";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { createSubscription } from "../../data-access/createSubscription";
import { generateSubscriptionCheckout } from "../../data-access/generateSubscriptionCheckout";
import { getSubscriptionPlans } from "../../data-access/getSubscriptionPlans";
import { createSubscriptionMutation } from "../../data-access/mutation/createSubscriptionMutation";
import { updateUser } from "../../data-access/updateUser";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import CheckoutForm, { CheckoutFieldTypeT } from "./CheckoutForm";
import { PlanPriceStateT } from "./WizardContainer";

const Wrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "stretch",
});

type CheckoutT = {
  planPriceState: PlanPriceStateT;
  setPlanPriceState: (planPriceState: PlanPriceStateT | undefined) => void;
};

const Checkout: FC<React.PropsWithChildren<CheckoutT>> = ({
  planPriceState,
  setPlanPriceState,
}) => {
  const navigate = useRouterNavigate();

  const { openToast } = useContext(ToastContext);
  const { session } = useContext(ApplicationContext);
  const { chargebee, setSubscription } = useContext(SubscriptionContext);

  const address = session?.brand?.billing_address;
  const subscription = session?.subscription || undefined;
  const payment_type = session?.payment_method || "card";

  const { t } = useTranslate("brands.wizard.checkout");

  useQuery(QueryKeys.SUBSCRIPTIONS_PLANS, () => getSubscriptionPlans());

  const queryClient = useQueryClient();
  const { mutate: storeInvoiceDetails, isLoading: isLoadingUpdate } =
    useMutation(
      (params: CheckoutFieldTypeT) =>
        updateUser(session?.nick_or_name || "", params.company, {
          firm_name: params.company,
          street: params.address,
          city: params.city,
          zip_code: params.zip,
          country_code: countries.find((c) => c.name === params.country)
            ?.codes[0] as string,
          firm_dic: params.vat,
        }),
      {
        onSuccess: () => {
          if (payment_type === "card") {
            openChargebeeCheckout(planPriceState.price.id);
          } else {
            createInvoiceSubscription();
          }
        },
        onError: () => {
          openToast({
            type: "error",
            message: t("messages.error"),
          });
        },
      },
    );

  const toastAndRedirect = (): void => {
    setTimeout(() => {
      navigate(NavigationKeyE.Database);
    }, 1000);
    openToast({
      type: "success",
      message: t(`messages.success_${payment_type}`, {
        link: NavigationKeyE.Subscription,
      }),
      button: {
        text: t("messages.button"),
        onClick: () => navigate(NavigationKeyE.Subscription),
      },
    });
  };

  const { mutate: saveSubscription, isLoading: isLoadingSave } = useMutation(
    (payload: SubscriptionPayloadT) => createSubscription(payload),
    {
      onSuccess: (data) => {
        createSubscriptionMutation(queryClient, data, () => toastAndRedirect());
      },
      onError: () => {
        openToast({
          type: "error",
          message: t("messages.error"),
        });
      },
    },
  );

  const { mutate: createInvoiceSubscription, isLoading: isLoadingInvoice } =
    useMutation(
      () => createSubscription({ price_id: planPriceState.price.id }),
      {
        onSuccess: (data) => {
          createSubscriptionMutation(queryClient, data, () =>
            toastAndRedirect(),
          );
        },
        onError: () => {
          openToast({
            type: "error",
            message: t("messages.error"),
          });
        },
      },
    );

  const openChargebeeCheckout = (priceId: string): void => {
    chargebee.openCheckout({
      layout: "in_app",
      hostedPage: () => generateSubscriptionCheckout(priceId),
      error: () => {
        openToast({
          type: "error",
          message: t("messages.error"),
        });
      },
      success: (hostedPageId: string) => {
        saveSubscription({ hosted_page_id: hostedPageId });
        setTimeout(() => {
          chargebee.closeAll();
        }, 1000);
      },
    });
  };

  const onFinish: FormProps<CheckoutFieldTypeT>["onFinish"] = (values) => {
    storeInvoiceDetails(values);
  };

  const country = address?.country_code
    ? countries.find((c) => c.codes[0] === address?.country_code)?.name
    : "";

  useEffect(() => {
    setSubscription(subscription);
  }, [subscription]);

  return (
    <Wrapper>
      <Form
        name="checkout"
        layout="vertical"
        labelCol={{ span: 8 }}
        style={{ width: "100%", height: "100%", display: "flex" }}
        disabled={isLoadingUpdate || isLoadingSave || isLoadingInvoice}
        initialValues={{
          company: session?.brand?.company_name,
          address: address?.street,
          city: address?.city,
          zip: address?.zip_code,
          country,
          vat: address?.firm_dic,
        }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <CheckoutForm
          planPriceState={planPriceState}
          setPlanPriceState={setPlanPriceState}
          payment_type={payment_type}
          initialCountry={country}
        />
      </Form>
    </Wrapper>
  );
};

export default Checkout;
