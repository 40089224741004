import { Form, FormInstance, Input } from "antd";
import * as React from "react";
import { FC } from "react";
import Select from "../../../../features/Select";
import { useTranslate } from "../../../../hooks/useTranslate";
import { NetworkProfileT } from "../../../types";

export type OverlapFieldTypeT = {
  title: string;
  handles: string[];
};

type CreateFormT = {
  form: FormInstance;
  onFinishHandle: () => void;
  suggestions: NetworkProfileT[];
  onTypeChangeHandle: (value: string) => void;
};

const CreateForm: FC<React.PropsWithChildren<CreateFormT>> = ({
  form,
  onFinishHandle,
  suggestions,
  onTypeChangeHandle,
}) => {
  const { t } = useTranslate("brands.overlap_analyses.index");

  return (
    <Form
      form={form}
      onFinish={onFinishHandle}
      name="newOverlap"
      layout="vertical"
      style={{ width: "100%" }}
      requiredMark={false}
      validateTrigger={["onSubmit"]}
    >
      <Form.Item<OverlapFieldTypeT>
        name="title"
        label={t("modal.title")}
        rules={[{ required: true }]}
      >
        <Input placeholder={t("modal.title_placeholder")} />
      </Form.Item>
      <Form.Item<OverlapFieldTypeT>
        label={t("modal.handles")}
        style={{ width: "100%" }}
        name="handles"
        rules={[
          () => ({
            validator: (_rule, value) =>
              value?.length > 1 && value?.length < 31
                ? Promise.resolve()
                : Promise.reject(t("errors.limit")),
          }),
        ]}
      >
        <Select
          mode="tags"
          placeholder={t("modal.handles_placeholder")}
          onSearch={onTypeChangeHandle}
          addonBefore="@"
          tokenSeparators={[","]}
          options={
            suggestions?.map((s) => ({
              label: s.profile_id,
              value: s.profile_id,
            })) || []
          }
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
