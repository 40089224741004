import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Segmented, SegmentedProps } from "antd";
import * as React from "react";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import FlexBox from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { NavigationKeyE, useRouterNavigate } from "../../../router";
import { useCampaign } from "../CampaignDetailContainer";
import Cards from "./Cards";
import Table from "./Table";

const SegmentedWrapper = styled(FlexBox)({
  width: rem(210),
  marginTop: rem(12),
});

const Outputs: FC<React.PropsWithChildren<unknown>> = () => {
  const { campaign } = useCampaign();

  const navigate = useRouterNavigate();
  const { t } = useTranslate("brands.campaign.outputs");

  const options: SegmentedProps["options"] = [
    {
      value: "",
      label: t("cards"),
      icon: <AppstoreOutlined />,
    },
    {
      value: "table",
      label: t("table.title"),
      icon: <BarsOutlined />,
    },
  ];
  const regex = new RegExp(
    `${NavigationKeyE.Campaign}/${campaign.id}/media_groups/(table)`,
  );
  const match = window.location.pathname.match(regex);
  const activeTab = match ? match[1] : "";

  return (
    <>
      <SegmentedWrapper>
        <Segmented
          style={{ width: "100%" }}
          options={options}
          onChange={(key) =>
            navigate(
              NavigationKeyE.Campaign,
              `${campaign.id}/media_groups/${key}`,
            )
          }
          value={activeTab}
        />
      </SegmentedWrapper>
      <Routes>
        <Route path="" element={<Cards campaign={campaign} />} />
        <Route path="table" element={<Table campaign={campaign} />} />
      </Routes>
    </>
  );
};

export default Outputs;
