import styled from "@emotion/styled";
import { Table as AntTable, Button, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib";
import { formatDate } from "helpers/formaters";
import * as React from "react";
import { FC, useState } from "react";
import emptyImg from "../../../../assets/competitiveAnalysisEmpty.png";
import BlueLink from "../../../../components/BlueLink";
import EmptyIntroducingContainer from "../../../../components/EmptyIntroductingContainer";
import FlexBox from "../../../../components/FlexBox";
import Loader from "../../../../components/Loader";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CompetitiveAnalysisT } from "../../types";
import CreateForm from "./CreateForm";

const StyledPanel = styled(Panel)<{ fullHeight: boolean }>(
  ({ fullHeight }) => ({
    width: "100%",
    overflow: "hidden",
    minHeight: fullHeight ? `calc(100dvh - ${rem(96)})` : "auto",
    alignItems: "stretch",
  }),
);

const DetailsWrapper = styled(FlexBox)<{
  open: boolean;
  marginLeft?: number;
}>(({ open, marginLeft }) => ({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: open ? rem(224) : 0,
  transition: "height 0.5s",
  overflow: "hidden",
  marginLeft: marginLeft && rem(marginLeft),
  gap: rem(24),
}));

interface DataType {
  key: string;
  analysis: CompetitiveAnalysisT;
}

type TableT = {
  dataIsLoading: boolean;
  competitiveAnalyses?: CompetitiveAnalysisT[];
  form: FormInstance;
  onFinishHandle: () => void;
  loading: boolean;
  onSubmitHandle: () => void;
};

const Table: FC<React.PropsWithChildren<TableT>> = ({
  dataIsLoading,
  competitiveAnalyses,
  form,
  onFinishHandle,
  loading,
  onSubmitHandle,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const navigate = useRouterNavigate();

  const { t, i18n } = useTranslate("brands.competitive_analyses.index");

  const showStatus = (competitiveAnalysis: CompetitiveAnalysisT) => {
    switch (competitiveAnalysis.status) {
      case "no_data":
        return (
          <>
            <FlexBox justifyContent="flex-start">
              <Paragraph paragraph={t("no_data")} />
              <Spacer size="mini" />
              <Tooltip title={t("no_data_info")}>
                <FlexBox>
                  <Icon
                    icon={IconTypeE.infoCircle}
                    color={theme.color.textGreyColor}
                    size="small"
                    zIndex={"auto"}
                  />
                </FlexBox>
              </Tooltip>
            </FlexBox>
          </>
        );
      case "pending":
        return <Paragraph paragraph={t("pending")} />;
      case "finished":
        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                NavigationKeyE.CompetitiveAnalyses,
                `${competitiveAnalysis.id}/influencers`,
              )
            }
          >
            {t("show")}
          </Button>
        );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.handle"),
      dataIndex: "title",
      render: (_, record) => record.analysis.handle,
      sorter: (a, b) => a.analysis.handle.localeCompare(b.analysis.handle),
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) => formatDate(record.analysis.created_at, i18n),
      sorter: (a, b) =>
        a.analysis.created_at.localeCompare(b.analysis.created_at),
    },
    {
      title: t("table.results"),
      dataIndex: "handles",
      render: (_, record) => record.analysis.results.length,
    },
    {
      title: undefined,
      dataIndex: "action",
      width: "20%",
      render: (_, record) => showStatus(record.analysis),
    },
  ];

  const getTableContent = () => {
    if (competitiveAnalyses && competitiveAnalyses.length > 0) {
      const data: DataType[] = competitiveAnalyses.map((analysis) => ({
        key: analysis.id,
        analysis: analysis,
      }));

      return (
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    if (dataIsLoading) {
      return <Loader />;
    }

    return (
      <EmptyIntroducingContainer imageSrc={emptyImg}>
        <FlexBox flexDirection="column" gap={rem(24)} alignItems="flex-start">
          <Paragraph paragraph={t("empty.title")} fontWeight={600} />
          <Paragraph paragraph={t("empty.subtitle")} />
        </FlexBox>
        <BlueLink
          text={showDetails ? t("empty.show_less") : t("empty.show_more")}
          onClick={() => setShowDetails((prev) => !prev)}
          paragraphSize="small"
          icon={IconTypeE.arrowBottom}
          rotate={showDetails ? "180deg" : "0deg"}
          iconPosition="after"
          iconColor={designToken.colorPrimary}
        />
        <DetailsWrapper open={showDetails}>
          <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(8)}>
            <Paragraph
              paragraph={t("empty.maximum.title")}
              fontWeight={600}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={t("empty.maximum.description")}
              color={designToken.colorTextSecondary}
              paragraphSize="small"
            />
          </FlexBox>
          <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(8)}>
            <Paragraph
              paragraph={t("empty.unique.title")}
              fontWeight={600}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={t("empty.unique.description")}
              color={designToken.colorTextSecondary}
              paragraphSize="small"
            />
          </FlexBox>
        </DetailsWrapper>
        <Paragraph paragraph={t("empty.add_new")} fontWeight={600} />
        <CreateForm form={form} onFinishHandle={onFinishHandle} />
        <Button onClick={onSubmitHandle} type="primary" loading={loading}>
          {t("modal.create")}
        </Button>
      </EmptyIntroducingContainer>
    );
  };

  return (
    <StyledPanel fullHeight={competitiveAnalyses?.length === 0}>
      {getTableContent()}
    </StyledPanel>
  );
};

export default Table;
