import styled from "@emotion/styled";
import { rem, theme } from "../../../styling/theme";

export const Heading = styled.h1`
  color: ${theme.color.darkPrimaryColor};
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(20)};

  ${theme.media.lg} {
    font-weight: 600;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  }
`;
