import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import BlueLink from "../../../../../../components/BlueLink";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../../ui/icons/Icon";
import {
  ContactRelatedAccountT,
  ContactT,
  InfluencerProfileT,
  SocialNetworkProviderT,
} from "../../types";
import NetworkAccount from "./NetworkAccount";

const Wrapper = styled(FlexBox)({
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  gap: rem(4),
});

const NetworkWrapper = styled(FlexBox)({
  width: "100%",
  paddingLeft: rem(8),
  borderRadius: rem(4),

  "&:hover": {
    backgroundColor: theme.color.backgroundColor,
  },
});

const NetworkInnerWrapper = styled(FlexBox)({
  width: "30%",

  [theme.media.maxSm]: {
    width: "50%",
  },
});

type NetworksT = {
  contact: ContactT;
  networkProfile: InfluencerProfileT;
};

const Networks: FC<React.PropsWithChildren<NetworksT>> = ({
  contact,
  networkProfile,
}) => {
  const [editVisible, setEditVisible] = useState<SocialNetworkProviderT>();
  const networks: SocialNetworkProviderT[] = ["instagram", "tiktok", "youtube"];

  const { t } = useTranslate("brands.influencer_database.crm.networks");

  const networkIcon = (network: SocialNetworkProviderT) => {
    switch (network) {
      case "instagram":
        return <Icon icon={IconTypeE.socInstagram} size="big" />;
      case "tiktok":
        return <Icon icon={IconTypeE.socTiktok} size="big" />;
      case "youtube":
        return <Icon icon={IconTypeE.socYoutube} size="big" />;
      case "facebook":
        return <Icon icon={IconTypeE.socFacebook} size="big" />;
    }
  };

  const networkUrl = (
    account: ContactRelatedAccountT | undefined,
  ): string | undefined => {
    if (!account) return undefined;

    switch (account.network) {
      case "instagram":
        return `https://instagram.com/${account.profile_id}`;
      case "tiktok":
        return `https://www.tiktok.com/@${account.profile_id}`;
      case "youtube":
        return `https://www.youtube.com/@${account.profile_id}`;
      case "facebook":
        return `https://www.facebook.com/${account.profile_id}`;
    }
  };

  return (
    <Wrapper>
      {networks.map((network) => {
        const account = contact.related_accounts.find(
          (acc) => acc.network === network,
        );

        return (
          <NetworkWrapper
            key={network}
            onMouseEnter={() => setEditVisible(network)}
            onMouseLeave={() => setEditVisible(undefined)}
          >
            <NetworkInnerWrapper justifyContent="flex-start" gap={rem(12)}>
              {networkIcon(network)}
              {account ? (
                <BlueLink
                  text={t(network)}
                  type="black"
                  icon={IconTypeE.linkExternal}
                  iconPosition="after"
                  iconSize="small"
                  href={networkUrl(account)}
                  target="_blank"
                />
              ) : (
                <Paragraph paragraph={t(network)} paragraphSize="small" />
              )}
            </NetworkInnerWrapper>
            <FlexBox justifyContent="flex-start" customWidth="70%">
              <NetworkAccount
                contact={contact}
                account={account}
                networkProfile={networkProfile}
                network={network}
                editVisible={editVisible === network}
              />
            </FlexBox>
          </NetworkWrapper>
        );
      })}
    </Wrapper>
  );
};

export default Networks;
