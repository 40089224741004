import * as React from "react";
import { FC } from "react";

interface HtmlTextT {
  value: string;
}

const HtmlText: FC<React.PropsWithChildren<HtmlTextT>> = ({ value }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  );
};

export default HtmlText;
