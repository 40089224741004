import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UserForUserManagementT } from "entry-files/company-root/types";
import { usersKeys } from "../queryKeys";

type UsersDataT = { users: Array<UserForUserManagementT> };

export function useGetUsers() {
  return useQuery<UsersDataT>({
    queryKey: usersKeys.all,
    queryFn: async () => {
      const response = await axios.get("/api/v1/brands/users");

      return response.data;
    },
  });
}
