import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { formatDate } from "helpers/formaters";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { InfluencerReportT } from "../../../../types";
import { AnalyzeContext } from "../../influencer-database/context-providers/AnalyzeContextProvider";
import { InfluencerProfileT } from "../types";

const InfluencerReportWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  margin: `${rem(24)} 0 ${rem(8)} ${rem(16)}`,
  gap: rem(6),
});

const OudatedTag = styled(FlexBox)({
  backgroundColor: designToken["orange-1"],
  borderRadius: rem(16),
  padding: `${rem(8)} ${rem(12)}`,
  gap: rem(8),
});

type InfluencerReportInfoT = {
  networkProfile: InfluencerProfileT;
  influencerReport: InfluencerReportT;
};

const InfluencerReportInfo: FC<
  React.PropsWithChildren<InfluencerReportInfoT>
> = ({ networkProfile, influencerReport }) => {
  const { profile_id, provider, availability_reason } = networkProfile;

  const { analyze, analyzeButton } = useContext(AnalyzeContext);
  const { t, i18n } = useTranslate(
    "brands.influencer_database.detail.influencer_reports",
  );

  const onClickHandler = (): void => {
    analyze({
      handle: profile_id,
      platform: provider,
      afterSuccess: () => {
        window.location.reload();
      },
    });
  };

  return (
    <InfluencerReportWrapper>
      <FlexBox gap={rem(16)}>
        {availability_reason == "report_outdated" && (
          <OudatedTag>
            <InfoCircleOutlined style={{ color: designToken["orange-6"] }} />
            <Paragraph
              paragraph={t("outdated_data")}
              paragraphSize="small"
              color={designToken["orange-6"]}
            />
          </OudatedTag>
        )}
        <Paragraph
          paragraph={t("last_updated", {
            date: formatDate(influencerReport.created_at, i18n),
          })}
        />
      </FlexBox>
      {analyzeButton(
        networkProfile.profile_id,
        undefined,
        onClickHandler,
        "update",
      )}
    </InfluencerReportWrapper>
  );
};

export default InfluencerReportInfo;
