import * as React from "react";
import { FC } from "react";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { theme } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableHeadWrapper from "../../../../../ui/table/TableHeadWrapper";

const TableHead: FC<React.PropsWithChildren<{}>> = () => {
  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity",
  );

  return (
    <TableHeadWrapper>
      <TableColumn>
        <Paragraph
          paragraph={t("brand")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="20%">
        <Paragraph
          paragraph={t("last_mention")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn>
        <Paragraph
          paragraph={t("mention_count")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
    </TableHeadWrapper>
  );
};

export default TableHead;
