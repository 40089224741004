import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { InfluencerNavigationDetailStepT } from "../../../types";
import {
  InfluencerNavigationContext,
  isInfluencerNavigationDetailItem,
} from "../../context-providers/InfluencerNavigationContextProvider";
import { getApprovalRequestData } from "../../data-access/getApprovalRequestData";
import Modal from "../../ui/Modal";
import NavigationMenu from "../../ui/NavigationMenu";
import { Aside, BodyContainer, Main } from "../../ui/PageElements";
import MissmatchModal from "./MissmatchModal";
import NotFoundMessage from "./NotFoundMessage";
import ProcessedApproval from "./ProcessedApproval";
import Approval from "./steps/Approval";
import Security from "./steps/Security";
import Tutorials from "./steps/Tutorials";
import Welcome from "./steps/Welcome";

const Iframe = styled.iframe`
  border: none;
  border-radius: ${rem(6)};
  margin: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`;

type ApprovalRequestContainerT = {
  approvalRequestId: string;
  loginToFb?: string;
};

export type ModalAttributesT = {
  title: string;
  iframeSrc: string;
};

const ApprovalRequestContainer: FC<
  React.PropsWithChildren<ApprovalRequestContainerT>
> = ({ approvalRequestId, loginToFb }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAttributes, setModalAttributes] = useState<
    ModalAttributesT | undefined
  >();
  const { session } = useContext(ApplicationContext);
  const { navigationState } = useContext(InfluencerNavigationContext);
  const navigationDetailState =
    navigationState && isInfluencerNavigationDetailItem(navigationState)
      ? navigationState
      : undefined;

  const { data, isLoading } = useQuery(QueryKeys.APPROVAL_REQUEST, () =>
    getApprovalRequestData(approvalRequestId),
  );

  const loggedIn = session?.id !== null;
  const { t } = useTranslate("influencer_root.approval_request");

  useEffect(() => {
    if (modalAttributes) {
      setOpenModal(true);
    }
  }, [modalAttributes]);

  const getStep = (step: InfluencerNavigationDetailStepT) => {
    if (data) {
      const isBrand = session && session.role === "brand";
      const profileMissmatch =
        loggedIn &&
        session &&
        !isBrand &&
        session.network_profiles[0].profile_id !==
          data.network_profile.profile_id;

      if (profileMissmatch) {
        return (
          <>
            <MissmatchModal
              approvalRequestId={approvalRequestId}
              currentHandle={session?.network_profiles[0].profile_id}
              requestedHandle={data.network_profile.profile_id}
            />
            <Welcome approvalRequest={data} />
          </>
        );
      } else if (data.status === "processed" && step !== "email_missing") {
        const {
          network_profile: { profile_id },
        } = data;

        return (
          <ProcessedApproval
            approvalRequest={data}
            influencerHandle={profile_id}
            url={`${loginToFb}`}
            content={
              loggedIn
                ? t("success_description", {
                    mentions: data.mentions,
                    email: data.author.email,
                  })
                : t("already_approved")
            }
            button_title={loggedIn ? t("approval.all_approvals") : t("login")}
          />
        );
      } else {
        switch (step) {
          case "welcome":
            return <Welcome approvalRequest={data} />;
          case "security":
            return <Security />;
          case "tutorials":
            return <Tutorials setModalAttributes={setModalAttributes} />;
          case "approval":
          case "handle_mismatch":
          case "success":
          case "email_missing":
          case "no_account_available":
          case "scopes_missing":
          case "user_cancelled":
            return (
              <Approval
                approvalRequest={data}
                setModalAttributes={setModalAttributes}
                step={step !== "approval" ? step : undefined}
              />
            );
        }
      }
    }

    return null;
  };

  const getContent = (step: InfluencerNavigationDetailStepT) => {
    if (isLoading && !data) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.approval_header}
          count={1}
          direction="row"
          justifyContent="flex-start"
        />
      );
    }

    if (!isLoading && !data) {
      return <NotFoundMessage />;
    }

    return getStep(step);
  };

  return (
    <>
      <div id="portal"></div>
      {navigationDetailState && (
        <BodyContainer>
          <Aside>
            <NavigationMenu
              approvalProcessed={data && data.status === "processed"}
            />
          </Aside>
          <Main>{getContent(navigationDetailState.step)}</Main>
          {modalAttributes && (
            <Modal
              isOpen={openModal}
              header={modalAttributes.title}
              onClose={() => setOpenModal(false)}
            >
              <Iframe
                src={modalAttributes.iframeSrc}
                allow="accelerometer; autoplay; allowfullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></Iframe>
            </Modal>
          )}
        </BodyContainer>
      )}
    </>
  );
};

export default ApprovalRequestContainer;
