import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import LoaderComponent from "../../../../../components/Loader";
import Modal from "../../../../../components/Modal";
import { ApplicationContext } from "../../../../../context-providers/ApplicationContextProvider";
import { getUrlWithQuery } from "../../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../../helpers/routes";
import { useTranslate } from "../../../../../hooks/useTranslate";
import {
  ApprovalRequestWithAuthorT,
  InfluencerNavigationDetailCallbackStepT,
} from "../../../../types";
import { InfluencerNavigationContext } from "../../../context-providers/InfluencerNavigationContextProvider";
import { getApprovalRequestAuthorization } from "../../../data-access/getApprovalRequestAuthorization";
import { ApprovalPageStateT } from "../../../types";
import { Heading } from "../../../ui/Header";
import { ModalAttributesT } from "../ApprovalRequestContainer";
import ApprovedStatement from "../ApprovedStatement";
import ErrorMessage from "../ErrorMessage";
import FacebookApproval from "../FacebookApproval";
import UpdateEmailModal from "../UpdateEmailModal";

type ApprovalT = {
  approvalRequest: ApprovalRequestWithAuthorT;
  setModalAttributes: Dispatch<SetStateAction<ModalAttributesT | undefined>>;
  step?: InfluencerNavigationDetailCallbackStepT;
};

const Approval: FC<React.PropsWithChildren<ApprovalT>> = ({
  approvalRequest,
  setModalAttributes,
  step,
}) => {
  const {
    id: approvalRequestId,
    author: { email, nick_or_name },
    network_profile: { profile_id: influencerHandle },
    company_name,
    mentions,
    status,
  } = approvalRequest;

  const mentionAsString =
    mentions.length > 0 ? `@${mentions.join(", @")}` : "-";

  const { session } = useContext(ApplicationContext);
  const { setNavigationState } = useContext(InfluencerNavigationContext);

  const [approvalPageState, setApprovalPageState] =
    useState<ApprovalPageStateT>("loading");
  const [urlForOath, setUrlForOath] = useState<string>();
  const [emailFromFacebook, setEmailFromFacebook] = useState("");
  const [tacCheckBoxResetHelper, setTacCheckBoxResetHelper] = useState(false);

  const isAlreadyApproved = status === "processed";
  const globalDisable = approvalPageState === "session_mismatch";

  const { t } = useTranslate("influencer_root.approval_request.approval");

  const { isLoading } = useQuery(
    QueryKeys.AUTHORIZATION,
    () =>
      getApprovalRequestAuthorization(
        getUrlWithQuery(
          getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_AUTHORIZATION_NEW),
          {
            approval_request_id: approvalRequestId,
            approved_mentions: mentions,
          },
        ),
      ),
    {
      onSuccess: (data) => {
        if (!step && data) {
          const { uri, reason } = data;
          const sessionInfluencerHandle = getSessionInfluencerHandle();

          if (uri) {
            setUrlForOath(uri);
          }

          if (
            sessionInfluencerHandle &&
            sessionInfluencerHandle !== influencerHandle
          ) {
            setApprovalPageState("session_mismatch");
            return;
          }

          if (reason) {
            setApprovalPageState(reason);
            return;
          }

          setApprovalPageState("not_approved");
        }
      },
    },
  );

  const getSessionInfluencerHandle = (): string | undefined => {
    const networkProfiles = session?.network_profiles || [];
    const networkHandle =
      networkProfiles.length > 0 &&
      networkProfiles.find(
        (network) =>
          // @ts-ignore
          (network.provider === "fb_instagram" ||
            network.provider === "instagram") &&
          network.profile_id,
      )?.profile_id;

    if (networkHandle) {
      return networkHandle;
    }

    return undefined;
  };

  useEffect(() => {
    if (isLoading) {
      setApprovalPageState("loading");
    }
  }, [isLoading]);

  useEffect(() => {
    if (approvalPageState === "email_updated") {
      setNavigationState({
        key: "approval_requests",
        id: approvalRequest.id,
        step: "success",
      });
      return;
    }
  }, [approvalPageState]);

  useEffect(() => {
    if (step) {
      if (step === "success") {
        setApprovalPageState("already_connected");
        return;
      }

      if (
        [
          "scopes_missing",
          "handle_mismatch",
          "user_cancelled",
          "email_missing",
          "no_account_available",
        ].includes(step)
      ) {
        const urlParams = new URLSearchParams(window.location.search);
        const unconfirmed_email = urlParams.get("unconfirmed_email");
        if (unconfirmed_email) {
          setEmailFromFacebook(unconfirmed_email);
        }

        setTacCheckBoxResetHelper((prevState) => !prevState);
        setApprovalPageState(step);
        return;
      }
    }
  }, [step]);

  const getContent = () => {
    if (approvalPageState === "loading") {
      return <LoaderComponent />;
    }

    if (
      (isAlreadyApproved &&
        ["already_connected", "token_expired"].includes(approvalPageState)) ||
      ["email_rejected", "email_missing"].includes(approvalPageState)
    ) {
      return (
        <ApprovedStatement
          mentions={mentionAsString}
          email={email || nick_or_name}
        />
      );
    }

    return (
      <FacebookApproval
        approvalRequestId={approvalRequestId}
        url={urlForOath}
        mentions={mentions}
        handle={influencerHandle}
        disableAllow={globalDisable}
        email={email || nick_or_name}
        company_name={company_name}
        resetTacCheckBox={tacCheckBoxResetHelper}
        setModalAttributes={setModalAttributes}
      />
    );
  };

  return (
    <>
      <Heading>{t("title")}</Heading>
      <ErrorMessage
        approvalRequestId={approvalRequestId}
        approvalPageState={approvalPageState}
        currentHandle={getSessionInfluencerHandle() || ""}
        influencerHandle={influencerHandle}
      />
      {getContent()}

      <Modal
        visible={approvalPageState === "email_missing"}
        close={() => setApprovalPageState("email_rejected")}
        slim={true}
        renderChildren={() => {
          return (
            <UpdateEmailModal
              approvalRequestId={approvalRequestId}
              initialEmail={emailFromFacebook}
              setApprovalPageState={setApprovalPageState}
            />
          );
        }}
      />
    </>
  );
};

export default Approval;
