import { Radio, Space } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { IconTypeE } from "../../../../../../ui/icons/Icon";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";
import RadioItem from "../../components/RadionItem";
import { CredibilityClassE, CredibilityClassT } from "../../types";

const Credibility: FC<React.PropsWithChildren<unknown>> = () => {
  const credibilityClasses: Record<
    CredibilityClassT | "any",
    CredibilityClassT[]
  > = {
    best: ["best"],
    high: ["high", "best"],
    normal: ["normal", "high", "best"],
    low: ["low", "normal", "high", "best"],
    any: [],
  };

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.credibility");

  const handleRadioOnChange = (value: CredibilityClassT): void => {
    setPartialSearchGlobalQuery({
      audience_credibility_class: credibilityClasses[value],
    });
  };

  return (
    <FilterContainer closable={["audience_credibility_class"]} t={t}>
      <Radio.Group
        name="credibility"
        value={searchGlobalQuery.audience_credibility_class[0] ?? "any"}
        defaultValue={undefined}
        onChange={(e) => handleRadioOnChange(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value="any">{t("none")}</Radio>
          <RadioItem
            value="best"
            paragraph={t("excellent")}
            description={t("more", { value: CredibilityClassE.best })}
            icon={IconTypeE.smileVery}
          />
          <RadioItem
            value="high"
            paragraph={t("high")}
            description={t("more", { value: CredibilityClassE.high })}
            icon={IconTypeE.smileSlightly}
          />
          <RadioItem
            value="normal"
            paragraph={t("normal")}
            description={t("more", { value: CredibilityClassE.normal })}
            icon={IconTypeE.smileNeutral}
          />
          <RadioItem
            value="low"
            paragraph={t("average")}
            description={t("more", { value: CredibilityClassE.low })}
            icon={IconTypeE.smileSad}
          />
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default Credibility;
