import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { designToken } from "../../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import { StyledDivider } from "../../components/FilterContainer";

const AnalyzdeProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const { t } = useTranslate(
    "brands.influencer_database.filter.recently_analyzed_profiles",
  );

  const onChangeHandle = (e: CheckboxChangeEvent): void => {
    setPartialSearchGlobalQuery({
      recently_analyzed_profiles: e.target.checked,
    });
  };

  return (
    <>
      <FlexBox>
        <Checkbox
          checked={searchGlobalQuery.recently_analyzed_profiles}
          onChange={onChangeHandle}
        >
          {t("title")}
        </Checkbox>
        <Tooltip title={t("tooltip")} placement="top">
          <QuestionCircleOutlined
            style={{
              fontSize: rem(14),
              color: designToken.colorTextTertiary,
            }}
          />
        </Tooltip>
      </FlexBox>
      <StyledDivider dashed />
    </>
  );
};

export default AnalyzdeProfiles;
