import { InfoCircleOutlined } from "@ant-design/icons";
import { Table as AntTable, Button, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import AvatarTile from "../../../../components/AvatarTile";
import FlexBox from "../../../../components/FlexBox";
import { putSpacesToThousand } from "../../../../helpers/formaters";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { getCompetitiveAnalysisOutputs } from "../../data-access/getCompetitiveAnalysisOutputs";
import { CompetitiveAnalysisResultT, ExistedOutputT } from "../../types";

interface DataType {
  key: string;
  result: CompetitiveAnalysisResultT;
}

type TableT = {
  competitiveAnalysisId: string;
  results: CompetitiveAnalysisResultT[];
  setOpenModal: Dispatch<SetStateAction<ExistedOutputT[] | null>>;
};

const Table: FC<React.PropsWithChildren<TableT>> = ({
  competitiveAnalysisId,
  results,
  setOpenModal,
}) => {
  const { t } = useTranslate("brands.competitive_analyses.show.table");

  const onClickHandle = (handle: string): void => {
    getCompetitiveAnalysisOutputs(competitiveAnalysisId, 100, handle).then(
      (data) => data && setOpenModal(data),
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("handle"),
      dataIndex: "handle",
      render: (_, record) => (
        <FlexBox justifyContent="flex-start">
          <AvatarTile
            handle={record.result.handle}
            name={record.result.full_name}
            imageUrl={record.result.avatar_url}
            externalLink={`https://instagram.com/${record.result.handle}`}
            paragraphSize="small"
          />
        </FlexBox>
      ),
      sorter: (a, b) => a.result.handle.localeCompare(b.result.handle),
    },
    {
      title: (
        <FlexBox gap={rem(4)} cursor="pointer">
          {t("avg_post_likes")}
          <Tooltip title={t("popovers.on_day_of_creation")} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        </FlexBox>
      ),
      dataIndex: "avg_post_likes",
      render: (_, record) =>
        putSpacesToThousand(Math.round(record.result.avg_post_likes)),
      sorter: (a, b) => a.result.avg_post_likes - b.result.avg_post_likes,
    },
    {
      title: (
        <FlexBox gap={rem(4)} cursor="pointer">
          {t("avg_post_comments")}
          <Tooltip title={t("popovers.on_day_of_creation")} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        </FlexBox>
      ),
      dataIndex: "avg_post_comments",
      render: (_, record) =>
        putSpacesToThousand(Math.round(record.result.avg_post_comments)),
      sorter: (a, b) => a.result.avg_post_comments - b.result.avg_post_comments,
    },
    {
      title: t("posts_count"),
      dataIndex: "posts_count",
      render: (_, record) => record.result.posts_count,
      sorter: (a, b) => a.result.posts_count - b.result.posts_count,
    },
    {
      title: (
        <FlexBox gap={rem(4)} cursor="pointer">
          {t("stories_count")}
          <Tooltip title={t("popovers.stories_info")} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        </FlexBox>
      ),
      dataIndex: "stories_count",
      render: (_, record) => record.result.stories_count,
      sorter: (a, b) => a.result.stories_count - b.result.stories_count,
    },
    {
      title: undefined,
      dataIndex: "actions",
      render: (_, record) => (
        <Button onClick={() => onClickHandle(record.result.handle)}>
          {t("view")}
        </Button>
      ),
    },
  ];
  const data: DataType[] = results.map((result) => ({
    key: result.id,
    result: result,
  }));

  return (
    <AntTable
      style={{ width: "100%" }}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default Table;
