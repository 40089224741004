const urlPrefix =
  "https://reportee-production.s3.eu-central-1.amazonaws.com/public/example/";

export const getRandomElements = (arr: string[], count: number): string[] => {
  return arr.sort(() => Math.random() - 0.5).slice(0, count);
};

const keys = Array.from({ length: 15 }, (_, i) => (i + 1).toString());

export const demoImageUrls = (): string[] => {
  return keys.map((key) => `${urlPrefix}${key}.jpeg`);
};
