import styled from "@emotion/styled";
import { Select, Slider } from "antd";
import { SliderMarks } from "antd/es/slider";
import { debounce } from "lodash";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import {
  SearchQueryGlobalAudienceAgeRangeT,
  SearchQueryGlobalT,
} from "../../types";

const SliderWrapper = styled.div({
  width: "100%",
});

const AudienceAge: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const age_range = searchGlobalQuery.audience_age_range;
  const weight = age_range?.weight;
  const [range, setRange] = useState<[number, number]>([1, 6]);

  const options: Record<number, string> = {
    1: "13",
    2: "18",
    3: "25",
    4: "35",
    5: "45",
    6: "65+",
  };
  const marks: SliderMarks = options;
  const valueLabels: Record<number, string> = {
    1: "13",
    2: "18",
    3: "25",
    4: "35",
    5: "45",
    6: "",
  };

  const percentageArr = [
    1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95,
  ];
  const percentageOptions = percentageArr.map((percentage) => ({
    label: `>${percentage}%`,
    value: percentage,
  }));

  const setQuery = useRef(
    debounce(
      (query: Partial<SearchQueryGlobalT>) =>
        setPartialSearchGlobalQuery(query),
      500,
    ),
  ).current;

  const handleInputOnChange = (values: number[]): void => {
    const range: [number, number] = [values[0], values[1]];
    setRange(range);

    let ageRange: SearchQueryGlobalAudienceAgeRangeT = undefined;

    if (!(values[0] === 1 && values[1] === 6)) {
      ageRange = {
        left_number: valueLabels[range[0]],
        right_number: valueLabels[range[1]],
        operator: "gte",
        weight: "0.01",
      };
    }

    setQuery({ audience_age_range: ageRange });
  };

  const mapLablesToValues = (labels: [string, string]): [number, number] => {
    const labelValues: Record<string, number> = {
      "13": 1,
      "18": 2,
      "25": 3,
      "35": 4,
      "45": 5,
      "": 6,
    };
    return [labelValues[labels[0]], labelValues[labels[1]]];
  };

  const onPercentageChange = (value: number): void => {
    if (!value) {
      return;
    }

    const ageRange = searchGlobalQuery.audience_age_range
      ? {
          ...searchGlobalQuery.audience_age_range,
          weight: (value / 100).toString(),
        }
      : undefined;

    setPartialSearchGlobalQuery({ audience_age_range: ageRange });
  };

  useEffect(() => {
    let range: [string, string];

    if (age_range) {
      range = [age_range.left_number, age_range.right_number];
    } else {
      range = ["13", ""];
    }
    setRange(mapLablesToValues(range));
  }, [searchGlobalQuery]);

  return (
    <SliderWrapper>
      <Slider
        tooltip={{ open: false }}
        range
        marks={marks}
        step={1}
        min={1}
        max={6}
        value={range}
        onChange={(val) => handleInputOnChange(val)}
      />
      {!(range[0] === 1 && range[1] === 6) && (
        <Select
          style={{ width: "100%" }}
          options={percentageOptions}
          value={weight ? parseFloat(weight) * 100 : undefined}
          onChange={(value) => onPercentageChange(value)}
        />
      )}
    </SliderWrapper>
  );
};

export default AudienceAge;
