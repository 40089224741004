import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import FlexBox from "./components/FlexBox";
import LoaderComponent from "./components/Loader";
import Spacer from "./components/Spacer";
import { ApplicationContextProvider } from "./context-providers/ApplicationContextProvider";
import TranslationsContextProvider from "./context-providers/TranslationsContextProvider";
import { getSession } from "./data-access/getSession";
import CompanyRoot from "./entry-files/company-root";
import InfluencerRoot from "./entry-files/influencer-root";
import NavigationRoot from "./entry-files/navigation-only";
import { EntryFilesRouterPropsT } from "./entry-files/types";
import { QueryKeys } from "./helpers/queryKeys";

const EntryFilesRouter: FC<React.PropsWithChildren<EntryFilesRouterPropsT>> = ({
  id,
  loader,
  translations,
  locale,
  props,
}) => {
  const [translationsReady, setTranslationsReady] = useState(false);

  const { data: session, isError: isSessionError } = useQuery(
    QueryKeys.SESSION,
    getSession,
  );

  const isBrand = session && session.role === "brand";

  const getEntryFile = () => {
    if (id == "company-root" && isBrand) {
      return <CompanyRoot />;
    }

    if (id == "influencer-root") {
      return <InfluencerRoot loginToFb={props?.login_to_fb} />;
    }

    if (id == "navigation-only") {
      return <NavigationRoot isBrand={isBrand} />;
    }

    return <p>{`Entry file NOT FOUND.`}</p>;
  };

  if (isSessionError) {
    // TODO: Error initial data data fetch
    console.error("Error: Initial data fetch failed!: ", isSessionError);

    return <div>{"Error! Check the console."}</div>;
  }

  return (
    <TranslationsContextProvider
      setTranslationsReady={setTranslationsReady}
      translations={translations}
      locale={locale}
    >
      {translationsReady && session ? (
        <ApplicationContextProvider session={session}>
          {getEntryFile()}
        </ApplicationContextProvider>
      ) : (
        <>
          {loader !== "none" && (
            <FlexBox fullHeight={true} flexDirection="column">
              <Spacer size="big" direction="vertical" />
              <LoaderComponent />
              <Spacer size="big" direction="vertical" />
            </FlexBox>
          )}
        </>
      )}
    </TranslationsContextProvider>
  );
};

export default EntryFilesRouter;
