import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import {
  QueryClientProvider as QueryClientProviderV3,
  QueryClient as QueryClientV3,
} from "react-query";
import EntryFilesRouter from "./EntryFilesRouter";

export default ({
  id,
  props,
  loader,
  translations,
  locale,
}: React.ComponentProps<typeof EntryFilesRouter>) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
  const queryClientV3 = new QueryClientV3({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <QueryClientProviderV3 client={queryClientV3}>
        <EntryFilesRouter
          id={id}
          props={props}
          translations={translations}
          locale={locale}
          loader={loader}
        />
      </QueryClientProviderV3>
    </QueryClientProvider>
  );
};
