import { useForm } from "antd/es/form/Form";
import Modal from "antd/es/modal/Modal";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { createOverlapAnalysis } from "../../data-access/createOverlapAnalysis";
import { getNetworkProfileSuggestions } from "../../data-access/getNetworkProfileSuggestions";
import { getOverlapAnalyses } from "../../data-access/getOverlapAnalyses";
import { createOverlapAnalysisMutation } from "../../data-access/mutation/createOverlapAnalysisMutation";
import LimitHeader from "../../ui/LimitHeader";
import CreateForm, { OverlapFieldTypeT } from "./CreateForm";
import Table from "./Table";

const OverlapAnalysesContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const [term, setTerm] = useState<string>("");
  const [addNewModal, setAddNewModal] = useState<boolean>(false);

  const [form] = useForm<OverlapFieldTypeT>();
  const { t } = useTranslate("brands.overlap_analyses.index");

  const clearStates = (): void => {
    setAddNewModal(false);
    form.resetFields();
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    () =>
      createOverlapAnalysis({
        overlap_analysis: {
          title: form.getFieldValue("title"),
          handles: form.getFieldValue("handles"),
        },
      }),
    {
      onSuccess: (data) => {
        createOverlapAnalysisMutation(
          queryClient,
          data.overlap_analysis,
          clearStates,
        );
      },
    },
  );

  const { data: suggestions, refetch } = useQuery(
    QueryKeys.NETWORK_PROFILE_SUGGESTIONS,
    () =>
      getNetworkProfileSuggestions({
        term: term,
        provider: "instagram",
      }),
  );

  const {
    data: overlapAnalyses,
    isLoading,
    isRefetching,
  } = useQuery(QueryKeys.OVERLAP_ANALYSES, () => getOverlapAnalyses());

  const onTypeChangeHandle = (value: string): void => {
    if (value.length > 1) {
      setTerm(value);
      refetch();
    }
  };

  const onCreateSubmitHandle = (): void => {
    form.submit();
  };

  return (
    <Container>
      <LimitHeader
        title={t("title")}
        onClickHandle={setAddNewModal}
        submitTitle={t("create")}
        entitlementFeature="overlap-analyses"
        hideCreate={overlapAnalyses?.length === 0}
      />
      <Table
        form={form}
        onFinishHandle={mutate}
        loading={isLoading}
        suggestions={suggestions ? suggestions : []}
        onTypeChangeHandle={onTypeChangeHandle}
        overlapAnalyses={overlapAnalyses}
        dataIsLoading={isLoading || isRefetching}
        onSubmitHandle={onCreateSubmitHandle}
      />

      <Modal
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onOk={onCreateSubmitHandle}
        title={t("modal.header")}
        okText={t("modal.create")}
        cancelText={t("modal.cancel")}
        okButtonProps={{ disabled: isLoading }}
      >
        <CreateForm
          form={form}
          onFinishHandle={mutate}
          suggestions={suggestions ? suggestions : []}
          onTypeChangeHandle={onTypeChangeHandle}
        />
      </Modal>
    </Container>
  );
};

export default OverlapAnalysesContainer;
