import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { Bar } from "react-chartjs-2";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Spacer from "../../../../../../components/Spacer";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import { GeoCardDataT } from "../../types";
import { Tab } from "../engagement/Engagement";
import MissingChart from "../MissingChart";
import { getCustomTicksGeo, getOptionsGeo } from "./options";

const ContentWrapper = styled(FlexBox)({
  position: "relative",
  marginTop: rem(10),
  minHeight: rem(200),
});

type GeoT = {
  data: GeoCardDataT;
};

export type GeoTypeT = "cities" | "countries";

const Geo: FC<React.PropsWithChildren<GeoT>> = ({ data }) => {
  const [currentTab, setCurrentTab] = useState<GeoTypeT>("countries");
  const { cities, countries } = data;

  const { t } = useTranslate("brands.influencer_database.detail.card");

  const dataCities = Object.entries(cities).map((item) => ({
    [item[0]]: item[1],
  }));

  const dataCountries = Object.entries(countries).map((item) => ({
    [item[0]]: item[1],
  }));

  const valuesCities = dataCities
    .map((s) => Object.values(s))
    .map((a) => a.join());
  const valuesCountries = dataCountries
    .map((s) => Object.values(s))
    .map((a) => a.join());
  const labelsCities = dataCities
    .map((s) => Object.keys(s))
    .map((a) => a.join());
  const labelsCountries = dataCountries
    .map((s) => Object.keys(s))
    .map((a) => a.join());

  const chartDataCities = {
    labels: labelsCities,
    datasets: [
      {
        data: valuesCities,
        backgroundColor: [
          theme.color.primaryColor,
          theme.color.lightPrimaryColor,
          theme.color.yellowColor,
          theme.color.yellowHoverColor,
        ],
        barThickness: 16,
        borderRadius: 5,
      },
    ],
  };

  const chartDataCountries = {
    labels: labelsCountries,
    datasets: [
      {
        data: valuesCountries,
        backgroundColor: [
          theme.color.primaryColor,
          theme.color.lightPrimaryColor,
          theme.color.yellowColor,
          theme.color.yellowHoverColor,
        ],
        barThickness: 16,
        borderRadius: 5,
      },
    ],
  };

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
    >
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-start">
        <Tab
          onClick={() => setCurrentTab("countries")}
          active={currentTab === "countries"}
        >
          {t("others.countries")}
        </Tab>
        <Spacer size="big" />
        {valuesCities && valuesCities.length > 0 && (
          <Tab
            onClick={() => setCurrentTab("cities")}
            active={currentTab === "cities"}
          >
            {t("others.cities")}
          </Tab>
        )}
      </FlexBox>
      <ContentWrapper fullHeight={true} customWidth={CustomWidthE.full}>
        {currentTab === "countries" && (
          <Bar
            data={chartDataCountries}
            plugins={[getCustomTicksGeo("countries")]}
            options={
              getOptionsGeo(
                valuesCountries,
                labelsCountries,
                "countries",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as any
            }
          />
        )}
        {currentTab === "cities" && (
          <>
            {valuesCities && valuesCities.length > 0 ? (
              <Bar
                data={chartDataCities}
                plugins={[getCustomTicksGeo("cities")]}
                options={getOptionsGeo(valuesCities, labelsCities, "cities")}
              />
            ) : (
              <MissingChart />
            )}
          </>
        )}
      </ContentWrapper>
    </FlexBox>
  );
};

export default Geo;
