import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import Avatar from "../../../../../components/Avatar";
import { useTranslate } from "../../../../../hooks/useTranslate";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { ApprovalRequestWithAuthorT } from "../../../../types";
import ColourWrapper from "../../../ui/ColourWrapper";
import { Button } from "../../../ui/controls";
import Faq from "../../../ui/Faq";
import Footer from "../../../ui/Footer";
import { Heading } from "../../../ui/Header";
import InfoCard from "../../../ui/InfoCard";
import { Item, List } from "../../../ui/List";
import Modal from "../../../ui/Modal";
import TextWrapper from "../../../ui/TextWrapper";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type WelcomeT = {
  approvalRequest: ApprovalRequestWithAuthorT;
};

const Welcome: FC<React.PropsWithChildren<WelcomeT>> = ({
  approvalRequest,
}) => {
  const {
    author: { email },
    network_profile: { profile_id },
    company_name,
    mentions,
  } = approvalRequest;
  const mentionsAsString =
    mentions.length > 0 ? `@${mentions.join(", @")}` : null;
  const { t } = useTranslate("influencer_root.approval_request.welcome");
  const [isOpen, setIsOpen] = useState(false);

  const cardItems = [
    {
      icon: <Icon icon={IconTypeE.alarm} size={16} />,
      title: t("reasons.timeSaving.title"),
      text: t("reasons.timeSaving.text"),
    },
    {
      icon: <Icon icon={IconTypeE.documentBold} size={16} />,
      title: t("reasons.simplicity.title"),
      text: t("reasons.simplicity.text", { mentions: mentionsAsString }),
    },
    {
      icon: <Icon icon={IconTypeE.key} size={16} />,
      title: t("reasons.safety.title"),
      text: t("reasons.safety.text"),
    },
  ];

  const modalFaqItems = [
    {
      header: t("faq.item1.title"),
      content: t("faq.item1.text"),
    },
    {
      header: t("faq.item2.title"),
      content: t("faq.item2.text"),
    },
    {
      header: t("faq.item3.title"),
      content: t("faq.item3.text"),
    },
    {
      header: t("faq.item4.title"),
      content: t("faq.item4.text"),
    },
    {
      header: t("faq.item5.title"),
      content: t("faq.item5.text"),
    },
    {
      header: t("faq.item6.title"),
      content: t("faq.item6.text"),
    },
    {
      header: t("faq.item7.title"),
      content: t("faq.item7.text"),
    },
  ];

  return (
    <>
      <HeaderWrapper>
        <Heading>{t("title")}</Heading>
        <Button like="link" type="button" onClick={() => setIsOpen(true)}>
          {t("faq.title")}
        </Button>
      </HeaderWrapper>
      <ColourWrapper
        title={t("box.title", { handle: profile_id })}
        text={t("box.text", {
          email: email,
          company: company_name,
        })}
        img={
          <Avatar
            size={104}
            name={"dsad"}
            src={
              approvalRequest.avatar_url?.length
                ? approvalRequest.avatar_url
                : null
            }
          />
        }
      />
      <TextWrapper
        title={t("description.title")}
        text={t("description.text", {
          mentions: mentionsAsString || "@mention",
        })}
      />
      <List>
        {cardItems.map((item) => (
          <Item key={item.title}>
            <InfoCard {...item} />
          </Item>
        ))}
      </List>
      <Footer />
      <Modal
        isOpen={isOpen}
        header={t("faq.title")}
        onClose={() => setIsOpen(false)}
      >
        <Faq data={modalFaqItems} />
      </Modal>
    </>
  );
};

export default Welcome;
