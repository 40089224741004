import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { designToken } from "../helpers/antDesign";
import { MixpanelEventT, trackEvent } from "../helpers/trackEvent";
import { rem, theme } from "../styling/theme";
import Icon, { IconRotateT, IconSizeT, IconTypeE } from "../ui/icons/Icon";
import FlexBox from "./FlexBox";
import Paragraph, { ParagraphSizeT } from "./Paragraph";
import Spacer from "./Spacer";

type BlueLinkTypeT = "default" | "black" | "grey";

const getColor = (type: BlueLinkTypeT) => {
  switch (type) {
    case "default":
      return {
        color: theme.color.primaryColor,

        "span, a": {
          borderBottom: "1px solid transparent",
        },

        ":hover, *:hover, :focus, *:focus": {
          color: designToken.colorPrimaryHover,
        },
      };
    case "black":
      return {
        color: designToken.colorText,

        "span, a": {
          borderBottom: `1px solid ${designToken.colorText}`,
        },

        ":hover, *:hover, :focus, *:focus": {
          color: theme.color.primaryColor,
          borderColor: theme.color.primaryColor,
        },
      };
    case "grey":
      return {
        color: designToken.colorTextTertiary,

        "span, a": {
          borderBottom: `1px solid ${designToken.colorTextTertiary}`,
        },

        ":hover, *:hover, :focus, *:focus": {
          color: designToken.colorText,
          borderColor: designToken.colorText,
        },
      };
  }
};

const StyledBlueLink = styled(FlexBox)<{
  marginTop: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  type: BlueLinkTypeT;
}>(({ marginTop, marginBottom, marginLeft, marginRight, type }) => ({
  display: "inline-block",
  width: "max-content",
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  cursor: "pointer",
  transition: "border 0.4s",
  transitionTimingFunction: theme.timing.default,
  ...getColor(type),
}));

type BlueLinkT = {
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => any;
  icon?: IconTypeE;
  iconColor?: string;
  iconPosition?: "before" | "after";
  iconSize?: IconSizeT;
  href?: string;
  target?: "_blank" | "_self";
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paragraphSize?: ParagraphSizeT;
  rotate?: IconRotateT;
  fontWeight?: 400 | 500 | 600 | 700;
  type?: BlueLinkTypeT;
  event?: MixpanelEventT;
};

const BlueLink: FC<React.PropsWithChildren<BlueLinkT>> = ({
  text,
  onClick,
  icon,
  iconColor = theme.color.primaryColor,
  iconPosition = "before",
  iconSize = "small",
  href,
  rotate,
  event,
  target = "_self",
  marginTop = rem(4),
  marginBottom = rem(0),
  marginLeft = rem(0),
  marginRight = rem(0),
  paragraphSize = "small",
  fontWeight = 400,
  type = "default",
}) => {
  const onClicktrackEvent = (): void => {
    event && trackEvent(event);
  };

  const onClickHandle = (): void => {
    onClick && onClick();
    onClicktrackEvent();
  };

  const getContent = () => (
    <FlexBox>
      {icon && iconPosition === "before" && (
        <>
          <Icon icon={icon} size={iconSize} color={iconColor} rotate={rotate} />
          <Spacer size="micro" />
        </>
      )}
      <Paragraph
        paragraph={text}
        color="inherit"
        paragraphSize={paragraphSize}
        fontWeight={fontWeight}
        cursor="pointer"
      />
      {icon && iconPosition === "after" && (
        <>
          <Spacer size="micro" />
          <Icon icon={icon} size={iconSize} color={iconColor} rotate={rotate} />
        </>
      )}
    </FlexBox>
  );

  if (href) {
    return (
      <StyledBlueLink
        type={type}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <a href={href} target={target} onClick={onClicktrackEvent}>
          {getContent()}
        </a>
      </StyledBlueLink>
    );
  }

  return (
    <StyledBlueLink
      type={type}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={onClickHandle}
    >
      {getContent()}
    </StyledBlueLink>
  );
};

export default BlueLink;
