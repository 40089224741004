import { Button, Select, Tooltip } from "antd";
import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { getLists } from "../../../../data-access/getLists";
import { ListT, SearchResultT } from "../../../../types";
import ListsTable from "../../components/ListTable";

type Option = {
  label: string;
  value: number;
};

type ListsT = {
  setListSearchResults: (searchResult: SearchResultT[] | undefined) => void;
};

const Lists: FC<React.PropsWithChildren<ListsT>> = ({
  setListSearchResults,
}) => {
  const [list, setList] = useState<ListT>();
  const { t } = useTranslate("brands.influencer_database.filter.lists");

  const { data, isLoading } = useQuery(QueryKeys.LISTS, () => getLists());

  const disabled = isLoading || data?.length === 0;

  const searchResults =
    list && data?.find((l) => l.id === list.id)?.search_results;
  const selected = list && searchResults;

  const options: Option[] = data
    ? data.map((list) => {
        return { label: list.name, value: list.id };
      })
    : [];

  const handleOnChange = (value: number): void => {
    const list = data?.find((list) => list.id === value);
    setList(list);
  };

  const handleOnClear = (): void => {
    setListSearchResults(undefined);
    setList(undefined);
  };

  const handleFilterOption = (
    input: string,
    option: Option | undefined,
  ): boolean => {
    return (option?.label.toLowerCase() ?? "").includes(input.toLowerCase());
  };

  return (
    <>
      <FlexBox customWidth={CustomWidthE.full}>
        <Tooltip
          title={disabled ? t("disabled") : undefined}
          placement="bottom"
        >
          <Select
            allowClear
            value={list?.id}
            onClear={() => setListSearchResults(undefined)}
            onChange={handleOnChange}
            disabled={disabled}
            placeholder={t("placeholder")}
            style={{ width: "100%", maxWidth: selected ? rem(205) : "100%" }}
            options={options}
            filterOption={handleFilterOption}
          />
        </Tooltip>
        {selected && (
          <Button type="link" onClick={handleOnClear}>
            {t("clear")}
          </Button>
        )}
      </FlexBox>
      {selected && (
        <ListsTable
          list={list}
          searchResults={searchResults}
          setListSearchResults={setListSearchResults}
          handleOnClear={handleOnClear}
        />
      )}
    </>
  );
};

export default Lists;
