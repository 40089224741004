import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Spacer from "../../../../../components/Spacer";
import { theme } from "../../../../../styling/theme";

type RawDataBoxesT = {
  data: Record<string, number | string>[];
  half: boolean;
  withPercentage: boolean;
};

const RawDataBoxes: FC<React.PropsWithChildren<RawDataBoxesT>> = ({
  data,
  half,
  withPercentage,
}) => {
  const values = data.map((s) => Object.values(s)).map((a) => a.join());
  const labels = data.map((s) => Object.keys(s)).map((a) => a.join());

  return (
    <FlexBox
      flexWrap="wrap"
      flexDirection="row"
      customWidth={CustomWidthE.full}
    >
      {labels.map((label, index) => {
        const value = values[index];
        return (
          <FlexBox
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            customWidth={half ? CustomWidthE.half : CustomWidthE.full}
            flexDirection="column"
            alignItems="flex-start"
          >
            <Heading
              headingType={half ? HeadingTypeT.h3 : HeadingTypeT.h4}
              heading={label}
              spaceBottom={"micro"}
              color={theme.color.textGreyColor}
            />
            {value && (
              <Heading
                headingType={HeadingTypeT.h2}
                heading={`${value} ${withPercentage ? "%" : ""}`}
                spaceBottom={0}
              />
            )}
            <Spacer direction="vertical" />
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default RawDataBoxes;
