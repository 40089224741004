import { I18n } from "i18n-js";

type putSpacesToThousandT = (num: number | string) => string | number;
export const putSpacesToThousand: putSpacesToThousandT = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return num;
};

type numberToTextT = (number: number, toFixed?: number) => string;
export const numberToText: numberToTextT = (number, toFixed = 1) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return `${number}`;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return `${scaled.toFixed(toFixed) + suffix}`;
};

type capitalizeFirstLetterT = (string: string) => string;
export const capitalizeFirstLetter: capitalizeFirstLetterT = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type roundNumberT = (value: number, precision?: number) => number;
export const roundNumber: roundNumberT = (value, precision = 0) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const numberWithSpaces = (val: number | string): string => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

type FormatCurrencyOptionsT = {
  valueInCents?: boolean;
};
export const formatCurrency = (
  value: number,
  currency: "CZK" | "EUR" | "USD" | null,
  options?: FormatCurrencyOptionsT,
) => {
  if (!currency) {
    return "";
  }
  const finalValue = options?.valueInCents ? value / 100 : value;
  if (currency === "CZK") {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: "CZK",
      maximumFractionDigits: 2,
    }).format(finalValue);
  }

  if (currency === "EUR") {
    return new Intl.NumberFormat("en-IE", {
      style: "currency",
      currency: "EUR",
    }).format(finalValue);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(finalValue);
};

export const formatDate = (value: string | Date | undefined, i18n: I18n) => {
  if (!value) {
    return "";
  }

  return i18n.l(
    "date_formats.default",
    typeof value === "string" ? new Date(value) : value,
  );
};
