import { theme as antTheme } from "antd";
import { AliasToken } from "antd/lib/theme/interface";
import { theme } from "../styling/theme";

const { getDesignToken } = antTheme;

export const defaults = {
  token: {
    colorPrimary: theme.color.primaryColor,
    colorLink: theme.color.primaryColor,
    gray1: "#ffffff",
    gray2: "#fafafa",
    gray3: "#f5f5f5",
    gray4: "#f0f0f0",
    gray5: "#d9d9d9",
    gray6: "#bfbfbf",
    gray7: "#8c8c8c",
    gray8: "#595959",
    gray9: "#434343",
    gray10: "#262626",
    gray11: "#1f1f1f",
    gray12: "#141414",
    gray13: "#000000",
  },
  components: {
    Progress: {
      defaultColor: theme.color.primaryColor,
      remainingColor: theme.color.remainingColor,
    },
  },
};

// there is a bug and neutral palette is not part of the antd so we have to add it manually
// https://stackoverflow.com/questions/71372137/antd-configprovider-not-generating-same-colors-as-provided-on-color-palettes/77127362
type AppAliasToken = AliasToken & {
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  gray8: string;
  gray9: string;
  gray10: string;
  gray11: string;
  gray12: string;
  gray13: string;
};

export const designToken = getDesignToken(defaults) as AppAliasToken;
