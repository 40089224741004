import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { rem } from "../../styling/theme";

export const Content = styled(FlexBox)<{ padding?: string }>(
  ({ padding = `${rem(16)} ${rem(16)} 0 ${rem(16)}` }) => ({
    flexGrow: 1,
    padding,
  }),
);

export const TopBar = styled(FlexBox)({
  padding: `${rem(8)} ${rem(12)}`,
});
