import styled from "@emotion/styled";
import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Segmented,
  Select,
  Tag,
} from "antd";
import { SegmentedLabeledOption } from "antd/es/segmented";
import { checkVAT, countries } from "jsvat";
import { debounce } from "lodash";
import { postcodeValidator } from "postcode-validator";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import BlueLink from "../../../../components/BlueLink";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { validateEmptyString } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import { LogoLetterDotIcon } from "../../../../ui/icons/CustomIcons";
import { IconTypeE } from "../../../../ui/icons/Icon";
import { getSubscriptionPlanPriceEstimate } from "../../data-access/getSubscriptionPlanPriceEstimate";
import { getSubscriptionProposalPriceEstimate } from "../../data-access/getSubscriptionProposalPriceEstimate";
import { SubscriptionPriceEstimateT, SubscriptionProposalT } from "../../types";
import SubscriptionPlanEntitlements from "../subscription-plans/SubscriptionPlanEntitlements";
import PriceSummary from "./PriceSummary";
import { PlanPriceStateT } from "./WizardContainer";

const Wrapper = styled(FlexBox)({
  alignItems: "flex-start",
  width: "100%",

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  width: "50%",
  padding: rem(32),

  [theme.media.maxMd]: {
    width: "100%",
  },
});

const LogoWrapper = styled(FlexBox)({
  width: rem(32),
  height: rem(32),
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: rem(16),

  svg: {
    height: rem(16),
  },
});

const StyledSegmented = styled(Segmented)({
  ".ant-segmented-item-label": {
    height: "100%",
  },
});

const SegmentedLabelWrapper = styled(FlexBox)({
  padding: rem(8),
  flexDirection: "column",
  alignItems: "flex-start",
  height: "100%",
  cursor: "pointer",
});

const StyledTag = styled(Tag)({
  marginLeft: rem(6),
});

const EntitlementsWrapper = styled(FlexBox)<{
  open: boolean;
  marginLeft?: number;
  height: number;
}>(({ open, marginLeft, height }) => ({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: open ? rem(height) : 0,
  transition: "height 0.5s",
  overflow: "hidden",
  marginLeft: marginLeft && rem(marginLeft),
}));

const ImageWrapper = styled(ContentWrapper)({
  justifyContent: "space-between",
  alignSelf: "stretch",
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: `0 ${rem(designToken.borderRadiusLG)} ${rem(
    designToken.borderRadiusLG,
  )} 0`,
});

export type CheckoutFieldTypeT = {
  company: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  vat: string;
  tac: boolean;
  invoice_emails?: string[];
};

type Option = {
  label: string;
  value: string;
};

type CheckoutFormPlanT = {
  planPriceState: PlanPriceStateT;
  setPlanPriceState: (planPriceState: PlanPriceStateT | undefined) => void;
  payment_type: "card" | "invoice";
  subscriptionProposal?: never;
  initialCountry?: string;
};

type CheckoutFormProposalT = {
  subscriptionProposal: SubscriptionProposalT;
  payment_type: "card" | "invoice";
  planPriceState?: never;
  setPlanPriceState?: never;
  initialCountry?: never;
};

type CheckoutFormT = CheckoutFormPlanT | CheckoutFormProposalT;

const CheckoutForm: FC<React.PropsWithChildren<CheckoutFormT>> = ({
  planPriceState,
  setPlanPriceState,
  subscriptionProposal,
  payment_type,
  initialCountry,
}) => {
  const [planDetail, setPlanDetail] = useState(false);
  const [priceEstimate, setPriceEstimate] = useState<
    SubscriptionPriceEstimateT | undefined
  >(subscriptionProposal?.price_estimate);
  const [country, setCountry] = useState<string>(initialCountry || "");
  const [vatNumber, setVatNumber] = useState<string>("");

  const plan = planPriceState?.plan;
  const planPrice = planPriceState?.price;

  const planName = planPriceState
    ? planPriceState.plan.external_name
    : subscriptionProposal.plan;
  const entitlements = planPriceState
    ? planPriceState.plan.entitlements
    : subscriptionProposal.entitlements;
  const currency = planPriceState?.price.price.currency;

  const countriesOptions: Option[] = countries.map((country) => {
    return { value: country.name, label: country.name };
  });

  const { t } = useTranslate("brands.wizard.checkout");

  const {
    refetch: refetchProposalEstimate,
    isFetching: isFetchingProposalEstimate,
  } = useQuery(
    QueryKeys.SUBSCRIPTIONS_PROPOSAL_PRICE_ESTIMATE,
    () => {
      const countryCode = countries.find((c) => c.name === country)?.codes[0];

      return getSubscriptionProposalPriceEstimate({
        country_code: countryCode,
        vat_number: vatNumber,
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setPriceEstimate(data);
      },
    },
  );

  const { refetch: refetchPlanEstimate, isFetching: isFetchingPlanEstimate } =
    useQuery(
      QueryKeys.SUBSCRIPTIONS_PRICE_ESTIMATE,
      () => {
        if (!planPriceState) {
          return Promise.reject();
        }

        const countryCode = countries.find((c) => c.name === country)?.codes[0];

        return getSubscriptionPlanPriceEstimate({
          plan: planPriceState?.plan.id,
          period: planPriceState?.price.period_unit,
          country_code: countryCode,
          vat_number: vatNumber,
          currency: planPriceState?.price.price.currency,
        });
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setPriceEstimate(data);
        },
      },
    );

  const refetchEstimate = useRef(
    debounce(
      subscriptionProposal ? refetchProposalEstimate : refetchPlanEstimate,
      500,
    ),
  ).current;

  const onSegmentedChangeHandle = (val: string): void => {
    if (plan && currency) {
      const newPrice = plan.prices.find(
        (price) => price.period_unit === val && price.id.match(currency),
      );
      newPrice &&
        setPlanPriceState &&
        setPlanPriceState({ plan, price: newPrice });
    }
  };

  const onChangePlanHandle = (): void => {
    setPlanPriceState && setPlanPriceState(undefined);
  };

  const validateZip = (value: string, country: string) => {
    const selectedCountry = countries.find((c) => c.name === country);

    if (
      value &&
      selectedCountry &&
      !postcodeValidator(value, selectedCountry.codes[0])
    ) {
      return Promise.reject(t("form.errors.zip"));
    }

    return Promise.resolve();
  };

  const validateVatId = (value: string, country: string) => {
    const errMessage = t("form.errors.vat");
    const selected = countries.find((c) => c.name === country);

    if (value && selected) {
      if (selected && checkVAT(value, [selected]).isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(errMessage);
      }
    }

    return Promise.resolve();
  };

  const segmentedOptions: SegmentedLabeledOption[] | undefined =
    plan && planPrice && currency
      ? plan.prices
          .filter((price) => price.id.match(currency))
          .map((price) => ({
            label: (
              <SegmentedLabelWrapper>
                <Radio
                  checked={planPrice.period_unit === price.period_unit}
                  onChange={(e) => onSegmentedChangeHandle(e.target.value)}
                  value={price.period_unit}
                >
                  {t(`billing.${price.period_unit}`)}
                  {price.period_unit === "year" && (
                    <StyledTag color="green">{t("save20")}</StyledTag>
                  )}
                </Radio>
              </SegmentedLabelWrapper>
            ),
            value: price.period_unit,
          }))
      : undefined;

  useEffect(() => {
    refetchEstimate();
  }, [country, vatNumber, planPriceState]);

  return (
    <Wrapper>
      <ContentWrapper>
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth="100%"
          gap={rem(16)}
        >
          <Paragraph paragraph={t("title")} fontWeight={600} />
          <FlexBox customWidth="100%" justifyContent="space-between">
            <FlexBox gap={rem(8)}>
              <LogoWrapper>
                <LogoLetterDotIcon />
              </LogoWrapper>
              <Paragraph
                paragraph={t("plan", {
                  title: t(`form.plans.${planName.toLowerCase()}`),
                })}
                fontWeight={600}
              />
            </FlexBox>
            {planPriceState && (
              <Button type="link" onClick={onChangePlanHandle}>
                {t("change_plan")}
              </Button>
            )}
          </FlexBox>
          <>
            {segmentedOptions && planPrice && (
              <StyledSegmented
                block
                value={planPrice.period_unit}
                onChange={(val) => onSegmentedChangeHandle(val as string)}
                style={{ width: "100%" }}
                options={segmentedOptions}
              />
            )}
            <BlueLink
              text={t("plan_details")}
              onClick={() => setPlanDetail((prev) => !prev)}
              paragraphSize="small"
              type="grey"
              icon={planDetail ? IconTypeE.arrowBottom : IconTypeE.arrowRight}
              iconPosition="after"
              iconColor="grey"
            />
            <EntitlementsWrapper open={planDetail} height={440}>
              {plan ? (
                <SubscriptionPlanEntitlements
                  planId={plan.id}
                  entitlements={entitlements}
                />
              ) : (
                subscriptionProposal && (
                  <SubscriptionPlanEntitlements
                    planId={subscriptionProposal.plan}
                    entitlements={entitlements}
                  />
                )
              )}
            </EntitlementsWrapper>
          </>
        </FlexBox>
        <Divider />
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth="100%"
          gap={rem(24)}
        >
          <Paragraph paragraph={t("billing_details")} fontWeight={600} />
          <FlexBox
            flexDirection="column"
            customWidth="100%"
            alignItems="stretch"
          >
            <Form.Item<CheckoutFieldTypeT>
              label={t("form.company_name")}
              name="company"
              rules={[
                {
                  required: true,
                  message: t("form.errors.base"),
                },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item<CheckoutFieldTypeT>
              label={t("form.address")}
              labelCol={{ span: 24 }}
              name="address"
              rules={[
                { required: true, message: t("form.errors.base") },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <FlexBox customWidth="100%" gap={rem(16)}>
              <Form.Item<CheckoutFieldTypeT>
                style={{ width: "70%" }}
                label={t("form.city")}
                name="city"
                rules={[
                  { required: true, message: t("form.errors.base") },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item<CheckoutFieldTypeT>
                style={{ width: "30%" }}
                labelCol={{ span: 24 }}
                label={t("form.zip")}
                name="zip"
                rules={[
                  {
                    required: true,
                    message: t("form.errors.base"),
                  },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                  ({ getFieldValue }) => ({
                    validator: (_rule, value) =>
                      validateZip(value, getFieldValue("country")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </FlexBox>
            <Form.Item<CheckoutFieldTypeT>
              label={t("form.country")}
              name="country"
              rules={[
                {
                  required: true,
                  message: t("form.errors.base"),
                },
                () => ({
                  validator: (_rule, value) =>
                    validateEmptyString(value, t("form.errors.format")),
                }),
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                onChange={setCountry}
                options={countriesOptions}
              />
            </Form.Item>
            <Form.Item<CheckoutFieldTypeT>
              label={t("form.vat")}
              name="vat"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_rule, value) =>
                    validateVatId(value, getFieldValue("country")),
                }),
              ]}
            >
              <Input onChange={(e) => setVatNumber(e.target.value)} />
            </Form.Item>
          </FlexBox>
        </FlexBox>
        {/* <Divider />
        <FlexBox customWidth="100%" gap={rem(16)} justifyContent="flex-start">
          <Form.Item<CheckoutFieldTypeT>
            label={t("form.invoice_emails")}
            name="invoice_emails"
            labelCol={{ span: 24 }}
            tooltip={t("form.invoice_emails_tooltip")}
            style={{ width: "100%" }}
          >
            <Select mode="tags" />
          </Form.Item>
        </FlexBox> */}
      </ContentWrapper>
      <ImageWrapper>
        <PriceSummary
          priceEstimate={priceEstimate}
          subscriptionProposal={subscriptionProposal}
          planPriceState={planPriceState}
          isFetching={isFetchingProposalEstimate || isFetchingPlanEstimate}
          paymentType={payment_type}
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default CheckoutForm;
