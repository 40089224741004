import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";

const Section = styled(FlexBox)<{ withBorder?: boolean }>(
  ({ withBorder = true }) => ({
    padding: rem(16),
    borderBottom: withBorder ? `1px solid ${theme.color.greyColor}` : "none",
  }),
);

const Header = styled(FlexBox)({
  marginBottom: rem(16),
});

type FilterSectionT = {
  customWidth: CustomWidthE;
  label: string;
  labelDescription?: string;
  withWrapper?: boolean;
  withBorder?: boolean;
};

const FilterSection: FC<React.PropsWithChildren<FilterSectionT>> = ({
  customWidth,
  label,
  labelDescription,
  withWrapper = false,
  withBorder,
  children,
}) => {
  const renderContent = () => (
    <>
      <Header>
        <Paragraph fontWeight={600} paragraphSize="small" paragraph={label} />
        {labelDescription && (
          <>
            <Spacer size="micro" />
            <Paragraph
              fontWeight={600}
              paragraphSize="small"
              color={theme.color.textLightGreyColor}
              paragraph={labelDescription}
            />
          </>
        )}
      </Header>
      {children}
    </>
  );

  return (
    <>
      {withWrapper ? (
        <Section
          withBorder={withBorder}
          alignItems="flex-start"
          customWidth={CustomWidthE.full}
          flexDirection="column"
        >
          {renderContent()}
        </Section>
      ) : (
        <FlexBox
          alignItems="flex-start"
          customWidth={customWidth}
          flexDirection="column"
          justifyContent="flex-start"
        >
          {renderContent()}
        </FlexBox>
      )}
    </>
  );
};

export default FilterSection;
