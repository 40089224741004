import styled from "@emotion/styled";
import { Progress } from "antd";
import BlueLink from "components/BlueLink";
import InfoPopover from "components/InfoPopover";
import { SubscriptionContext } from "entry-files/company-root/context-providers/SubscriptionsContextProvider";
import { SubscriptionCurrentUsageT } from "entry-files/types";
import * as React from "react";
import { useContext, useMemo } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";

const Wrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: hexToRgb(designToken["blue-1"], 0.2),
  padding: `${rem(20)} ${rem(16)}`,
  borderRadius: designToken.borderRadiusSM,
});

const Note = styled(Paragraph)({
  flexGrow: 1,
  textAlign: "right",
});

type PropsT = {
  title: string;
  tooltip?: string;
  usage: SubscriptionCurrentUsageT;
  icon: React.ReactNode;
  link?: React.ReactNode;
  note?: string;
};

const UsageItem = ({
  title,
  tooltip,
  usage: { used, quota },
  icon,
  link,
  note,
}: PropsT) => {
  const { t } = useTranslate("brands.manage_subscription");
  const isUnlimited = quota === "Unlimited";
  const { openPortal, isTrial } = useContext(SubscriptionContext);

  const percentage = useMemo(() => {
    if (isUnlimited) {
      return 100;
    }
    return quota > 0 ? used / (quota / 100) : 100;
  }, [quota, used, isUnlimited]);

  const getProgressStatus = () => {
    if (isUnlimited) {
      return "success";
    }

    return percentage >= 100 ? "exception" : "normal";
  };

  return (
    <Wrapper gap={rem(10)}>
      <FlexBox gap={rem(8)}>
        {icon}
        <Paragraph
          paragraphSize="small"
          paragraph={title}
          marginRight={rem(-3)}
        />
        {tooltip && <InfoPopover placement="right" title={tooltip} />}
      </FlexBox>
      <Progress
        percent={percentage}
        showInfo={false}
        status={getProgressStatus()}
      />
      <FlexBox
        customWidth={CustomWidthE.full}
        justifyContent={note ? "space-between" : "flex-start"}
        gap={rem(4)}
      >
        {isUnlimited ? (
          <Paragraph
            paragraphSize="small"
            paragraph={t("usages.unlimited")}
            color={designToken.green6}
            fontWeight={600}
          />
        ) : (
          <>
            <Paragraph
              paragraph={used.toString()}
              color={used >= quota ? designToken.red4 : theme.color.blackColor}
              fontWeight={600}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={`/ ${quota} ${t("used")}`}
              color={designToken.colorTextTertiary}
              paragraphSize="small"
            />
            {used >= quota && !isTrial ? (
              <BlueLink
                onClick={() => openPortal("edit_subscription")}
                type="grey"
                text={t("buy_credits")}
                marginTop={rem(0)}
                marginLeft={rem(8)}
              />
            ) : null}
          </>
        )}
        {note && <Note paragraph={`+ ${note}`} paragraphSize="small" />}
        {link && link}
      </FlexBox>
    </Wrapper>
  );
};

export default UsageItem;
