import { numberToText } from "../../../../../helpers/formaters";
import AgeGenderExport from "../charts-components/age-gender/AgeGenderExport";
import EngagementHistogramExport from "../charts-components/engagement-histogram/EngagementHistogramExport";
import GeoExport from "../charts-components/geo/GeoExport";
import InterestsDataExport from "../charts-components/interests/InterestsExport";
import { CardsMapT, SocialNetworkProviderT } from "../types";

const followersCard: CardsMapT = {
  type: "followers",
  element: "without",
  graphOptions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callBackForYTicks: (v: any, i: any) =>
      i % 2 == 0 ? numberToText(Math.floor(v / 1000) * 1000) : "",
  },
};

const creditableFollowersCard: CardsMapT = {
  type: "credible_followers",
  element: "without",
  graphOptions: {},
};

const audienceReachabilityCard: CardsMapT = {
  type: "audience_reachability",
  element: "raw",
  graphOptions: {},
};

const engagementHistogramCard: CardsMapT = {
  type: "engagement_histogram",
  element: EngagementHistogramExport,
  graphOptions: {},
};

const engagementRateCard: CardsMapT = {
  type: "engagement_rate",
  element: "without",
  graphOptions: {},
};

const engagementCard: CardsMapT = {
  type: "engagement",
  element: "raw",
  graphOptions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callBackForYTicks: (v: any, i: any) => (i % 2 == 0 ? numberToText(v) : ""),
  },
};

const accountsReachedCard: CardsMapT = {
  type: "accounts_reached",
  element: "raw",
  graphOptions: {},
};

const impressionsCard: CardsMapT = {
  type: "impressions",
  element: "without",
  graphOptions: {},
};

const avgViewsCard: CardsMapT = {
  type: "avg_views",
  element: "without",
  graphOptions: {},
};

const avgReelsPlaysCard: CardsMapT = {
  type: "avg_reels_plays",
  element: "without",
  graphOptions: {},
};

const interestsCard: CardsMapT = {
  type: "interests",
  element: InterestsDataExport,
  graphOptions: {},
};

const ageGenderCard: CardsMapT = {
  type: "age_gender",
  element: AgeGenderExport,
  graphOptions: {},
};

const geoCard: CardsMapT = {
  type: "geo",
  element: GeoExport,
  graphOptions: {},
};

type getCardsForExportMapT = (
  provider: SocialNetworkProviderT,
) => CardsMapT[] | null;
export const getCardsForExportMap: getCardsForExportMapT = (provider) => {
  switch (provider) {
    case "instagram":
      return [
        geoCard,
        ageGenderCard,
        interestsCard,
        engagementHistogramCard,
        audienceReachabilityCard,
        accountsReachedCard,
        engagementCard,
        engagementRateCard,
        followersCard,
        creditableFollowersCard,
        impressionsCard,
        avgReelsPlaysCard,
      ];

    case "youtube":
      return [
        geoCard,
        ageGenderCard,
        accountsReachedCard,
        engagementCard,
        engagementRateCard,
        followersCard,
      ];

    case "tiktok":
      return [
        geoCard,
        ageGenderCard,
        interestsCard,
        engagementHistogramCard,
        audienceReachabilityCard,
        accountsReachedCard,
        engagementCard,
        engagementRateCard,
        followersCard,
        creditableFollowersCard,
        impressionsCard,
        avgViewsCard,
      ];

    default:
      return null;
  }
};
