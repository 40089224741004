import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import Paragraph from "../../../../components/Paragraph";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import { createCompetitiveAnalysis } from "../../data-access/createCompetitiveAnalysis";
import { getCompetitiveAnalyses } from "../../data-access/getCompetitiveAnalyses";
import { createCompetitiveAnalysisMutation } from "../../data-access/mutation/createCompetitiveAnalysisMutation";
import LimitHeader from "../../ui/LimitHeader";
import CreateForm, { CompetitiveAnalysisFieldTypeT } from "./CreateForm";
import Table from "./Table";

const CompetitiveAnalysesContainer: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [addNewModal, setAddNewModal] = useState<boolean>(false);

  const [form] = useForm<CompetitiveAnalysisFieldTypeT>();
  const { t } = useTranslate("brands.competitive_analyses.index");

  const clearStates = (): void => {
    setAddNewModal(false);
    form.resetFields();
  };

  const {
    data: competitiveAnalyses,
    isLoading,
    isRefetching,
  } = useQuery(QueryKeys.COMPETITIVE_ANALYSES, () => getCompetitiveAnalyses());

  const queryClient = useQueryClient();
  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    () =>
      createCompetitiveAnalysis({
        competitive_analysis: {
          handle: form.getFieldValue("handle"),
        },
      }),
    {
      onSuccess: (data) => {
        createCompetitiveAnalysisMutation(
          queryClient,
          data.competitive_analysis,
          clearStates,
        );
      },
    },
  );

  const onCreateSubmitHandle = (): void => {
    form.submit();
  };

  return (
    <Container>
      <LimitHeader
        title={t("title")}
        onClickHandle={setAddNewModal}
        submitTitle={t("create")}
        entitlementFeature="competitive-analyses"
        hideCreate={competitiveAnalyses?.length === 0}
      />
      <Table
        competitiveAnalyses={competitiveAnalyses}
        dataIsLoading={isLoading || isRefetching}
        form={form}
        onFinishHandle={create}
        onSubmitHandle={onCreateSubmitHandle}
        loading={isCreateLoading}
      />

      <Modal
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onOk={onCreateSubmitHandle}
        title={t("modal.header")}
        okText={t("modal.create")}
        cancelText={t("modal.cancel")}
        okButtonProps={{ disabled: isCreateLoading }}
      >
        <Paragraph
          paragraph={t("modal.description")}
          color={theme.color.textGreyColor}
        />
        <CreateForm form={form} onFinishHandle={create} />
      </Modal>
    </Container>
  );
};

export default CompetitiveAnalysesContainer;
