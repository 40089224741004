import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useMatch, useParams } from "react-router-dom";
import Container from "../../../../components/Container";
import Panel from "../../../../components/Panel";
import StickyHeader from "../../../../components/StickyHeader";

import ActionCable from "actioncable";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useTranslate } from "../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../styling/theme";
import { getNetworkProfile } from "../../data-access/getNetworkProfile";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import Cards from "./components/Cards";
import ExportContainer from "./components/ExportContainer";
import ProfileHeader from "./components/ProfileHeader";
import SkeletonPlaceholder from "./components/SkeletonPlaceholder";
import Crm from "./Crm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
);

const ContentWrapper = styled(Panel)({
  flexDirection: "column",
  borderRadius: rem(8),

  [theme.media.maxSm]: {
    marginTop: rem(16),
  },
});

const StyledTabs = styled(Tabs)({
  width: "100%",
  padding: `0 ${rem(16)}`,

  ".ant-tabs-nav": {
    marginBottom: 0,
  },
});

type InfluencerReportChannelMessageT = "influencer_report_finished";

const InfluencerDatabaseDetailContainer: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams() as { id: string };

  const [exportType, setExportType] = useState<"copy" | "save" | null>(null);
  const [exportPrice, setExportPrice] = useState<string>("");
  const [exportDescription, setExportDescription] = useState<string>("");

  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  const { tabletVersion } = useResponsive(breakpoints.sm);

  const navigate = useRouterNavigate();
  const match = useMatch(`${NavigationKeyE.Database}/${id}/:key`);
  const activeKey = match ? match.params.key : "overview";

  const cable = ActionCable.createConsumer();

  const { t } = useTranslate("brands.influencer_database.detail");

  const tabItems: TabsProps["items"] = [
    {
      key: "overview",
      label: t("overview"),
    },
    {
      key: "crm",
      label: t("crm"),
    },
  ];

  const subscribeForInfluencerReport = (id: string): void => {
    const channelOptions = {
      channel: "InfluencerReportChannel",
      influencer_report_id: id,
    };
    cable.subscriptions.create(channelOptions, {
      received: (data: { message: InfluencerReportChannelMessageT }) => {
        if (data.message === "influencer_report_finished") {
          timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
          cable.disconnect();
          refetch();
        }
      },
    });
  };

  const {
    data: networkProfile,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(QueryKeys.NETWORK_PROFILE_DETAIL, () => getNetworkProfile(id), {
    onSuccess: (data) => {
      if (data.influencer_report?.status === "pending") {
        subscribeForInfluencerReport(data.influencer_report.id);
        const timeout = setTimeout(() => window.location.reload(), 5000);
        timeoutIdRef.current = timeout;
      }
    },
    onError: () => {
      navigate(NavigationKeyE.Error);
    },
  });

  const onTabChangeHandle = (key: string): void => {
    navigate(NavigationKeyE.Database, `${id}/${key}`);
  };

  useEffect(() => {
    return () => {
      cable.disconnect();
    };
  }, []);

  return (
    <Container>
      <StickyHeader
        renderChildren={(fixed) => (
          <ProfileHeader
            networkProfile={networkProfile}
            isLoading={isLoading}
            fixed={fixed || tabletVersion}
            setExportType={setExportType}
            setExportPrice={setExportPrice}
            setExportDescription={setExportDescription}
            exportPrice={exportPrice}
            exportDescription={exportDescription}
          />
        )}
      />

      {!isLoading &&
      !isFetching &&
      networkProfile &&
      (!networkProfile.influencer_report ||
        networkProfile.influencer_report.status === "finished") ? (
        <>
          <ExportContainer
            influencerData={networkProfile}
            exportType={exportType}
            setExportType={setExportType}
            exportPrice={exportPrice}
            exportDescription={exportDescription}
          />
          <ContentWrapper flexDirection="column">
            <StyledTabs
              items={tabItems}
              onChange={onTabChangeHandle}
              activeKey={activeKey}
            />
            <Routes>
              <Route
                path="overview"
                element={<Cards networkProfile={networkProfile} />}
              />
              <Route
                path="crm"
                element={<Crm networkProfile={networkProfile} />}
              />
            </Routes>
          </ContentWrapper>
        </>
      ) : (
        <SkeletonPlaceholder />
      )}
    </Container>
  );
};

export default InfluencerDatabaseDetailContainer;
