import styled from "@emotion/styled";
import { Button } from "antd";
import { formatDate } from "helpers/formaters";
import * as React from "react";
import { Dispatch, FC, MouseEvent, SetStateAction } from "react";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../ui/table/TableItemWrapper";
import { ExistedOutputT } from "../../../types";
import { BrandAffinityT } from "../types";

const Wrapper = styled(FlexBox)({
  width: "100%",

  div: {
    cursor: "pointer",
  },
});

const MinWidth = styled.div({
  minWidth: rem(47),
});

type TableItemT = {
  item: BrandAffinityT;
  setCurrentMedias: Dispatch<SetStateAction<ExistedOutputT[] | null>>;
  last?: boolean;
};

const TableItem: FC<React.PropsWithChildren<TableItemT>> = ({
  item,
  setCurrentMedias,
  last = false,
}) => {
  const { t, i18n } = useTranslate(
    "brands.influencer_database.detail.brand_affinity",
  );

  const onClickHandle = (item: BrandAffinityT): void => {
    setCurrentMedias(item.medias);

    trackEvent(MixpanelEventT.databaseDetailBrandAffinityShowDetail, {
      mention: item.id,
    });
  };

  const onProfileClickHandle = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    item: BrandAffinityT,
  ): void => {
    e.stopPropagation();

    window.open(`https://instagram.com/${item.id}`, "_blank");
  };

  return (
    <TableItemWrapper
      key={item.id}
      last={last}
      renderChildren={() => {
        return (
          <Wrapper onClick={() => onClickHandle(item)}>
            <TableColumn>
              <Button
                type="link"
                onClick={(e) => onProfileClickHandle(e, item)}
              >
                {`@${item.id}`}
              </Button>
            </TableColumn>
            <TableColumn columnWidth="20%">
              <Paragraph
                paragraph={formatDate(item.medias[0].timestamp, i18n)}
              />
            </TableColumn>
            <TableColumn>
              <MinWidth>
                <Paragraph
                  paragraph={`${item.medias.length} x`}
                  paragraphSize="big"
                  fontWeight={500}
                  align="right"
                />
              </MinWidth>
              {item.medias.length > 0 && (
                <>
                  <Spacer size="small" />
                  <BlueLink text={`(${t("preview")})`} />
                </>
              )}
            </TableColumn>
          </Wrapper>
        );
      }}
    />
  );
};

export default TableItem;
