import styled from "@emotion/styled";
import { formatDate } from "helpers/formaters";
import { useTranslate } from "hooks/useTranslate";
import * as React from "react";
import { FC, useContext } from "react";
import Avatar from "../../../../components/Avatar";
import BlueLink from "../../../../components/BlueLink";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { LightBoxContext } from "../../../../context-providers/LightBoxContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { CompetitiveAnalysisResultT, ExistedOutputT } from "../../types";

const size = rem(204);

const OutputsWrapper = styled.div({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(auto-fill, ${size})`,
  justifyContent: "space-between",
  gridGap: rem(10),
  rowGap: rem(20),
});

const ExternalLink = styled(Icon)({
  cursor: "pointer",
  opacity: 0,
});

const OutputWrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: theme.color.whiteColor,
  padding: rem(12),
  borderRadius: rem(10),
  width: size,
  gap: rem(10),
  overflow: "hidden",
  boxShadow: `${theme.color.backgroundColor} 0px 5px 5px;`,
  border: `1px solid ${designToken.colorBorderSecondary}`,

  ["&:hover"]: {
    [`${ExternalLink}`]: {
      opacity: 1,
    },
  },
});

const AvatarWrapper = styled(FlexBox)({
  paddingLeft: rem(10),
  gap: rem(5),
});

const MediaWrapper = styled.div<{ link: boolean }>((link) => ({
  position: "relative",
  cursor: link ? "pointer" : "default",
  height: size,

  "&:hover": {
    opacity: link ? 0.8 : 1,
  },
}));

const Video = styled.video({
  width: size,
  height: size,
  objectFit: "cover",
  borderRadius: rem(10),
});

const Timestamp = styled(Paragraph)({
  position: "absolute",
  bottom: 0,
  right: 0,
  textAlign: "right",
  color: theme.color.whiteColor,
  background: "rgba(0, 0, 0, 0.6)",
  borderRadius: `${rem(10)} 0`,
  padding: rem(5),
});

const Image = styled.img({
  width: size,
  height: size,
  objectFit: "cover",
  borderRadius: rem(10),
});

const DataWrapper = styled(FlexBox)({
  padding: `0 ${rem(10)}`,
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
});

const Metrics = styled(FlexBox)({
  gap: rem(5),
});

type OutputCardsT = {
  outputs: ExistedOutputT[];
  results: CompetitiveAnalysisResultT[];
};

const OutputCards: FC<React.PropsWithChildren<OutputCardsT>> = ({
  outputs,
  results,
}) => {
  const { setLightBoxState } = useContext(LightBoxContext);
  const { i18n } = useTranslate();
  const medias = outputs.filter((o) => o.media_url);

  const avatarUrl = (handle: string): string | null => {
    const url = results.find((r) => r.handle === handle)?.avatar_url;
    return url ? url : null;
  };

  const openLightbox = (media: ExistedOutputT): void => {
    if (media.media_url) {
      setLightBoxState({ file_urls: [media.media_url] });
    }
  };

  const openLink = (link: string | undefined): void => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const getOutput = (media: ExistedOutputT) => {
    const { owner, media_url, likes, comments } = media;
    const hasCommets = !!(comments && comments > 0);

    return (
      <OutputWrapper key={media.id}>
        <AvatarWrapper>
          <Avatar name={owner} src={avatarUrl(owner)} />
          <BlueLink
            text={`${owner}`}
            href={`https://instagram.com/${owner}`}
            paragraphSize="small"
            target="_blank"
            type="black"
          />
        </AvatarWrapper>
        <MediaWrapper
          link={!!media.permalink}
          onClick={() => openLightbox(media)}
        >
          <Timestamp
            paragraph={formatDate(media.timestamp, i18n)}
            paragraphSize="small"
          />
          {media.media_type.toLowerCase() === "video" ? (
            <Video src={`${media_url}?t=0.3`} />
          ) : (
            <Image src={media_url ? media_url : undefined} />
          )}
        </MediaWrapper>
        <DataWrapper>
          <Metrics>
            <Icon icon={IconTypeE.heart} color={theme.color.textGreyColor} />
            <Paragraph paragraph={`${likes}`} paragraphSize="small" />
            {hasCommets && (
              <>
                <Icon
                  icon={IconTypeE.chatBubble}
                  color={theme.color.textGreyColor}
                />
                <Paragraph paragraph={`${comments}`} paragraphSize="small" />
              </>
            )}
          </Metrics>
          {media.permalink && (
            <ExternalLink
              icon={IconTypeE.linkExternal}
              color={theme.color.primaryColor}
              size="small"
              onClick={() => openLink(media.permalink)}
            />
          )}
        </DataWrapper>
      </OutputWrapper>
    );
  };

  return (
    <OutputsWrapper>{medias.map((media) => getOutput(media))}</OutputsWrapper>
  );
};

export default OutputCards;
