import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { CustomWidthE } from "../../../../components/FlexBox";
import PositionWrapper from "../../../../ui/cards-ui/PositionWrapper";
import { ClearCarouselWrapper } from "../../../../ui/carousel/ClearCarouselWrapper";
import { MediaGroupsHelperContext } from "../../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupT } from "../../types";
import MediaCard from "./card/MediaCard";
import StorySetHandles from "./card/StorySetHandles";
import { MediaGroupToRemoveStateT } from "./MediaGoupsContainer";

type MediaGroupStorySetT = {
  mediaGroup: MediaGroupT;
  mediaGroupToRemove: MediaGroupToRemoveStateT | undefined;
  actionsVisible: boolean;
  liveAddedMediaGroup?: boolean;
};

const MediaGroupStorySet: FC<React.PropsWithChildren<MediaGroupStorySetT>> = ({
  mediaGroup,
  mediaGroupToRemove,
  actionsVisible,
  liveAddedMediaGroup,
}) => {
  const { cardWidth } = useContext(MediaGroupsHelperContext);

  const { medias } = mediaGroup;
  const [mediaToRemoveInSet, setMediaToRemoveInSet] = useState(false);
  const [currentCard, setCurrentCard] = useState({
    index: 0,
    id: medias[0].id,
  });

  useEffect(() => {
    if (mediaGroupToRemove) {
      const inSet =
        medias.findIndex(
          (item) => item.id === mediaGroupToRemove.mediaGroupId,
        ) > -1;

      if (inSet) {
        setMediaToRemoveInSet(true);
        return;
      }

      setMediaToRemoveInSet(false);
    }
  }, [mediaGroupToRemove]);

  useEffect(() => {
    if (!mediaGroupToRemove && mediaToRemoveInSet) {
      if (medias.find((item) => item.id !== currentCard.id)) {
        setCurrentCard((prevState) => {
          const newIndex = prevState.index > 0 ? prevState.index - 1 : 0;
          return {
            index: newIndex,
            id: medias[newIndex].id,
          };
        });

        return;
      }

      setCurrentCard({
        index: 0,
        id: medias[0].id,
      });
    }
  }, [mediaGroupToRemove]);

  return (
    <PositionWrapper cardWidth={cardWidth}>
      <StorySetHandles
        mediaGroup={mediaGroup}
        currentCard={currentCard}
        liveAddedMediaGroup={liveAddedMediaGroup}
        setCurrentCard={setCurrentCard}
        removeVisible={actionsVisible}
      />
      <ClearCarouselWrapper
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
        fullHeight={true}
      >
        <Carousel
          showArrows={false}
          showThumbs={false}
          emulateTouch={false}
          selectedItem={currentCard.index}
          onChange={(index) => setCurrentCard({ index, id: medias[index].id })}
        >
          {medias
            .sort((a, b) =>
              (a.published_at || a.created_at).localeCompare(
                b.published_at || b.created_at,
              ),
            )
            .map((media, index) => (
              <MediaCard
                key={media.id}
                media={media}
                mediaGroup={mediaGroup}
                cardStorySizing={true}
                liveAddedMedia={liveAddedMediaGroup}
                storyCounter={`${index + 1}/${medias.length}`}
                isStorySet={true}
              />
            ))}
        </Carousel>
      </ClearCarouselWrapper>
    </PositionWrapper>
  );
};
export default MediaGroupStorySet;
