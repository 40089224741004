import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Panel from "../../../../../components/Panel";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import CheckBox from "../../../../../ui/CheckBox";
import RadioButton from "../../../../../ui/RadioButton";
import { getBrandAffinities } from "../../../data-access/getBrandAffinities";
import { BrandAffinityT } from "../types";
import AffintyTable from "./AffinityTable";

const PanelHead = styled(FlexBox)({
  padding: `${rem(24)} ${rem(16)} ${rem(20)}`,
  borderBottom: `1px solid ${theme.color.greyColor}`,

  [theme.media.maxSm]: {
    flexDirection: "column",
  },
});

const FiltersWrapper = styled(FlexBox)({
  [theme.media.maxSm]: {
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: rem(16),
  },
});

const Divider = styled.div({
  width: rem(2),
  height: rem(30),
  backgroundColor: theme.color.greyColor,

  [theme.media.maxSm]: {
    width: rem(30),
    height: rem(2),
    margin: `${rem(5)} 0`,
  },
});

type PastDaysT = 90 | 180 | 365;

type BrandAffinityPropsT = {
  networkProfileId: string;
};

const BrandAffinity: FC<React.PropsWithChildren<BrandAffinityPropsT>> = ({
  networkProfileId,
}) => {
  const [affinities, setAffinities] = useState<BrandAffinityT[] | null>(null);
  const [filteredBrands, setFilteredBrands] = useState<BrandAffinityT[] | null>(
    null,
  );
  const [pastDays, setPastDays] = useState<PastDaysT>(365);
  const [loaded, setLoaded] = useState(false);
  const [paidPartnership, setPaidPartnership] = useState(false);

  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity",
  );

  const { refetch } = useQuery(
    `${QueryKeys.NETWORK_PROFILE_BRAND_AFFINITY}-${pastDays}`,
    () => getBrandAffinities(networkProfileId, pastDays),
    {
      onSuccess: (data) => {
        setAffinities(data);

        setFilteredBrands(filterPaidPartnership(paidPartnership, data));

        setLoaded(true);
      },
    },
  );

  const filterPaidPartnership = (
    paidPartnership: boolean,
    brand_affinities: BrandAffinityT[],
  ): BrandAffinityT[] => {
    if (paidPartnership) {
      const newAffinities = brand_affinities.map((item) => {
        const affinity: BrandAffinityT = {
          ...item,
          medias: item.medias.filter((media) => media.paid_partnership),
        };

        return affinity;
      });

      return newAffinities.filter((item) => item.medias.length > 0);
    }

    return brand_affinities;
  };

  useEffect(() => {
    if (loaded) {
      refetch();
    }
  }, [pastDays]);

  const pastDaysData: Record<PastDaysT, string> = {
    90: t("last_90"),
    180: t("last_180"),
    365: t("last_year"),
  };

  const handlePaidPartnership = (): void => {
    const paid = !paidPartnership;
    setPaidPartnership(paid);
    affinities && setFilteredBrands(filterPaidPartnership(paid, affinities));
  };

  return (
    <Panel flexDirection="column" padding={rem(8)}>
      <PanelHead justifyContent="space-between" customWidth={CustomWidthE.full}>
        <FlexBox
          customWidth={CustomWidthE.full}
          flexDirection="column"
          alignItems="flex-start"
        >
          <Heading
            headingType={HeadingTypeT.h4}
            heading={t("label")}
            spaceBottom="micro"
          />
          <Spacer size="micro" direction="vertical" />
          <Paragraph
            paragraph={t("popover")}
            color={theme.color.textGreyColor}
          />
        </FlexBox>
        <FiltersWrapper>
          <CheckBox
            active={paidPartnership}
            onClickHandle={handlePaidPartnership}
          >
            {t("paid")}
          </CheckBox>
          <Spacer size="small" />
          <Divider />
          <Spacer size="small" />
          <FlexBox>
            {t("last")}
            {Object.keys(pastDaysData).map((item, index) => {
              const current = item as unknown as PastDaysT;

              return (
                <FlexBox key={index}>
                  <Spacer size="micro" />
                  <Button
                    size={ButtonSizeE.micro}
                    type={ButtonTypeE.clear}
                    onClick={() => setPastDays(current)}
                  >
                    <RadioButton active={pastDays == current} />
                    <Spacer size="micro" />
                    {pastDaysData[current]}
                  </Button>
                </FlexBox>
              );
            })}
          </FlexBox>
        </FiltersWrapper>
      </PanelHead>
      <AffintyTable affinities={filteredBrands} />
    </Panel>
  );
};

export default BrandAffinity;
