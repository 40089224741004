import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { AnimationOverlay, Line } from "../PlaceholderLoader";

const CampaignCardCover = styled(FlexBox)({
  height: "65%",
  backgroundColor: theme.color.greyLightColor,
});

const CampaignCardContent = styled(FlexBox)({
  height: "35%",
  padding: rem(16),
});

const Wrapper = styled(FlexBox)<{ perRow: number }>(({ perRow }) => ({
  position: "relative",
  width: `calc(${100 / perRow}% - ${rem(8)})`,
  margin: rem(4),
  height: rem(350),
  border: `1px solid ${theme.color.greyLightColor}`,
  backgroundColor: theme.color.whiteColor,
  borderRadius: rem(8),
  overflow: "hidden",

  [theme.media.maxLg]: {
    width: "32%",
  },

  [theme.media.maxSm]: {
    width: "49%",
  },

  [theme.media.maxS]: {
    width: "100%",
    margin: 0,
  },
}));

const CampaignCardPlaceholder: FC<
  React.PropsWithChildren<{ perRow: number }>
> = ({ perRow }) => (
  <Wrapper perRow={perRow}>
    <AnimationOverlay />
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
    >
      <CampaignCardCover customWidth={CustomWidthE.full}>
        <Icon
          icon={IconTypeE.image}
          size={100}
          color={theme.color.whiteColor}
        />
      </CampaignCardCover>
      <CampaignCardContent
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Line />
        <Line />
        <Line />
      </CampaignCardContent>
    </FlexBox>
  </Wrapper>
);

export default CampaignCardPlaceholder;
