import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import Shield from "../../../../../assets/fb-approval/icons/shield.png";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import AccessToData from "../../../ui/AccessToData";
import BasicCard from "../../../ui/BasicCard";
import ColourWrapper from "../../../ui/ColourWrapper";
import Footer from "../../../ui/Footer";
import { Heading } from "../../../ui/Header";
import { Item, List } from "../../../ui/List";
import Modal from "../../../ui/Modal";
import TextWrapper from "../../../ui/TextWrapper";

const ImgShield = styled.img`
  position: absolute;
  right: 0;
  top: ${rem(24)};
  height: ${rem(56)};
  width: auto;
  ${theme.media.lg} {
    position: inherit;
    right: ${rem(84)};
    top: auto;
    height: auto;
  }
`;

const Security: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate("influencer_root.approval_request.security");
  const [isOpen, setIsOpen] = useState(false);

  const cardList = [t("reasons.one"), t("reasons.two"), t("reasons.three")];

  return (
    <>
      <Heading>{t("title")}</Heading>
      <ColourWrapper
        title={t("box.title")}
        text={t("box.text")}
        linkTitle={t("box.link")}
        linkHandle={() => setIsOpen(true)}
        img={<ImgShield src={Shield} alt="shield" />}
      />
      <TextWrapper
        title={t("description.title")}
        text={t("description.text")}
      />
      <List>
        {cardList.map((item) => (
          <Item key={item}>
            <BasicCard text={item} />
          </Item>
        ))}
      </List>
      <Footer />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <AccessToData />
      </Modal>
    </>
  );
};

export default Security;
