import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Paragraph from "../../../components/Paragraph";
import { rem } from "../../../styling/theme";

const Wrapper = styled.div`
  padding: 26px 0;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const Text = styled.div`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(28)};
`;

interface TextWrapperContent {
  title: string;
  text: string;
}

const TextWrapper: FC<React.PropsWithChildren<TextWrapperContent>> = ({
  title,
  text,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>
        <Paragraph paragraph={text} />
      </Text>
    </Wrapper>
  );
};

export default TextWrapper;
