import styled from "@emotion/styled";
import Paragraph from "components/Paragraph";
import { SubscriptionT } from "entry-files/types";
import { designToken } from "helpers/antDesign";
import { formatCurrency } from "helpers/formaters";
import { useTranslate } from "hooks/useTranslate";
import * as React from "react";
import { rem } from "styling/theme";

const Wrapper = styled.div({
  marginTop: rem(-6),
  marginBottom: rem(-6),
});
const Table = styled.div({
  display: "table-row",
});
const Row = styled.div({
  display: "table-row",
});
const Cell = styled(Paragraph)({
  display: "table-cell",
  paddingTop: rem(6),
  paddingBottom: rem(6),
});
const CellRight = styled(Paragraph)({
  display: "table-cell",
  textAlign: "right",
  paddingLeft: rem(44),
  paddingTop: rem(6),
  paddingBottom: rem(6),
  marginTop: rem(20),
});
const CellTotal = styled(Cell)({
  borderTop: `${rem(1)} solid ${designToken.gray9}`,
});
const CellRightTotal = styled(CellRight)({
  borderTop: `${rem(1)} solid ${designToken.gray9}`,
});

type PropsT = {
  subscription: SubscriptionT;
};

const TotalCosts = ({ subscription }: PropsT) => {
  const { t } = useTranslate("brands.manage_subscription.total_costs");
  const { currency_code, total_price, items } = subscription;
  const plan = items?.find((item) => item.item_type === "plan");
  const addons = items ? items.filter((item) => item.item_type !== "plan") : [];

  if (!currency_code || !plan) {
    return null;
  }

  return (
    <Wrapper>
      <Table>
        <Row>
          <Cell
            color={designToken.colorWhite}
            paragraph={t("fee")}
            paragraphSize="small"
          />
          <CellRight
            color={designToken.colorWhite}
            paragraph={formatCurrency(plan.amount ?? 0, currency_code, {
              valueInCents: true,
            })}
            paragraphSize="small"
          />
        </Row>
        {addons.map((addon) => {
          const translationKey =
            addon.entitlement?.feature_id.replaceAll("-", "_") || "";
          return (
            <Row key={addon.item_price_id}>
              <Cell
                color={designToken.colorWhite}
                paragraph={`+${t(translationKey, { count: addon.entitlement?.value })}`}
                paragraphSize="small"
              />
              <CellRight
                color={designToken.colorWhite}
                paragraph={formatCurrency(addon.amount, currency_code, {
                  valueInCents: true,
                })}
                paragraphSize="small"
              />
            </Row>
          );
        })}
        <Row>
          <CellTotal
            color={designToken.colorWhite}
            paragraph={t("total")}
            paragraphSize="small"
          />
          <CellRightTotal
            color={designToken.colorWhite}
            fontWeight={600}
            paragraph={formatCurrency(total_price, currency_code, {
              valueInCents: true,
            })}
            paragraphSize="small"
          />
        </Row>
      </Table>
    </Wrapper>
  );
};

export default TotalCosts;
