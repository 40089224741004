import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import FlexBox from "../../../components/FlexBox";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { NavigationKeyE, useRouterNavigate } from "../router";

const CompanyRoot: FC<React.PropsWithChildren<unknown>> = () => {
  const { session, isActiveSubscription } = useContext(ApplicationContext);
  const navigate = useRouterNavigate();

  const isWizardDone = !!session?.name;

  const redirect = (): void => {
    if (isActiveSubscription() && !isWizardDone) {
      window.location.href = "/profile/edit";
    } else {
      if (session?.subscription) {
        navigate(NavigationKeyE.Subscription);
      } else {
        navigate(NavigationKeyE.Wizard, "personal-details", {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    if (!(isActiveSubscription() && isWizardDone)) {
      redirect();
    }
  }, []);

  return (
    <>
      {isActiveSubscription() && isWizardDone && (
        <FlexBox>
          <Outlet />
        </FlexBox>
      )}
    </>
  );
};

export default CompanyRoot;
