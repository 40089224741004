import * as React from "react";
import { FC } from "react";
import Avatar from "../../../../components/Avatar";
import Button from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import { useTranslate } from "../../../../hooks/useTranslate";
import { ApprovalRequestWithAuthorT } from "../../../types";
import ColourWrapper from "../../ui/ColourWrapper";
import Footer from "../../ui/Footer";
import { Heading } from "../../ui/Header";

type ProcessedApprovalT = {
  approvalRequest: ApprovalRequestWithAuthorT;
  influencerHandle: string;
  url: string;
  content: string;
  button_title: string;
};

const ProcessedApproval: FC<React.PropsWithChildren<ProcessedApprovalT>> = ({
  approvalRequest,
  influencerHandle,
  url,
  content,
  button_title,
}) => {
  const { t } = useTranslate("influencer_root.approval_request");
  const {
    avatar_url,
    network_profile: { profile_id },
  } = approvalRequest;

  return (
    <>
      <Heading>{t("processed.title")}</Heading>
      <ColourWrapper
        title={t("hi", { name: influencerHandle })}
        text={content}
        img={
          <Avatar
            size={104}
            name={profile_id}
            src={avatar_url?.length ? avatar_url : null}
          />
        }
      >
        <Button>{t("login")}</Button>
      </ColourWrapper>
      <Footer>
        <FlexBox justifyContent="flex-end">
          <Button href={`${url}`}>{button_title}</Button>
        </FlexBox>
      </Footer>
    </>
  );
};

export default ProcessedApproval;
