import styled from "@emotion/styled";
import { Button, Form, FormProps, Input, Select } from "antd";
import { countries } from "jsvat";
import * as React from "react";
import { FC, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { updateSessionMutation } from "../../../../data-access/mutations/updateSessionMutation";
import { designToken } from "../../../../helpers/antDesign";
import { validateEmptyString } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { activateTrial } from "../../data-access/activateTrial";
import { NavigationKeyE, useRouterNavigate } from "../../router";

const img = require("../../../../assets/personal-details-bg.png");

const Wrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "50%",
  padding: rem(64),
  height: "100%",
  gap: rem(32),

  [theme.media.maxMd]: {
    width: "100%",
  },
});

const ImageWrapper = styled(ContentWrapper)({
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.24),
  borderRadius: `0 ${rem(designToken.borderRadiusLG)} ${rem(
    designToken.borderRadiusLG,
  )} 0`,

  [theme.media.maxMd]: {
    display: "none",
  },
});

const Image = styled(FlexBox)({
  backgroundImage: `url("${img}")`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundPositionY: "center",
  width: rem(335),
  height: rem(380),
});

type OptionT = {
  label: string;
  value: string;
};

type FieldType = {
  name: string;
  company: string;
  country: string;
  countryCode: string;
  position: string;
  campaingsCount: string;
};

const PersonalDetails: FC<React.PropsWithChildren<unknown>> = () => {
  const { session } = useContext(ApplicationContext);

  const countriesOptions: OptionT[] = countries.map((country) => {
    return { value: country.codes[0], label: country.name };
  });

  const navigate = useRouterNavigate();

  const { openToast } = useContext(ToastContext);
  const { t } = useTranslate("brands.wizard.personal_details");

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (params: FieldType) =>
      activateTrial(
        params.name,
        params.company,
        params.position,
        params.campaingsCount,
        {
          country_code: params.countryCode,
        },
      ),
    {
      onSuccess: (data) => {
        updateSessionMutation(queryClient, data);
        setTimeout(() => {
          navigate(NavigationKeyE.Database);
        }, 200);
        openToast({
          type: "success",
          message: t("messages.success_trial", {
            link: NavigationKeyE.Subscription,
          }),
        });
      },
    },
  );

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    mutate(values);
  };

  return (
    <Wrapper>
      <>
        <ContentWrapper>
          <FlexBox flexDirection="column" gap={rem(16)}>
            <Paragraph paragraph={t("title")} />
            <Paragraph paragraph={t("description")} fontWeight={600} />
          </FlexBox>
          <FlexBox flexDirection="column" customWidth="100%">
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              style={{ width: "100%" }}
              initialValues={{
                name: session?.name,
                company: session?.brand?.company_name,
                countryCode: session?.brand?.billing_address?.country_code,
                position: session?.position,
                campaingsCount: session?.brand?.campaigns_count,
              }}
              onFinish={onFinish}
              autoComplete="off"
              requiredMark="optional"
            >
              <Form.Item<FieldType>
                label={t("form.name")}
                name="name"
                rules={[
                  { required: true, message: t("form.errors.base") },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label={t("form.company")}
                name="company"
                rules={[
                  { required: true, message: t("form.errors.base") },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label={t("form.position")}
                labelCol={{}}
                name="position"
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label={t("form.country")}
                name="countryCode"
                rules={[
                  { required: true, message: t("form.errors.base") },
                  () => ({
                    validator: (_rule, value) =>
                      validateEmptyString(value, t("form.errors.format")),
                  }),
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{ width: "100%" }}
                  options={countriesOptions}
                />
              </Form.Item>

              <Form.Item<FieldType>
                label={t("form.campaings_count")}
                labelCol={{}}
                name="campaingsCount"
              >
                <Select
                  options={[
                    { value: "0-5", label: "0-5" },
                    { value: "5-20", label: "5-20" },
                    { value: "20+", label: "20+" },
                  ]}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  {t(session?.subscription ? "form.save" : "form.start")}
                </Button>
              </Form.Item>
            </Form>
          </FlexBox>
        </ContentWrapper>
        <ImageWrapper>
          <Image />
        </ImageWrapper>
      </>
    </Wrapper>
  );
};

export default PersonalDetails;
