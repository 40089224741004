import { SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Select, Spin } from "antd";
import { debounce } from "lodash";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import AvatarTile from "../../../../../components/AvatarTile";
import FlexBox from "../../../../../components/FlexBox";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { NetworkProviderT } from "../../../../types";
import { getDictionaryUsers } from "../../../data-access/getDictionaryUsers";
import { NavigationKeyE } from "../../../router";
import { DictionaryUserT } from "../../../types";
import { AnalyzeContext } from "../context-providers/AnalyzeContextProvider";

const { Option } = Select;

const OptionInnerWrapper = styled(FlexBox)({
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
});

const Spinner: FC<React.PropsWithChildren<unknown>> = () => (
  <FlexBox customWidth="100%">
    <Spin />
  </FlexBox>
);

type SearchT = {
  provider: NetworkProviderT;
  options: DictionaryUserT[];
  setOptions: Dispatch<SetStateAction<DictionaryUserT[]>>;
};

const Search: FC<React.PropsWithChildren<SearchT>> = ({
  provider,
  options,
  setOptions,
}) => {
  const [term, setTerm] = useState<string>("");
  const [open, setOpen] = useState(false);

  const { analyze, analyzeButton, analyzeTooltip } = useContext(AnalyzeContext);

  const { t } = useTranslate("brands.influencer_database");

  const { refetch, isRefetching } = useQuery(
    `${QueryKeys.DICTIONARY_USERS}-${provider}`,
    () => getDictionaryUsers(term, provider),
    {
      enabled: false,
      onSuccess: (data) => setOptions(data),
    },
  );

  const onViewHandle = (option: DictionaryUserT): void => {
    if (option.network_profile_id === null) return;

    window.open(
      `${window.location.origin}${NavigationKeyE.Database}/${option.network_profile_id}/overview`,
      "_blank",
    );
  };

  const handleOnSelect = (id: string): void => {
    const selected = options.find((option) => option.user_id === id);
    if (!selected) return;

    if (selected.available) {
      onViewHandle(selected);
    } else {
      analyze({
        handle: provider === "youtube" ? selected.user_id : selected.username,
        platform: provider,
      });
    }
  };

  const row = (option: DictionaryUserT) => {
    const item = (
      <OptionInnerWrapper>
        <AvatarTile
          key={option.user_id}
          imageUrl={option.picture}
          handle={option.username}
          name={option.fullname}
          justifyContent="flex-start"
          paragraphSize="small"
        />
        {option.available ? (
          <Button style={{ width: rem(100) }}>{t("search.view")}</Button>
        ) : (
          analyzeButton(
            provider === "youtube" ? option.user_id : option.username,
            100,
            undefined,
            "analyze",
            false,
          )
        )}
      </OptionInnerWrapper>
    );

    return option.available ? item : analyzeTooltip(item, "right");
  };

  const search = useRef(debounce(() => refetch(), 500)).current;
  useEffect(() => {
    if (term && term.length > 2) {
      search();
    }
  }, [term]);

  return (
    <>
      <Select
        showSearch
        value={term}
        open={open}
        placeholder={t("search.placeholder")}
        notFoundContent={null}
        filterOption={false}
        onSearch={setTerm}
        onSelect={(value) => handleOnSelect(value)}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        suffixIcon={<SearchOutlined rev="" />}
        style={{ width: "100%" }}
        dropdownRender={(list) => (
          <>
            <div>{isRefetching ? <Spinner /> : list}</div>
          </>
        )}
      >
        {options.length > 0 &&
          options.map((option) => (
            <Option key={option.user_id} value={option.user_id}>
              {row(option)}
            </Option>
          ))}
      </Select>
    </>
  );
};

export default Search;
