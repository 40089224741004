import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../components/FlexBox";
import {
  FileTypeE,
  getFileExtension,
  getFileType,
} from "../../helpers/fileType";
import { useCardCoverHeight } from "../../hooks/useCardCoverHeight";
import { rem, theme } from "../../styling/theme";
import Icon, { IconTypeE } from "../icons/Icon";

type CoverStyleT = {
  image?: string;
  height: number;
};

const Wrapper = styled(FlexBox)<CoverStyleT>(({ image, height }) => ({
  position: "relative",
  width: "100%",
  height: rem(height),
  backgroundImage: image ? `url('${image}')` : "none",
  backgroundColor: image ? "transparent" : theme.color.greyLightColor,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  border: image ? "none" : `4px solid ${theme.color.whiteColor}`,
  borderRadius: image ? "none" : "8px",
  transition: "height 0.2s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
}));

const VideoThumb = styled.video({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
});

const Labels = styled(FlexBox)({
  position: "absolute",
  bottom: rem(12),
  left: rem(12),
});

const Label = styled(FlexBox)({
  marginTop: rem(4),
  padding: `${rem(4)} ${rem(8)}`,
  fontSize: rem(12),
  fontWeight: 600,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: rem(4),
});

type CardCoverT = {
  image?: string;
  onClick?: () => void;
  labels?: string[];
  wrapperWidth?: number;
  defaultHeight?: number;
  aspectRatio?: number[];
};

const CardCover: FC<React.PropsWithChildren<CardCoverT>> = ({
  image,
  onClick,
  labels,
  wrapperWidth,
  defaultHeight = 280,
  aspectRatio = [4, 3],
}) => {
  const { coverHeight } = useCardCoverHeight(
    wrapperWidth,
    defaultHeight,
    aspectRatio,
  );

  const fileType = image && getFileType(getFileExtension(image));

  return (
    <Wrapper onClick={onClick} image={image} height={coverHeight}>
      {fileType && fileType === FileTypeE.video && (
        <VideoThumb src={`${image}#t=0.1`} />
      )}
      {!image && (
        <Icon
          icon={IconTypeE.eyeStrike}
          color={theme.color.textLightGreyColor}
          size="big"
        />
      )}
      {labels && (
        <Labels flexDirection="column" alignItems="flex-start">
          {labels.map((label, index) => (
            <Label key={index}>{label}</Label>
          ))}
        </Labels>
      )}
    </Wrapper>
  );
};

export default CardCover;
