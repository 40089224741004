import { _o } from "./helpers";

type handleTriggerT = (trigger: HTMLElement, force?: "close") => void;
const handleTrigger: handleTriggerT = (trigger, force) => {
  const triggeredClassName = "basic-trigger--triggered";
  if (force === "close" || trigger.classList.contains(triggeredClassName)) {
    trigger.classList.remove(triggeredClassName);

    return;
  }

  trigger.classList.add(triggeredClassName);
};

export const basicTriggerInit = (): void => {
  const basicTriggers = document.getElementsByClassName("basic-trigger");
  const basicTriggersDisable = document.getElementsByClassName(
    "basic-trigger-disable",
  );

  if (basicTriggers) {
    _o(basicTriggers).forEach((trigger) => {
      const triggerElement = trigger as HTMLDivElement;

      if (!triggerElement.dataset.mounted) {
        triggerElement.dataset.mounted = "TRUE";

        trigger.addEventListener("click", () =>
          handleTrigger(trigger as HTMLElement),
        );
      }
    });
  }

  if (basicTriggersDisable) {
    _o(basicTriggersDisable).forEach((trigger) => {
      trigger.addEventListener("click", () => {
        const disableElement = trigger as HTMLElement;
        const disableTrigger = disableElement.dataset.trigger;

        const targetTrigger =
          disableTrigger && document.getElementById(disableTrigger);

        if (targetTrigger) {
          handleTrigger(targetTrigger, "close");
        }
      });
    });
  }
};
