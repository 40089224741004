import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import { AnimationOverlay, Avatar } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(16),
});

const ChatMessageWrapper = styled(FlexBox)({
  width: "50%",
  marginBottom: rem(20),
});

const Bubble = styled.div({
  width: "100%",
  height: rem(75),
  backgroundColor: theme.color.greyLightColor,
  borderRadius: rem(16),
});

const ChatMessagePlaceholder: FC<
  React.PropsWithChildren<{ count: number; index: number }>
> = ({ count, index }) => {
  const [randomNumbers] = useState<boolean[]>(
    [...Array(count)].map(() => Math.random() < 0.5),
  );

  return (
    <Wrapper customWidth={CustomWidthE.full}>
      <FlexBox
        customWidth={CustomWidthE.full}
        justifyContent={randomNumbers[index] ? "flex-start" : "flex-end"}
      >
        <ChatMessageWrapper>
          <AnimationOverlay />
          <FlexBox customWidth={CustomWidthE.full} alignItems="flex-end">
            {randomNumbers[index] && <Avatar size={40} />}
            <Bubble />
          </FlexBox>
        </ChatMessageWrapper>
      </FlexBox>
    </Wrapper>
  );
};

export default ChatMessagePlaceholder;
