import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";
import { AnimationOverlay, Avatar, Line } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(8),
  padding: rem(32),
  backgroundColor: theme.color.whiteColor,
  overflow: "hidden",
});

const ApprovalHeaderPlaceholder: FC<React.PropsWithChildren<{}>> = () => (
  <Wrapper>
    <FlexBox customWidth={CustomWidthE.full}>
      <AnimationOverlay />
      <FlexBox
        customWidth={CustomWidthE.full}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Avatar size={200} />
        <Spacer size="big" />
        <FlexBox
          customWidth={CustomWidthE.half}
          alignItems="flex-start"
          flexDirection="column"
        >
          <Line />
          <Line />
          <Line last={true} />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  </Wrapper>
);

export default ApprovalHeaderPlaceholder;
