import { I18n, Scope, TranslateOptions } from "i18n-js";
import { useContext } from "react";
import {
  TranslationsContext,
  TranslationsLocaleT,
} from "../context-providers/TranslationsContextProvider";

type useTranslateReturnT = {
  t: (scope: Scope, options?: TranslateOptions) => string;
  locale: TranslationsLocaleT;
  i18n: I18n;
};

type useTranslateT = (path?: string) => useTranslateReturnT;
export const useTranslate: useTranslateT = (path) => {
  const { i18n } = useContext(TranslationsContext);

  return {
    t: path ? (key, options) => i18n.t(`${path}.${key}`, options) : i18n.t,
    locale: i18n.locale as TranslationsLocaleT,
    i18n: i18n,
  };
};
