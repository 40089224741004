export const optionsCredibleFollowers = {
  responsive: true,
  cutout: 55,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    tooltip: {
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label: ({ formattedValue, label }: any) =>
          `${label}: ${formattedValue}%`,
      },
    },
  },
};
