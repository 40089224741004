import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { hexToRgb, rem, theme } from "../styling/theme";
import {
  LogoLetterDotIcon,
  LogoLetterIcon,
  LogoTextIcon,
} from "../ui/icons/CustomIcons";
import Icon, { IconTypeE } from "../ui/icons/Icon";
import { Overlay } from "../ui/Overlay";
import FlexBox from "./FlexBox";

const openMenuWidth = 200;

const StyledLogoLetterIcon = styled(LogoLetterIcon)({
  display: "none",
});

const StyledLogoLetterDotIcon = styled(LogoLetterDotIcon)({
  display: "block",
});

const LogoWrapper = styled(FlexBox)({
  marginLeft: rem(9),
  cursor: "pointer",
});

const StyledLogoText = styled(LogoTextIcon)({
  visibility: "hidden",
  margin: `${rem(2)} ${rem(0)} ${rem(0)} ${rem(2)}`,
});

const Close = styled(FlexBox)({
  display: "none",
  width: rem(40),
  height: rem(40),
  position: "absolute",
  top: 0,
  left: rem(openMenuWidth),
  borderRadius: `0 ${rem(8)} ${rem(8)} 0`,
  backgroundColor: theme.color.whiteColor,
  cursor: "pointer",
  zIndex: 32,
});

const Wrapper = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: rem(48),
  height: "100dvh",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  backgroundColor: theme.color.whiteColor,
  padding: `${rem(20)} ${rem(4)} ${rem(4)} ${rem(4)}`,
  borderRight: `1px solid ${theme.color.greyLightColor}`,
  gap: rem(28),
  zIndex: 31,
  overflow: "hidden",

  [theme.media.maxSm]: {
    display: active ? "flex" : "none",
  },
}));

const MobileHeader = styled(FlexBox)({
  display: "none",
  position: "fixed",
  justifyContent: "space-between",
  top: 0,
  left: 0,
  height: rem(64),
  width: "100%",
  padding: rem(16),
  backgroundColor: theme.color.whiteColor,
  boxShadow: `${rem(0)} ${rem(2)} ${rem(4)} 0 ${hexToRgb(
    theme.color.blackColor,
    0.16,
  )}`,

  [theme.media.maxSm]: {
    display: "flex",
  },
});

const OpenMenuWrapper = styled(FlexBox)({
  width: rem(32),
  height: rem(32),
  border: `1px solid ${theme.color.primaryColor}`,
  borderRadius: rem(4),
  cursor: "pointer",
  color: theme.color.primaryColor,

  ":hover": {
    border: `1px solid ${theme.color.lightPrimaryColor}`,
    color: theme.color.lightPrimaryColor,
  },
});

const MenuCheckbox = styled.input({
  display: "none",

  ":checked": {
    [`~ ${Wrapper}`]: {
      width: rem(openMenuWidth),
      borderRight: "none",
      boxShadow: `${rem(4)} ${rem(4)} ${rem(8)} 0 ${hexToRgb(
        theme.color.blackColor,
        0.08,
      )}`,

      [`${StyledLogoText}`]: {
        visibility: "visible",
      },

      [`${StyledLogoLetterIcon}`]: {
        display: "block",
      },

      [`${StyledLogoLetterDotIcon}`]: {
        display: "none",
      },
    },

    [`~ ${Close}`]: {
      [theme.media.maxSm]: {
        display: "flex",
      },
    },

    [`~ ${Overlay}`]: {
      [theme.media.maxSm]: {
        display: "flex",
      },
    },
  },
});

type NavigationContainerT = {
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  navigateHome: () => void;
};

const NavigationContainer: FC<
  React.PropsWithChildren<NavigationContainerT>
> = ({ openMenu, setOpenMenu, navigateHome, children }) => {
  return (
    <>
      <MobileHeader>
        <FlexBox customWidth="33.3%" justifyContent="flex-start">
          <OpenMenuWrapper onClick={() => setOpenMenu(true)}>
            <MenuOutlined style={{ fontSize: rem(16) }} />
          </OpenMenuWrapper>
        </FlexBox>
        <FlexBox customWidth="33.3%" onClick={() => navigateHome()}>
          <Icon icon={IconTypeE.logo} size={112} />
        </FlexBox>
        <FlexBox customWidth="33.3%" />
      </MobileHeader>
      <MenuCheckbox type="checkbox" checked={openMenu} onChange={() => {}} />
      <Overlay zIndex={30} onClick={() => setOpenMenu(false)} />
      <Close onClick={() => setOpenMenu(false)}>
        <CloseOutlined />
      </Close>
      <Wrapper
        onMouseEnter={() => setOpenMenu(true)}
        onMouseLeave={() => setOpenMenu(false)}
        active={openMenu}
      >
        <LogoWrapper onClick={() => navigateHome()}>
          <StyledLogoLetterDotIcon />
          <StyledLogoLetterIcon />
          <StyledLogoText />
        </LogoWrapper>
        {children}
      </Wrapper>
    </>
  );
};

export default NavigationContainer;
