import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import Spacer from "../../../../components/Spacer";
import { rem, theme } from "../../../../styling/theme";
import AvatarWithCircles from "../../../../ui/AvatarWithCircles";

const Wrapper = styled(Panel)({
  flexDirection: "row",

  [theme.media.maxSm]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  [theme.media.maxSm]: {
    padding: rem(16),
  },
});

type InfluencerMainPanelT = {
  handle: string;
  profileImageUrl: string | null;
  disabled?: boolean;
};

const InfluencerMainPanel: FC<
  React.PropsWithChildren<InfluencerMainPanelT>
> = ({ handle, profileImageUrl, disabled = false, children }) => {
  return (
    <Wrapper
      padding={rem(0)}
      alignItems="center"
      justifyContent="flex-start"
      customWidth={CustomWidthE.full}
      overflow="hidden"
    >
      <AvatarWithCircles
        handle={handle}
        profileImageUrl={profileImageUrl}
        disabled={disabled}
        avatarSize="big"
        provider="instagram"
      />
      <Spacer size={"big"} />
      <ContentWrapper fullHeight={true}>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default InfluencerMainPanel;
