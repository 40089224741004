import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Button, { ButtonSizeE } from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import Modal from "../../ui/Modal";

const Wrapper = styled(FlexBox)({
  ["p"]: {
    textAlign: "center",
  },
});

const IconWrapper = styled(FlexBox)({
  padding: rem(20),
  backgroundColor: hexToRgb(theme.color.errorColor, 0.4),
  borderRadius: "50%",
});

const ErrorTitle = styled(Paragraph)({
  padding: `${rem(25)} 0 ${rem(10)} 0`,
});

type MissmatchModalT = {
  approvalRequestId: string;
  currentHandle: string | undefined;
  requestedHandle: string;
};

const MissmatchModal: FC<React.PropsWithChildren<MissmatchModalT>> = ({
  approvalRequestId,
  currentHandle,
  requestedHandle,
}) => {
  const { t } = useTranslate("influencer_root.approval_request");

  return (
    <Modal isOpen={true} headerless={true} size="small" onClose={() => {}}>
      <Wrapper justifyContent="center" flexDirection="column">
        <IconWrapper>
          <Icon
            icon={IconTypeE.cross}
            size="big"
            color={theme.color.errorColor}
          />
        </IconWrapper>
        <ErrorTitle
          paragraph={t("errors.missmatch.title")}
          paragraphSize="large"
        />
        <Paragraph
          paragraph={t("errors.missmatch.text1", {
            wrong: currentHandle,
          })}
        />
        <Spacer direction="vertical" size="mini" />
        <Paragraph
          paragraph={t("errors.missmatch.text2", {
            correct: requestedHandle,
          })}
        />
        <Spacer direction="vertical" />
        <a
          data-method="delete"
          href={`/users/logout?redirect_to=/approval_requests/${approvalRequestId}`}
        >
          <Button size={ButtonSizeE.small}>
            {t("errors.change_account_button")}
          </Button>
        </a>
      </Wrapper>
    </Modal>
  );
};

export default MissmatchModal;
