import { CheckCircleFilled } from "@ant-design/icons";
import { Input } from "antd";
import { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { rem } from "../../../../../styling/theme";
import { SearchQueryGlobalT } from "../types";

const { Search } = Input;

type SerchInputWithClearT = {
  searchQuery: SearchQueryGlobalT;
  setFilterSearchQuery: (query: Partial<SearchQueryGlobalT>) => void;
  attribute: "keywords" | "text";
  t: (scope: Scope, options?: TranslateOptions | undefined) => string;
};

const SerchInputWithClear: FC<
  React.PropsWithChildren<SerchInputWithClearT>
> = ({ searchQuery, setFilterSearchQuery, attribute, t }) => {
  const [keywordValue, setKeywordValue] = useState<string>(
    searchQuery[attribute],
  );

  const onSearchHandle = (value?: string): void => {
    setFilterSearchQuery({
      [attribute]: value === undefined ? keywordValue : value,
    });
  };

  const onClearHandle = (): void => {
    onSearchHandle("");
    setKeywordValue("");
  };

  useEffect(() => {
    setKeywordValue(searchQuery[attribute]);
  }, [searchQuery[attribute]]);

  return (
    <>
      <Search
        value={keywordValue}
        style={{ width: "100%" }}
        placeholder={t("placeholder")}
        onChange={(e) => setKeywordValue(e.target.value)}
        onSearch={onSearchHandle}
        onBlur={() => onSearchHandle()}
      />
      {searchQuery[attribute].length > 0 && (
        <FlexBox gap={rem(8)}>
          <CheckCircleFilled style={{ color: designToken.colorIcon }} />
          <Paragraph
            paragraph={t("searched")}
            paragraphSize="micro"
            color={designToken.colorTextTertiary}
          />
          <BlueLink
            onClick={onClearHandle}
            type="grey"
            text="Clear"
            paragraphSize="micro"
            marginTop={rem(0)}
          />
        </FlexBox>
      )}
    </>
  );
};

export default SerchInputWithClear;
