import * as React from "react";
import { createContext, FC } from "react";
import {
  PermissionT,
  SessionT,
  SubscriptionEntitlementFeaturesT,
} from "../entry-files/types";

type ApplicationContextT = {
  session: SessionT | undefined;
  entitlementValue: (
    featureId: SubscriptionEntitlementFeaturesT,
  ) => string | number | boolean | undefined;
  isActiveSubscription: () => boolean;
  permissions: PermissionT[];
};

export const ApplicationContext = createContext<ApplicationContextT>({
  session: undefined,
  entitlementValue: () => undefined,
  isActiveSubscription: () => false,
  permissions: [],
});

export const ApplicationContextProvider: FC<
  React.PropsWithChildren<{
    session: SessionT;
  }>
> = ({ session, children }) => {
  const permissions: PermissionT[] =
    session?.subscription?.plan === "trial" ? [] : ["database.list.view"];

  const entitlementValue = (
    featureId: SubscriptionEntitlementFeaturesT,
  ): string | number | boolean | undefined => {
    if (session.subscription) {
      const entitlement = session.subscription.entitlements.find(
        (entitlement) => entitlement.feature_id === featureId,
      );

      return entitlement && entitlement.value;
    }

    return undefined;
  };

  const isActiveSubscription = (): boolean => {
    const isActive =
      session?.subscription?.status &&
      ["active", "non_renewing"].includes(session?.subscription?.status);
    return !!isActive;
  };

  return (
    <ApplicationContext.Provider
      value={{
        session,
        entitlementValue,
        isActiveSubscription,
        permissions,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
