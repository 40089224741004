import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { rem, theme } from "../styling/theme";
import { Overlay } from "../ui/Overlay";
import { defaultSubmenuWidth } from "./Container";
import FlexBox from "./FlexBox";
import FloatingBadgeButton from "./FloatingBadgeButton";

const Close = styled(FlexBox)<{ right: number }>(({ right }) => ({
  display: "none",
  width: rem(40),
  height: rem(40),
  position: "absolute",
  top: 0,
  right: rem(right),
  borderRadius: `${rem(8)} 0 0 ${rem(8)}`,
  backgroundColor: theme.color.whiteColor,
  cursor: "pointer",
  zIndex: 35,
}));

const Wrapper = styled(FlexBox)<{ hidden: boolean; width: number }>(
  ({ hidden, width }) => ({
    justifyContent: "flex-start",
    display: hidden ? "none" : "flex",
    position: "fixed",
    top: 0,
    right: 0,
    flexDirection: "column",
    width: rem(width),
    height: "100dvh",
    margin: 0,
    backgroundColor: theme.color.whiteColor,
    zIndex: 35,

    [theme.media.md]: {
      display: "flex",
      left: rem(48),
      height: "100dvh",
    },

    [`${Close}`]: {
      display: hidden ? "none" : "flex",
    },

    [`~ ${Overlay}`]: {
      display: hidden ? "none" : "flex",
    },
  }),
);

type OverrideHiddenT = {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
};

type FloatingButtonT = {
  title?: string;
  badge?: boolean;
  icon?: React.ReactNode;
};

type SubMenuContainerT = {
  floatingButton?: FloatingButtonT;
  overrideHidden?: OverrideHiddenT;
  width?: number;
};

const SubMenuContainer: FC<React.PropsWithChildren<SubMenuContainerT>> = ({
  floatingButton,
  overrideHidden,
  width = defaultSubmenuWidth,
  children,
}) => {
  const [hidden, setHidden] = useState<boolean>(
    overrideHidden !== undefined ? overrideHidden.value : true,
  );

  const setValues = (value: boolean): void => {
    setHidden(value);
    overrideHidden?.setValue(value);
  };

  useEffect(() => {
    setHidden(overrideHidden !== undefined ? overrideHidden.value : true);
  }, [overrideHidden?.value]);

  return (
    <>
      <Wrapper hidden={hidden} width={width}>
        <Close onClick={() => setValues(true)} right={width}>
          <CloseOutlined />
        </Close>
        {children}
      </Wrapper>
      <Overlay zIndex={34} onClick={() => setValues(true)} />
      <FloatingBadgeButton
        title={floatingButton?.title}
        onClickHandler={() => setValues(false)}
        icon={floatingButton?.icon}
        badge={floatingButton?.badge}
      />
    </>
  );
};

export default SubMenuContainer;
