import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem } from "../../../styling/theme";
import {
  AnimationOverlay,
  Avatar,
  CardWrapper,
  Line,
} from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(16),
  minHeight: rem(70),
  overflow: "hidden",
  alignItems: "normal",
});

type TableItemPlaceholderT = {
  withAvatar?: boolean;
  withBorder?: boolean;
};

const TableItemPlaceholder: FC<
  React.PropsWithChildren<TableItemPlaceholderT>
> = ({ withAvatar = true, withBorder }) => (
  <Wrapper customWidth={CustomWidthE.full}>
    <CardWrapper customWidth={CustomWidthE.full} withBorder={withBorder}>
      <AnimationOverlay />
      <FlexBox customWidth={CustomWidthE.full} alignItems="center">
        {withAvatar && <Avatar size={40} />}
        <FlexBox customWidth={CustomWidthE.full} alignItems="flex-start">
          <Line last={true} noMarginBottom={true} />
          <Line last={true} noMarginBottom={true} />
          <Line last={true} noMarginBottom={true} />
        </FlexBox>
      </FlexBox>
    </CardWrapper>
  </Wrapper>
);

export default TableItemPlaceholder;
