import { Checkbox } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { contentTypes } from "../../../../dataConstants";
import { MediaGroupKindT } from "../../../../types";

type CheckBoxValueT = {
  label: string;
  value: MediaGroupKindT;
};

type FilterPostTypeT = {
  postTypeState: MediaGroupKindT[];
  setPostTypeState: Dispatch<SetStateAction<MediaGroupKindT[]>>;
};

const FilterPostType: FC<React.PropsWithChildren<FilterPostTypeT>> = ({
  postTypeState,
  setPostTypeState,
}) => {
  const { t } = useTranslate("brands.campaign");

  const options: CheckBoxValueT[] = contentTypes.map((type) => ({
    label: t(`labels.${type}`),
    value: type,
  }));

  const onChangeHanle = (values: MediaGroupKindT[]): void => {
    setPostTypeState(values);
  };

  return (
    <>
      <Checkbox.Group value={postTypeState} onChange={onChangeHanle}>
        <FlexBox flexDirection="row" alignItems="flex-start">
          {options.map((option) => (
            <Checkbox key={option.value} value={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </FlexBox>
      </Checkbox.Group>
    </>
  );
};

export default FilterPostType;
