import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Popover, PopoverProps } from "antd";
import { designToken } from "helpers/antDesign";
import * as React from "react";
import { useState } from "react";
import { rem } from "styling/theme";
import Paragraph from "./Paragraph";

type PropsWithTitleT = Omit<PopoverProps, "content"> & {
  title: string;
  content?: undefined;
};

type PropsWithContentT = Omit<PopoverProps, "title"> & {
  content: React.ReactNode;
  title?: undefined;
};

type PropsT = {
  maxWidth?: string;
  minWidth?: string;
};

const style = {
  fontSize: rem(12),
  color: designToken.colorTextTertiary,
  cursor: "pointer",
};

const InfoPopover = ({
  title,
  content,
  onOpenChange,
  maxWidth = rem(260),
  minWidth = "auto",
  ...rest
}: (PropsWithTitleT | PropsWithContentT) & PropsT) => {
  const [active, setActive] = useState(false);

  const handleOnOpenChange = (visible: boolean) => {
    setActive(!active);
    onOpenChange && onOpenChange(visible);
  };

  const getContent = () => {
    if (content) {
      return <div>{content}</div>;
    }

    return (
      <Paragraph
        paragraphSize="small"
        color={designToken.colorTextLightSolid}
        paragraph={title}
      />
    );
  };

  return (
    <Popover
      {...rest}
      color={designToken.colorBgSpotlight}
      onOpenChange={handleOnOpenChange}
      content={getContent()}
      styles={{ root: { maxWidth, minWidth } }}
    >
      {active ? (
        <InfoCircleFilled style={style} />
      ) : (
        <InfoCircleOutlined style={style} />
      )}
    </Popover>
  );
};

export default InfoPopover;
