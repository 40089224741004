import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";

type QuestionBoxVariantType = "primary" | "secondary";

export interface QuestionBoxItemProps {
  header: React.ReactNode;
  content: React.ReactNode;
}

export interface QuestionBoxProps {
  index: number;
  openOnLoad?: boolean;
  variant?: QuestionBoxVariantType;
}

const Wrapper = styled.div<{
  isOpen: boolean;
  variant: QuestionBoxVariantType;
}>`
  border: ${theme.color.borderColor};
  transition: background-color 300ms;
  border-radius: 12px;
  border: 1px solid ${theme.color.borderColor};
  ${({ isOpen, variant }) =>
    (isOpen || variant === "secondary") &&
    `background-color: ${theme.color.backgroundColor};`}
  transition: border 300ms;
  margin-bottom: ${rem(12)};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${theme.color.primaryColor};
  }
`;

const ToggleButton = styled.button`
  position: relative;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  text-align: left;
  cursor: pointer;
  padding: 0;

  &:focus-visible {
    outline: 1px solid ${theme.color.darkPrimaryColor};
  }
`;

const IndicatorWrapper = styled.span<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${rem(26)};
  font-size: 0;
  color: ${theme.color.primaryColor};

  svg {
    fill: currentColor;
    transition: transform 300ms;
    transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : "0")});
  }
`;

const Title = styled.h2`
  margin: 0;
  padding: ${rem(26)} ${rem(64)} ${rem(26)} ${rem(32)};
  font-size: ${rem(16)};
  line-height: ${24 / 16};
  font-weight: 500;
  color: ${theme.color.darkPrimaryColor};
`;

const Content = styled.dd<{ isOpen: boolean }>`
  display: block;
  height: ${({ isOpen }) => (isOpen ? "auto" : 0)};
  overflow: hidden;
  margin: ${({ isOpen }) => (isOpen ? `0 ${rem(32)} ${rem(32)}` : 0)};
`;

const QuestionBox: FC<
  React.PropsWithChildren<QuestionBoxProps & QuestionBoxItemProps>
> = ({ index, content, header, openOnLoad, variant = "primary" }) => {
  const [isOpen, setIsOpen] = useState<boolean>(openOnLoad ?? false);

  return (
    <Wrapper isOpen={isOpen} variant={variant ?? "primary"}>
      <dt>
        <ToggleButton
          aria-controls={`faq-question-${index}`}
          aria-expanded={isOpen}
          type="button"
          id={`faq-answer-${index}`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {typeof header === "string" ? <Title>{header}</Title> : header}
          <IndicatorWrapper isOpen={isOpen}>
            <Icon icon={IconTypeE.chevron} size={26} />
          </IndicatorWrapper>
        </ToggleButton>
      </dt>
      <Content
        isOpen={isOpen}
        aria-labelledby={`faq-answer-${index}`}
        id={`faq-question-${index}`}
      >
        {content}
      </Content>
    </Wrapper>
  );
};

export default QuestionBox;
