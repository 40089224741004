import * as React from "react";
import ReactDOM from "react-dom";
import Navigation from "./Navigation";

export default () => {
  const navigationHook = document.getElementById("navigation-hook");

  return (
    <>
      {navigationHook && ReactDOM.createPortal(<Navigation />, navigationHook)}
    </>
  );
};
