import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Select, Spin } from "antd";
import { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { rem } from "../../../../../styling/theme";
import { QueryIDandWeightT, SearchQueryGlobalT } from "../types";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  gap: rem(10),
  borderRadius: rem(4),
});

const ValueItem = styled(FlexBox)({
  width: "100%",
  justifyContent: "space-between",
  backgroundColor: designToken.colorBgContainerDisabled,
  padding: `${rem(4)} ${rem(7)}`,
  borderRadius: rem(8),
});

type AttributeT = "audience_geo" | "audience_brand_category" | "audience_lang";

type OptionT = {
  id: number | string;
  name: string;
};

type SelectWithAdvancedOptionsT = {
  searchQuery: SearchQueryGlobalT;
  setFilterSearchQuery: (query: Partial<SearchQueryGlobalT>) => void;
  attribute: AttributeT;
  onSelectHandle: (id: number | string | null) => void;
  onDeselectHandle: (id: number | string | null) => void;
  onSearchHandle?: (value: string) => void;
  options: OptionT[];
  t: (scope: Scope, options?: TranslateOptions | undefined) => string;
  isLoading?: boolean;
};

const SelectWithAdvancedOptions: FC<
  React.PropsWithChildren<SelectWithAdvancedOptionsT>
> = ({
  searchQuery,
  setFilterSearchQuery,
  attribute,
  onSelectHandle,
  onDeselectHandle,
  onSearchHandle,
  options,
  t,
  isLoading = false,
}) => {
  const [open, setOpen] = useState(false);

  const percentageArr = [
    1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95,
  ];
  const percentageOptions = percentageArr.map((percentage) => ({
    label: `>${percentage}%`,
    value: percentage,
  }));

  const selectOptions = options.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const onPercentageChange = (
    value: QueryIDandWeightT,
    percentage: number | null,
  ): void => {
    if (percentage === null) {
      return;
    }

    const weight = percentage / 100;
    const updatedCategories =
      attribute === "audience_lang"
        ? { ...searchQuery[attribute], weight: weight.toString() }
        : searchQuery[attribute].map((interest) =>
            interest.id === value.id
              ? { ...interest, weight: weight.toString() }
              : interest,
          );
    setFilterSearchQuery({ [attribute]: updatedCategories });
  };

  const onDropdownSelectHandle = (id: number | string | null): void => {
    onSelectHandle(id);
    setOpen(false);
  };

  const onDropdownDeselectHandle = (id: number | string | null): void => {
    onDeselectHandle(id);
    setOpen(false);
  };

  const advancedOption = (value: QueryIDandWeightT) => {
    const option = options.find(
      (option) =>
        option.id ===
        (typeof option.id === "string" ? value.id : parseInt(value.id)),
    );
    if (!option) return <div key={`${attribute}-empty-${value.id}`}></div>;

    return (
      <ValueItem
        key={`${attribute}-${option.id}`}
        justifyContent="space-between"
        customWidth={CustomWidthE.full}
      >
        <Paragraph paragraph={option.name} paragraphSize="small" />
        <FlexBox gap={rem(8)}>
          <Select
            style={{ width: rem(90) }}
            size="small"
            options={percentageOptions}
            value={parseFloat(value.weight) * 100}
            onChange={(val) => onPercentageChange(value, val)}
          />
          <Button
            style={{ color: designToken.colorText }}
            size="small"
            type="text"
            icon={<CloseOutlined />}
            onClick={() => onDeselectHandle(option.id)}
          />
        </FlexBox>
      </ValueItem>
    );
  };

  return (
    <Wrapper>
      <Select
        showSearch
        mode="multiple"
        value={null}
        open={open}
        style={{ width: "100%" }}
        onSelect={onDropdownSelectHandle}
        onDeselect={onDropdownDeselectHandle}
        onSearch={onSearchHandle}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        options={selectOptions}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        placeholder={t("placeholder")}
        notFoundContent={
          isLoading ? (
            <FlexBox>
              <Spin size="small" />
            </FlexBox>
          ) : null
        }
      />
      {attribute === "audience_lang"
        ? advancedOption({
            id: searchQuery[attribute].code,
            weight: searchQuery[attribute].weight,
          })
        : searchQuery[attribute].map((value) => advancedOption(value))}
    </Wrapper>
  );
};

export default SelectWithAdvancedOptions;
