import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import { getIllustration, IllustrationTypeE } from "../ui/Illustrations";
import FlexBox, { CustomWidthE } from "./FlexBox";
import Paragraph from "./Paragraph";

type IllustrationStyleT = {
  size?: string;
};

export const IllustrationWrapper = styled.div<IllustrationStyleT>(
  ({ size = "100%" }) => ({
    width: size,
    maxWidth: "100%",
    marginBottom: rem(16),
  }),
);

type NoResultsT = {
  message?: string;
  illustration?: IllustrationTypeE;
  illustrationSize?: number;
};

const NoResults: FC<React.PropsWithChildren<NoResultsT>> = ({
  message,
  illustration = IllustrationTypeE.search_fighting,
  illustrationSize = 300,
}) => (
  <FlexBox
    flexDirection="column"
    customWidth={CustomWidthE.full}
    fullHeight={true}
  >
    <IllustrationWrapper size={rem(illustrationSize)}>
      {getIllustration(illustration)}
    </IllustrationWrapper>
    {message && (
      <Paragraph
        paragraph={message}
        align="center"
        color={theme.color.textGreyColor}
      />
    )}
  </FlexBox>
);

export default NoResults;
