import styled from "@emotion/styled";
import { Radio } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";

const Wrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  cursor: "pointer",
  flexWrap: "wrap",
});

const StyledRadio = styled(Radio)({
  alignItems: "center",
});

const RadioParagraph = styled(Paragraph)({
  padding: `0 ${rem(5)}`,
  textWrap: "nowrap",
});

const DescriptionParagraph = styled(Paragraph)({
  textWrap: "nowrap",
});

type RadioItemT = {
  value: string;
  paragraph: string;
  description: string;
  icon: IconTypeE;
};

const RadioItem: FC<React.PropsWithChildren<RadioItemT>> = ({
  value,
  paragraph,
  description,
  icon,
}) => {
  return (
    <StyledRadio value={value}>
      <Wrapper flexDirection="row">
        <Icon icon={icon} size="small" color={theme.color.iconGreyColor} />
        <RadioParagraph paragraphSize="small" paragraph={paragraph} />
        <DescriptionParagraph
          paragraphSize="small"
          paragraph={description}
          color={theme.color.textLightGreyColor}
        />
      </Wrapper>
    </StyledRadio>
  );
};

export default RadioItem;
