import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { GridWrapper } from "../../../../components/GridWrapper";
import Modal from "../../../../components/Modal";
import NoResults from "../../../../components/NoResults";
import Spacer from "../../../../components/Spacer";
import ReferencialCard from "../../../../features/card/ReferencialCard";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import TableContentWrapper from "../../../../ui/table/TableContentWrapper";
import { MediaGroupsHelperContextProvider } from "../../context-providers/MediaGroupsHelperContextProvider";
import { getAddedMediaGroup } from "../../data-access/createMediaGroup";
import { CampaignT, MediaGroupT, QueryStackT } from "../../types";
import CalendarContainer, { DateCalendarData } from "./CalendarContainer";
import MediaContentModal, {
  MediaContentStateT,
} from "./card-cover/MediaContentModal";
import ExistedOutputsModal, {
  ExistedOutputsModalStateT,
} from "./card/ExistedOutputsModal";
import RemoveMediaGroupModal from "./card/RemoveMediaGroupModal";
import RemoveMediaModal from "./card/RemoveMediaModal";
import MediaGroup from "./MediaGroup";

export type MediaGroupToRemoveStateT = {
  campaignId: string;
  mediaGroupId: string;
  removed: boolean;
  queryStack: QueryStackT;
};

export type MediaToRemoveStateT = {
  id: string;
  mediaGroupId: string;
  removed: boolean;
  queryStack: QueryStackT;
};

type MediaGroupsContainerT = {
  queryStack: QueryStackT;
  addedQueryStack: QueryStackT;
  campaign: CampaignT;
  mediaGroups?: MediaGroupT[];
  loading?: boolean;
};

const MediaGroupsContainer: FC<
  React.PropsWithChildren<MediaGroupsContainerT>
> = ({ mediaGroups, campaign, queryStack, addedQueryStack, loading }) => {
  const [removeMediaDisable, setRemoveMediaDisable] = useState<boolean>(false);
  const [referencialCardWidth, setReferencialCardWidth] = useState<number>(0);

  const [existedOutputsState, setExistedOutputsState] = useState<
    ExistedOutputsModalStateT | undefined
  >();
  const [mediaGroupToRemove, setMediaGroupToRemove] = useState<
    MediaGroupToRemoveStateT | undefined
  >();
  const [mediaToRemove, setMediaToRemove] = useState<
    MediaToRemoveStateT | undefined
  >();
  const [mediaContentState, setMediaContentState] = useState<
    MediaContentStateT | undefined
  >();

  const [dateCalendar, setDateCalendar] = useState<
    DateCalendarData | undefined
  >();

  const { data: addedMediaGroups } = useQuery(
    addedQueryStack,
    getAddedMediaGroup,
  );

  const { t } = useTranslate("brands.campaign.outputs");

  const getContent = () => {
    if (loading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.camapign_card}
          count={8}
          direction="row"
          justifyContent="flex-start"
        />
      );
    }

    return (
      <>
        {addedMediaGroups &&
          addedMediaGroups.map((item) => (
            <MediaGroup
              key={item.temporary_id ? item.temporary_id : item.id}
              mediaGroup={item}
              mediaGroupToRemove={mediaGroupToRemove}
              liveAddedMediaGroup={true}
            />
          ))}
        {mediaGroups && mediaGroups.length > 0 ? (
          <>
            {mediaGroups.map((item) => (
              <MediaGroup
                key={item.id}
                mediaGroup={item}
                mediaGroupToRemove={mediaGroupToRemove}
              />
            ))}
          </>
        ) : (
          <TableContentWrapper isWhite={true}>
            <NoResults illustrationSize={350} message={t("empty_title")} />
            <Spacer direction="vertical" />
          </TableContentWrapper>
        )}
      </>
    );
  };

  return (
    <MediaGroupsHelperContextProvider
      cardWidth={rem(referencialCardWidth)}
      campaign={campaign}
      setMediaGroupToRemove={setMediaGroupToRemove}
      setMediaToRemove={setMediaToRemove}
      setMediaContentState={setMediaContentState}
      setExistedOutputsState={setExistedOutputsState}
      dateCalendar={dateCalendar}
      setDateCalendar={setDateCalendar}
      queryStack={queryStack}
      addedQueryStack={addedQueryStack}
    >
      <GridWrapper>
        <ReferencialCard setReferencialCardWidth={setReferencialCardWidth} />
        {getContent()}

        <Modal
          visible={!!mediaGroupToRemove}
          close={() => setMediaGroupToRemove(undefined)}
          slim={true}
          renderChildren={() => (
            <>
              {mediaGroupToRemove && (
                <RemoveMediaGroupModal
                  mediaGroupToRemove={mediaGroupToRemove}
                  setRemoveMediaDisable={setRemoveMediaDisable}
                  isDisabled={removeMediaDisable}
                />
              )}
            </>
          )}
        />

        <Modal
          visible={!!mediaToRemove}
          close={() => setMediaToRemove(undefined)}
          slim={true}
          renderChildren={() => (
            <>
              {mediaToRemove && (
                <RemoveMediaModal
                  mediaToRemove={mediaToRemove}
                  setRemoveMediaDisable={setRemoveMediaDisable}
                  isDisabled={removeMediaDisable}
                />
              )}
            </>
          )}
        />

        <Modal
          visible={!!mediaContentState}
          close={() => setMediaContentState(undefined)}
          contentPadding={rem(4)}
          showCloseButton={false}
          renderChildren={(_, close) => (
            <>
              {!!mediaContentState && (
                <MediaContentModal
                  mediaContentState={mediaContentState}
                  close={close}
                />
              )}
            </>
          )}
        />

        <Modal
          visible={!!existedOutputsState}
          close={() => setExistedOutputsState(undefined)}
          maxHeight={rem(650)}
          contentPadding={rem(24)}
          renderChildren={(_, close) => (
            <>
              {!!existedOutputsState && (
                <ExistedOutputsModal
                  outputsState={existedOutputsState}
                  closeModal={close}
                />
              )}
            </>
          )}
        />
      </GridWrapper>
      <CalendarContainer />
    </MediaGroupsHelperContextProvider>
  );
};

export default MediaGroupsContainer;
