import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { rem, theme } from "../../../styling/theme";
import CircleIcon from "./CircleIcon";
import HtmlText from "./HtmlText";

const Card = styled.button`
  width: 100%;
  height: 100%;
  border: solid 1px ${theme.color.borderColor};
  border-radius: ${rem(12)};
  padding: ${rem(16)};
  position: relative;
  background-color: transparent;
  appearance: none;
  text-align: left;

  &:hover {
    background-color: ${theme.color.backgroundColor};
  }

  ${theme.media.lg} {
    padding: ${rem(32)} ${rem(32)} ${rem(30)};
  }
`;

const Title = styled.div`
  font-weight: 600;
  line-height: ${rem(22)};
  font-size: ${rem(16)};
  margin: 0;
  color: ${theme.color.darkPrimaryColor};
  display: flex;
  align-items: center;
  ${theme.media.lg} {
    margin: 0 0 ${rem(8)};
  }
`;

const TitleText = styled.div`
  margin-left: 12px;
`;

const Text = styled.div<{ isActive: boolean }>`
  font-weight: 400;
  line-height: ${rem(28)};
  font-size: ${rem(14)};
  margin: ${rem(12)} 0 0;
  color: ${theme.color.bwBlack};
  opacity: 0.6;
  display: ${({ isActive }) => (isActive ? "block" : "none")};

  ${theme.media.lg} {
    display: block;
    margin: 0 0 ${rem(8)};
  }
`;

interface InfoCardContent {
  title: string;
  text: string;
  icon: React.ReactNode;
}

const InfoCard: FC<React.PropsWithChildren<InfoCardContent>> = ({
  title,
  icon,
  text,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card onClick={() => setIsOpen(!isOpen)}>
      <Title>
        <CircleIcon variant="background" color={theme.color.yellowColor}>
          {icon}
        </CircleIcon>
        <TitleText>{title}</TitleText>
      </Title>
      <Text isActive={isOpen}>
        <HtmlText value={text} />
      </Text>
    </Card>
  );
};

export default InfoCard;
