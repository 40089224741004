import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, theme } from "../../../styling/theme";

const linkCss = css`
  background-color: transparent;
  border: none;
  appearance: none;
  color: ${theme.color.darkPrimaryColor};
  font-weight: 600;
  font-size: ${rem(14)};
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  &:hover {
    text-decoration: none;
  }
`;

const commonButtonCss = css`
  border-radius: ${rem(6)};
  padding: ${rem(12)} ${rem(32)};
  font-size: ${rem(14)};
  font-weight: 600;
  letter-spacing: 0.2px;
  border: 1px solid;
  color: ${theme.color.darkPrimaryColor};
  cursor: pointer;
  min-width: ${rem(134)};
  transition: background-color 300ms;
`;

const outlineButtonCss = css`
  ${commonButtonCss}

  background-color: transparent;
  border-color: ${theme.color.darkPrimaryColor};

  &:hover {
    background-color: ${theme.color.lighterBlueColor};
  }
`;

const backgroundCss = css`
  ${commonButtonCss}

  border-color: transparent;
  background-color: #f2c94c;

  &:hover {
    background-color: ${theme.color.yellowColor};
  }
`;

export const Link = styled.a`
  ${linkCss}
`;

type ButtonProps =
  | {
      like: "link";
    }
  | {
      like: "button";
      variant: "background" | "outline";
    };

export const Button = styled.button<ButtonProps>`
  ${(props) =>
    props.like === "link"
      ? linkCss
      : props.variant === "background"
        ? backgroundCss
        : outlineButtonCss}
`;
