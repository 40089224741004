import styled from "@emotion/styled";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  PointElement,
} from "chart.js";
import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import FlexBox from "../../../../components/FlexBox";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { OverlapAnalysisInfoT } from "../../types";

ChartJS.register(CategoryScale, PointElement, LineElement, BarElement);

const Wrapper = styled(FlexBox)({
  height: rem(250),
  width: "100%",
});

type BarDatasetT = {
  label: string;
  data: number[];
  backgroundColor: string;
};
type BarDataT = {
  labels: string[];
  datasets: BarDatasetT[];
};

type GraphT = {
  info: OverlapAnalysisInfoT;
};

const Graph: FC<React.PropsWithChildren<GraphT>> = ({ info }) => {
  const { t } = useTranslate("brands.overlap_analyses.show");

  const formatedGraphData = (): BarDataT => {
    return {
      labels: info.data.map((item) => item.username),
      datasets: [
        {
          label: t("overlapping_percentage"),
          data: info.data.map((item) => item.overlapping_followers),
          backgroundColor: "rgb(240, 114, 124)",
        },
        {
          label: t("unique_percentage"),
          data: info.data.map((item) => item.unique_followers),
          backgroundColor: "rgb(119, 154, 237)",
        },
      ],
    };
  };

  return (
    <Wrapper justifyContent="flex-end" alignItems="flex-end">
      <Bar
        data={formatedGraphData()}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: { x: { stacked: true }, y: { stacked: true } },
          plugins: { legend: { position: "top" } },
        }}
      />
    </Wrapper>
  );
};

export default Graph;
