import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import { rem } from "../../../../styling/theme";

const ImageWrapper = styled.div<{
  customWidth: string;
  customHeight: string;
}>(({ customWidth, customHeight }) => ({
  width: customWidth,
  height: customHeight,
  borderRadius: rem(8),
  overflow: "hidden",
  marginRight: rem(4),
}));

const Image = styled.img({
  minHeight: "100%",
  minWidth: "100%",
  objectFit: "cover",
});

type ProfileThumbnailsT = {
  items: string[];
  thumbnailWidth?: string;
  thumbnailHeight?: string;
};

const ProfileThumbnails: FC<React.PropsWithChildren<ProfileThumbnailsT>> = ({
  items,
  thumbnailWidth = rem(180),
  thumbnailHeight,
}) => {
  return (
    <FlexBox>
      {items.map((post) => (
        <ImageWrapper
          key={post}
          customWidth={thumbnailWidth}
          customHeight={thumbnailHeight ? thumbnailHeight : thumbnailWidth}
        >
          <Image src={post} alt={post} />
        </ImageWrapper>
      ))}
    </FlexBox>
  );
};
export default ProfileThumbnails;
