import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import Spacer, { SpacerSizeT } from "./Spacer";

const getHeadingStyles = (type: HeadingTypeT) => {
  switch (type) {
    case HeadingTypeT.h1:
      return {
        fontSize: rem(32),
        marginTop: rem(18),
      };
    case HeadingTypeT.h2:
      return {
        fontSize: rem(24),
        marginTop: rem(14),
      };
    case HeadingTypeT.h3:
      return {
        fontSize: rem(18),
      };
    case HeadingTypeT.h4:
      return {
        fontSize: rem(16),
      };
  }
};

export enum HeadingTypeT {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
}

type HeadingT = {
  heading: string;
  headingType?: HeadingTypeT;
  fontWeight?: 400 | 600 | 700;
  color?: string;
  spaceBottom?: SpacerSizeT;
};

const Heading: FC<React.PropsWithChildren<HeadingT>> = ({
  heading,
  headingType = HeadingTypeT.h1,
  fontWeight = 700,
  color = theme.color.textColor,
  spaceBottom = "default",
}) => {
  const HeadingElement = headingType;
  const StyledHeading = styled(HeadingElement)({
    ...getHeadingStyles(headingType),
    color,
    fontWeight,
    marginBottom: 0,
    marginTop: 0,
  });

  return (
    <>
      <StyledHeading>{heading}</StyledHeading>
      <Spacer size={spaceBottom} direction="vertical" />
    </>
  );
};

export default Heading;
