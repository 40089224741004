import { NetworkProviderT } from "../../../types";

export type GenderTypeT = "male" | "female" | "known" | "unknown";

export type QueryTextTagTypeT = "mention" | "hashtag";
export type QueryTextTagT = {
  value: string;
  type: QueryTextTagTypeT;
  action: "should";
};

export type QueryIDandWeightT = {
  id: string;
  weight: string;
};

export type GlobalGenderTypeT = "MALE" | "FEMALE" | "KNOWN" | "UNKNOWN";

export type SearchQueryGlobalSortFieldT = "engagements" | "followers" | "views";

type SearchQueryGlobalSortT = {
  direction: "desc" | "asc";
  field: SearchQueryGlobalSortFieldT;
};

type SearchQueryGlobalRangeT = {
  left_number: string;
  right_number: string;
};

export type SearchQueryGlobalAudienceAgeRangeT =
  | {
      left_number: string;
      right_number: string;
      operator: "gte";
      weight: string;
    }
  | undefined;

export enum CredibilityClassE {
  low = 80,
  normal = 85,
  high = 90,
  best = 95,
}
export type CredibilityClassT = keyof typeof CredibilityClassE;

type SearchQueryGlobalGrowthT =
  | {
      interval: string;
      operator: "gte" | "lte";
      value: string;
    }
  | undefined;

export type SearchQueryGlobalT = {
  account_type: ("1" | "2" | "3")[];
  age: SearchQueryGlobalRangeT;
  audience_lang: { code: string; weight: string };
  audience_age_range: SearchQueryGlobalAudienceAgeRangeT;
  audience_brand_category: QueryIDandWeightT[];
  audience_credibility_class: CredibilityClassT[];
  audience_gender: { code: GlobalGenderTypeT | ""; weight: string };
  audience_geo: QueryIDandWeightT[];
  audience_race: { code: string; weight: string };
  brand_category: string[];
  engagement_rate: { value: number | undefined | null; operator: "gte" };
  engagements: SearchQueryGlobalRangeT;
  followers: SearchQueryGlobalRangeT;
  followers_growth: SearchQueryGlobalGrowthT;
  total_likes_growth: SearchQueryGlobalGrowthT;
  gender: { code: GlobalGenderTypeT | "" };
  geo: { id: string }[];
  keywords: string;
  lang: { code: string };
  last_posted: string;
  platform: NetworkProviderT;
  reels_plays: SearchQueryGlobalRangeT;
  saves: SearchQueryGlobalRangeT;
  shares: SearchQueryGlobalRangeT;
  text_tags: QueryTextTagT[];
  text: string;
  views: SearchQueryGlobalRangeT;
  with_contact: { type: string; action: string }[];
  tags: string[];
  recently_analyzed_profiles: boolean;
  sort: SearchQueryGlobalSortT;
};

type CityT = {
  name: string;
  country: string;
};

export type FilterDataTotalsT = {
  instagram: number;
  tiktok: number;
  youtube: number;
};

export type FilterDataT = {
  followers_from: number;
  followers_to: number;
  profile_interests: string[];
  cities: CityT[];
  followers_interests: string[];
  tags: string[];
  totals: FilterDataTotalsT;
};
