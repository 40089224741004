import * as React from "react";
import { FC, useContext } from "react";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";
import SerchInputWithClear from "../../components/SerchInputWithClear";

const Bio: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const { t } = useTranslate("brands.influencer_database.filter.bio");

  return (
    <FilterContainer closable={["text"]} t={t}>
      <FlexBox
        gap={rem(16)}
        alignItems="flex-start"
        customWidth={CustomWidthE.full}
        flexDirection="column"
      >
        <SerchInputWithClear
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
          attribute="text"
          t={t}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default Bio;
