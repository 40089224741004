import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Avatar from "../components/Avatar";
import FlexBox from "../components/FlexBox";
import { NetworkProviderT } from "../entry-files/types";
import { rem, theme } from "../styling/theme";
import Icon, { IconSizeT, IconTypeE } from "./icons/Icon";

const Wrapper = styled(FlexBox)({
  padding: `${rem(4)} ${rem(4)} ${rem(4)} ${rem(12)}`,
  minWidth: "fit-content",
  overflow: "hidden",
  zIndex: 1,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
});

const SocialIconWrapper = styled(FlexBox)({
  position: "absolute",
  borderRadius: "50%",
  bottom: rem(0),
  right: rem(0),
  padding: rem(1),
  backgroundColor: theme.color.whiteColor,
  zIndex: 2,
});

type AvatarWithCirclesSizesT = "big" | "default" | "mini" | "micro";
type AvatarWithCirclesT = {
  handle: string;
  profileImageUrl: string | null;
  avatarSize?: AvatarWithCirclesSizesT;
  disabled?: boolean;
  provider?: NetworkProviderT;
};

const AvatarWithCircles: FC<React.PropsWithChildren<AvatarWithCirclesT>> = ({
  handle,
  profileImageUrl,
  avatarSize = "default",
  provider,
}) => {
  const getProviderIcon = (provider: NetworkProviderT): IconTypeE => {
    switch (provider) {
      case "instagram":
        return IconTypeE.socInstagram;
      case "tiktok":
        return IconTypeE.socTiktok;
      case "youtube":
        return IconTypeE.socYoutube;
    }
  };

  const getAvatarSizeDimensions = (): {
    size: number;
    iconSize: IconSizeT;
  } => {
    switch (avatarSize) {
      case "mini":
        return {
          size: 48,
          iconSize: "small",
        };
      default:
        return {
          size: 64,
          iconSize: "default",
        };
    }
  };

  const { size, iconSize } = getAvatarSizeDimensions();

  return (
    <Wrapper position="relative" flexDirection="column">
      <FlexBox position="relative">
        <Avatar size={size} name={handle} src={profileImageUrl} />
        {provider && (
          <SocialIconWrapper>
            <Icon icon={getProviderIcon(provider)} size={iconSize} />
          </SocialIconWrapper>
        )}
      </FlexBox>
    </Wrapper>
  );
};

export default AvatarWithCircles;
