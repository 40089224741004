import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../../../styling/theme";
import { Button } from "./controls";
import HtmlText from "./HtmlText";

const Wrapper = styled.div`
  background-color: ${theme.color.primaryColor};
  border-radius: ${rem(8)};
  color: ${theme.color.whiteColor};
  padding: ${rem(22)};
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  ${theme.media.lg} {
    padding: ${rem(30)} ${rem(36)} ${rem(30)} ${rem(40)};
  }
`;

const TextWrapper = styled.div`
  padding-right: 0;

  ${theme.media.lg} {
    padding-right: ${rem(24)};
  }
`;

const ImgWrapper = styled.div`
  padding: 0;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: ${rem(22)};
  margin-top: 0;
  margin: 0;
  padding-right: ${rem(56)};
  ${theme.media.lg} {
    padding-right: 0;
  }
`;

const Text = styled.p`
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(26)};
  margin: ${rem(12)} 0 ${rem(24)} 0;
  opacity: 0.8;
`;

const ImgCircle1 = styled.img`
  position: absolute;
  right: ${rem(44)};
  top: ${rem(-10)};
  width: ${rem(44)};
  ${theme.media.lg} {
    right: 84px;
    top: -18px;
    width: 103px;
  }
`;
const ImgCircle2 = styled.img`
  position: absolute;
  right: ${rem(-20)};
  top: ${rem(32)};
  width: ${rem(61)};
  height: auto;
  ${theme.media.lg} {
    right: ${rem(-55)};
    top: ${rem(70)};
    width: ${rem(167)};
    height: auto;
    width: ${rem(144)};
  }
`;

const StyledButton = styled(Button)({
  color: theme.color.whiteColor,
});

const Circle1 = require("../../../assets/fb-approval/icons/circle126.png");
const Circle2 = require("../../../assets/fb-approval/icons/circle167.png");

interface TextWrapperContent {
  title: string;
  text: string;
  linkTitle?: string;
  linkHandle?: () => void;
  img: React.ReactNode;
}

const ColourWrapper: FC<React.PropsWithChildren<TextWrapperContent>> = ({
  title,
  text,
  linkTitle,
  linkHandle,
  img,
}) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Text>
          <HtmlText value={text} />
        </Text>
        {linkTitle && linkHandle && (
          <StyledButton like="link" type="button" onClick={linkHandle}>
            {linkTitle}
          </StyledButton>
        )}
      </TextWrapper>
      <ImgWrapper>
        {img}
        <ImgCircle1 src={Circle1} alt="" />
        <ImgCircle2 src={Circle2} alt="" />
      </ImgWrapper>
    </Wrapper>
  );
};

export default ColourWrapper;
