import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ListT } from "../types";

export const updateList = async (
  id: number,
  networkProfileIds: string[],
  name?: string,
): Promise<ListT> => {
  let params: Record<string, unknown> = {
    network_profile_ids: networkProfileIds,
  };
  if (name && name.length > 0) {
    params["name"] = name;
  }
  const payload = { list: params };
  return axios
    .patch(getApiUrl(ApiRoutesE.LISTS_DETAIL, id), payload)
    .then((res) => res.data.list);
};
