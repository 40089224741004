import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { designToken } from "../helpers/antDesign";
import { rem, theme } from "../styling/theme";
import Icon, { IconTypeE } from "../ui/icons/Icon";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)<{ width: number }>(({ width }) => ({
  position: "relative",
  width: width,
}));

const AvatarWrapper = styled(FlexBox)<{
  size: number;
  borderWidth: number;
  borderColor: string;
  withShadow: boolean;
}>(({ size, borderWidth, borderColor, withShadow }) => ({
  position: "relative",
  minWidth: rem(size),
  width: rem(size),
  height: rem(size),
  backgroundColor: theme.color.yellowColor,
  borderWidth,
  borderColor,
  borderStyle: "solid",
  borderRadius: "50%",
  boxShadow: withShadow ? `0px 2px 20px rgb(0 0 0 / 10%)` : "none",
  overflow: "hidden",
}));

const Image = styled.img({
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

const StyledIcon = styled(Icon)<{
  size: number;
}>(({ size }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  width: size,
  height: size,
}));

const IconWrapper = styled(FlexBox)({
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: designToken["blue-1"],
  borderRadius: "50%",
  padding: rem(6),
  border: `1px solid ${theme.color.whiteColor}`,
});

const Letter = styled.div({
  fontSize: rem(20),
  fontWeight: "bold",
  userSelect: "none",
});

type AvatarT = {
  name: string;
  src: string | null;
  size?: number;
  borderWidth?: number;
  borderColor?: string;
  withShadow?: boolean;
  network?: string;
  icon?: React.ReactNode;
};

const Avatar: FC<React.PropsWithChildren<AvatarT>> = ({
  src,
  name,
  size = 28,
  borderWidth = 0,
  borderColor = theme.color.whiteColor,
  withShadow = false,
  network,
  icon,
}) => {
  const networkicon = (): IconTypeE => {
    switch (network) {
      case "instagram":
        return IconTypeE.socInstagram;
      case "tiktok":
        return IconTypeE.socTiktok;
      case "youtube":
        return IconTypeE.socYoutube;
      default:
        return IconTypeE.socInstagram;
    }
  };

  const getIcon = () => {
    if (icon) {
      return <IconWrapper>{icon}</IconWrapper>;
    }
    if (network) {
      return <StyledIcon icon={networkicon()} size={size / 2} />;
    }

    return undefined;
  };

  return (
    <Wrapper width={network ? size + size / 4 : size}>
      <AvatarWrapper
        title={name}
        size={size}
        borderColor={borderColor}
        borderWidth={borderWidth}
        withShadow={withShadow}
      >
        {src ? <Image src={src} /> : <Letter>{name[0].toUpperCase()}</Letter>}
      </AvatarWrapper>
      {getIcon()}
    </Wrapper>
  );
};

export default Avatar;
