import {
  MailOutlined,
  RiseOutlined,
  SearchOutlined,
  SyncOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import BlueLink from "components/BlueLink";
import FlexBox, { CustomWidthE } from "components/FlexBox";
import InfoPopover from "components/InfoPopover";
import Paragraph from "components/Paragraph";
import {
  NavigationKeyE,
  useRouterNavigate,
} from "entry-files/company-root/router";
import {
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
  SubscriptionT,
} from "entry-files/types";
import { designToken } from "helpers/antDesign";
import { formatDate } from "helpers/formaters";
import { useTranslate } from "hooks/useTranslate";
import * as React from "react";
import { rem } from "styling/theme";
import { UserMonitorIcon } from "ui/icons/CustomIcons";
import UsageItem from "./UsageItem";

const Box = styled(FlexBox)({
  backgroundColor: designToken.colorWhite,
  padding: `${rem(20)} ${rem(16)}`,
  borderRadius: designToken.borderRadiusLG,
});
const StyledInfoPopover = styled(InfoPopover)({
  marginLeft: rem(5),
  position: "relative",
  top: rem(-1),
});

const iconStyle = { fontSize: rem(12) };

type PropsT = {
  usage: Array<SubscriptionCurrentUsageT>;
  subscription: SubscriptionT;
  renewalDate?: Date;
};

const PlanUsage = ({ usage, subscription, renewalDate }: PropsT) => {
  const { t, i18n } = useTranslate("brands.manage_subscription");
  const navigate = useRouterNavigate();
  const { billing_period, billing_period_unit, entitlements } = subscription;

  const getUsageData = (key: SubscriptionEntitlementFeaturesT) => {
    const current = usage.find((u) => u.feature_id === key);

    if (!current) {
      return null;
    }

    return {
      feature_id: current.feature_id,
      used: current.used,
      quota: current.quota,
    };
  };

  const databaseSearchesUsage = getUsageData("database-searches");
  const databaseReportsUsage = getUsageData("database-reports");
  const overlapsUsage = getUsageData("overlap-analyses");
  const competitiveUsage = getUsageData("competitive-analyses");
  const usersUsage = getUsageData("users");
  const monitoredProfilesUsage = getUsageData("reporting-influencers");

  const hasDatabaseCzSkAccess = entitlements.some(
    (ent) =>
      ent.feature_id === "database-access-cz" &&
      ent.enabled === true &&
      ent.value === "true",
  );
  const hasPriorityEmailSupport = entitlements.some(
    (ent) =>
      ent.feature_id === "support" &&
      ent.enabled === true &&
      ent.value === "priority-email",
  );
  const hasPriorityEmailAndPhoneSupport =
    entitlements.some(
      (ent) =>
        ent.feature_id === "support" &&
        ent.enabled === true &&
        ent.value === "priority-email",
    ) &&
    entitlements.some(
      (ent) =>
        ent.feature_id === "support" &&
        ent.enabled === true &&
        ent.value === "phone",
    );

  const handleManageUserseOnClick = () => {
    navigate(NavigationKeyE.Users);
  };

  return (
    <>
      <Box
        customWidth={CustomWidthE.full}
        flexDirection="column"
        gap={rem(12)}
        alignItems="flex-start"
      >
        <Paragraph
          paragraph={
            <>
              {t("plan_usage")}
              {renewalDate && (
                <StyledInfoPopover
                  style={{ marginLeft: rem(8) }}
                  title={t("plan_usage_desc", {
                    billingPeriod: t(
                      `billing.${billing_period}${billing_period_unit}`,
                    ).toLowerCase(),
                    date: formatDate(renewalDate, i18n),
                  })}
                />
              )}
            </>
          }
          fontWeight={600}
        />

        {(databaseSearchesUsage || databaseReportsUsage) && (
          <>
            <Paragraph
              paragraph={t("database")}
              color={designToken.colorTextTertiary}
              paragraphSize="micro"
              marginTop={rem(12)}
              marginBottom={rem(-6)}
            />
            <FlexBox
              customWidth={CustomWidthE.full}
              gap={rem(16)}
              justifyContent="flex-start"
            >
              {databaseSearchesUsage && (
                <UsageItem
                  title={t("usages.database_searches")}
                  tooltip={t("usages.database_searches_tooltip")}
                  usage={databaseSearchesUsage}
                  icon={<SearchOutlined style={iconStyle} />}
                />
              )}
              {databaseReportsUsage && (
                <UsageItem
                  title={t("usages.database_reports")}
                  tooltip={t("usages.database_reports_tooltip")}
                  usage={databaseReportsUsage}
                  icon={<SearchOutlined style={iconStyle} />}
                  note={
                    hasDatabaseCzSkAccess
                      ? t("usages.database_access_cz")
                      : undefined
                  }
                />
              )}
            </FlexBox>
          </>
        )}

        {(overlapsUsage || competitiveUsage) && (
          <>
            <Paragraph
              paragraph={t("research_tools")}
              color={designToken.colorTextTertiary}
              paragraphSize="micro"
              marginTop={rem(12)}
              marginBottom={rem(-6)}
            />
            <FlexBox
              customWidth={CustomWidthE.full}
              gap={rem(16)}
              justifyContent="flex-start"
            >
              {overlapsUsage && (
                <UsageItem
                  title={t("usages.overlap_analysis")}
                  tooltip={t("usages.overlap_analysis_tooltip")}
                  usage={overlapsUsage}
                  icon={<SyncOutlined style={iconStyle} />}
                />
              )}
              {competitiveUsage && (
                <UsageItem
                  title={t("usages.competitive_analysis")}
                  tooltip={t("usages.competitive_analysis_tooltip")}
                  usage={competitiveUsage}
                  icon={<RiseOutlined style={iconStyle} />}
                />
              )}
            </FlexBox>
          </>
        )}

        {monitoredProfilesUsage && (
          <>
            <Paragraph
              paragraph={t("campaigns")}
              color={designToken.colorTextTertiary}
              paragraphSize="micro"
              marginTop={rem(12)}
              marginBottom={rem(-6)}
            />
            <FlexBox
              customWidth={CustomWidthE.half}
              justifyContent="flex-start"
              style={{ paddingRight: rem(8) }}
            >
              <UsageItem
                title={t("usages.monitored_influencers")}
                tooltip={t("usages.monitored_influencers_tooltip")}
                usage={monitoredProfilesUsage}
                icon={<UserMonitorIcon fill={designToken.colorTextBase} />}
              />
            </FlexBox>
          </>
        )}

        <Paragraph
          paragraph={t("general")}
          color={designToken.colorTextTertiary}
          paragraphSize="micro"
          marginTop={rem(12)}
          marginBottom={rem(-6)}
        />
        <FlexBox
          customWidth={CustomWidthE.full}
          gap={rem(16)}
          justifyContent="flex-start"
        >
          {usersUsage && (
            <UsageItem
              title={t("usages.users")}
              usage={usersUsage}
              icon={<TeamOutlined style={iconStyle} />}
              link={
                usersUsage.quota !== 1 ? (
                  <BlueLink
                    onClick={handleManageUserseOnClick}
                    type="grey"
                    text={t("manage_users")}
                    marginTop={rem(0)}
                    marginLeft={rem(8)}
                  />
                ) : undefined
              }
            />
          )}
          <UsageItem
            title={t(
              `usages.${hasPriorityEmailAndPhoneSupport ? "priority_email_and_phone" : hasPriorityEmailSupport ? "priority_email" : "email"}`,
            )}
            usage={{
              used: 0,
              quota: "Unlimited",
              feature_id: "support",
            }}
            icon={<MailOutlined style={iconStyle} />}
            link={
              <BlueLink
                href="mailto:hello@trendin.io"
                type="grey"
                text="hello@trendin.io"
                marginTop={rem(0)}
                marginLeft={rem(8)}
              />
            }
          />
        </FlexBox>
      </Box>
    </>
  );
};

export default PlanUsage;
