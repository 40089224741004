import styled from "@emotion/styled";
import { Spin } from "antd";
import { SpinSize } from "antd/lib/spin";
import * as React from "react";
import { FC } from "react";
import { theme } from "../styling/theme";

const Wrapper = styled.div<{ color: string }>(({ color }) => ({
  display: "flex",

  div: {
    display: "flex",
  },

  ".ant-spin-dot-item": {
    backgroundColor: color,
  },
}));

type LoaderT = {
  size?: SpinSize;
  color?: string;
};

const LoaderComponent: FC<React.PropsWithChildren<LoaderT>> = ({
  size = "large",
  color = theme.color.primaryColor,
}) => (
  <Wrapper color={color}>
    <Spin size={size} />
  </Wrapper>
);

export default LoaderComponent;
