import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import DoubleFilter from "../../components/DoubleFilter";
import AudienceAge from "./AudienceAge";
import InfluencerAge from "./InfluencerAge";

const Age: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate("brands.influencer_database.filter.age");

  return (
    <DoubleFilter
      audienceFilter={<AudienceAge />}
      influencerFilter={<InfluencerAge />}
      t={t}
    />
  );
};

export default Age;
