import { CheckOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import NavigationContainer from "../../../../components/NavigationContainer";
import { deleteSession } from "../../../../data-access/deleteSession";
import { updateSessionMutation } from "../../../../data-access/mutations/updateSessionMutation";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import NavigationItem from "../../../../ui/NavigationItem";
import { InfluencerNavigationContext } from "../../context-providers/InfluencerNavigationContextProvider";

const TextWrapper = styled(FlexBox)<{ open: boolean }>(({ open }) => ({
  visibility: open ? "visible" : "hidden",
  cursor: "pointer",
}));

const MenuItemsWrapper = styled(FlexBox)({
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});

const NavigationGroup = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  gap: rem(4),
});

type NavigationT = {
  standalone?: boolean;
};

const Navigation: FC<React.PropsWithChildren<NavigationT>> = ({
  standalone,
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const { navigationState, setNavigationState } = useContext(
    InfluencerNavigationContext,
  );

  const navigationKey = standalone ? "profile" : navigationState;

  const { t } = useTranslate("menu");

  const queryClient = useQueryClient();
  const { mutate: logout } = useMutation(deleteSession, {
    onSuccess: (data) => {
      updateSessionMutation(queryClient, data, () => {
        window.location.href = getApiUrl(ApiRoutesE.SESSION_DELETE_AFTER_PATH);
      });
    },
  });

  const handleProfileOnClick = (): void => {
    window.location.href = getApiUrl(ApiRoutesE.PROFILE_EDIT);
  };

  const navigate = (key: "approvals"): void => {
    if (standalone) {
      const path = { approvals: "/influencers/reportings/approvals" }[key];
      window.location.href = path;
    }

    setNavigationState(key);
  };

  return (
    // @ts-ignore
    <NavigationContainer openMenu={openMenu} setOpenMenu={setOpenMenu}>
      <MenuItemsWrapper>
        <NavigationGroup
          flexDirection="column"
          gap={rem(4)}
          customWidth={CustomWidthE.full}
        >
          <NavigationItem
            onClick={() => navigate("approvals")}
            active={!standalone}
            icon={<CheckOutlined />}
          >
            <TextWrapper open={openMenu}>{t("approvals")}</TextWrapper>
          </NavigationItem>
        </NavigationGroup>
        <NavigationGroup>
          <NavigationItem
            onClick={handleProfileOnClick}
            active={navigationKey === "profile"}
            icon={<UserOutlined />}
          >
            <TextWrapper open={openMenu}>{t("profile")}</TextWrapper>
          </NavigationItem>
          <NavigationItem
            onClick={logout}
            active={false}
            icon={<LogoutOutlined />}
          >
            <TextWrapper open={openMenu}>{t("logout")}</TextWrapper>
          </NavigationItem>
        </NavigationGroup>
      </MenuItemsWrapper>
    </NavigationContainer>
  );
};

export default Navigation;
