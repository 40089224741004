import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import { rem } from "../../styling/theme";

type WrapperStyleT = {
  height: number;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ height }) => ({
  height: rem(height),
}));

const Content = styled(FlexBox)({
  position: "absolute",
  maxWidth: rem(450),
});

const Block = styled(FlexBox)<{ isWhite?: boolean; blocksAreRows?: boolean }>(
  ({ isWhite, blocksAreRows }) => ({
    width: blocksAreRows ? "99%" : "18%",
    height: blocksAreRows ? "9.5%" : "44%",
    margin: blocksAreRows ? "0.5%" : "1%",
    background: isWhite
      ? "linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0%, rgba(242, 242, 242, 0.6) 100%)"
      : "linear-gradient(120deg, rgba(240, 240, 240, 0.25) 0%, rgba(228, 228, 228, 0.05) 100%)",
    opacity: 1,
    borderRadius: rem(8),
  }),
);

type TableContentWrapperT = {
  height?: number;
  isWhite?: boolean;
  blocksAreRows?: boolean;
};

const TableContentWrapper: FC<
  React.PropsWithChildren<TableContentWrapperT>
> = ({ children, height = 550, isWhite = false, blocksAreRows = false }) => {
  return (
    <Wrapper
      customWidth={CustomWidthE.full}
      height={height}
      flexDirection={blocksAreRows ? "column" : "row"}
      flexWrap={blocksAreRows ? "nowrap" : "wrap"}
    >
      {[...new Array(10)].map((item, index) => (
        <Block key={index} isWhite={isWhite} blocksAreRows={blocksAreRows} />
      ))}
      <Content flexDirection="column">{children}</Content>
    </Wrapper>
  );
};

export default TableContentWrapper;
